import '../public/css/aqua.css';
import '../public/font/HKGrotesk-Regular.woff';
import { Elm } from './Main.elm';
import * as serviceWorker from './serviceWorker';
import './fontawesome_all.min.js';

let app = Elm.Main.init({
  node: document.getElementById('root')
  ,flags: { endpoint: process.env.ELM_APP_PROD, api_key: process.env.ELM_APP_API_KEY }
                         //set to ELM_APP_PROD for deploy
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// local storage
app.ports.storeLocal.subscribe(function(obj) {
  localStorage.setItem('aquajwt', JSON.stringify(obj) ) 
});

app.ports.checkLocal.subscribe(function() {
  let a = localStorage.getItem('aquajwt');
  app.ports.hasLocal.send(a != null);
})

app.ports.requestLocal.subscribe(function() {
  let a = localStorage.getItem('aquajwt');
  app.ports.sendLocalStore.send(JSON.parse(a))
})


app.ports.removeLocal.subscribe(function() {
  localStorage.removeItem('aquajwt'); 
});

