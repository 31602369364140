(function(scope){
'use strict';

function F(arity, fun, wrapper) {
  wrapper.a = arity;
  wrapper.f = fun;
  return wrapper;
}

function F2(fun) {
  return F(2, fun, function(a) { return function(b) { return fun(a,b); }; })
}
function F3(fun) {
  return F(3, fun, function(a) {
    return function(b) { return function(c) { return fun(a, b, c); }; };
  });
}
function F4(fun) {
  return F(4, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return fun(a, b, c, d); }; }; };
  });
}
function F5(fun) {
  return F(5, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return fun(a, b, c, d, e); }; }; }; };
  });
}
function F6(fun) {
  return F(6, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return fun(a, b, c, d, e, f); }; }; }; }; };
  });
}
function F7(fun) {
  return F(7, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return fun(a, b, c, d, e, f, g); }; }; }; }; }; };
  });
}
function F8(fun) {
  return F(8, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) {
    return fun(a, b, c, d, e, f, g, h); }; }; }; }; }; }; };
  });
}
function F9(fun) {
  return F(9, fun, function(a) { return function(b) { return function(c) {
    return function(d) { return function(e) { return function(f) {
    return function(g) { return function(h) { return function(i) {
    return fun(a, b, c, d, e, f, g, h, i); }; }; }; }; }; }; }; };
  });
}

function A2(fun, a, b) {
  return fun.a === 2 ? fun.f(a, b) : fun(a)(b);
}
function A3(fun, a, b, c) {
  return fun.a === 3 ? fun.f(a, b, c) : fun(a)(b)(c);
}
function A4(fun, a, b, c, d) {
  return fun.a === 4 ? fun.f(a, b, c, d) : fun(a)(b)(c)(d);
}
function A5(fun, a, b, c, d, e) {
  return fun.a === 5 ? fun.f(a, b, c, d, e) : fun(a)(b)(c)(d)(e);
}
function A6(fun, a, b, c, d, e, f) {
  return fun.a === 6 ? fun.f(a, b, c, d, e, f) : fun(a)(b)(c)(d)(e)(f);
}
function A7(fun, a, b, c, d, e, f, g) {
  return fun.a === 7 ? fun.f(a, b, c, d, e, f, g) : fun(a)(b)(c)(d)(e)(f)(g);
}
function A8(fun, a, b, c, d, e, f, g, h) {
  return fun.a === 8 ? fun.f(a, b, c, d, e, f, g, h) : fun(a)(b)(c)(d)(e)(f)(g)(h);
}
function A9(fun, a, b, c, d, e, f, g, h, i) {
  return fun.a === 9 ? fun.f(a, b, c, d, e, f, g, h, i) : fun(a)(b)(c)(d)(e)(f)(g)(h)(i);
}




var _JsArray_empty = [];

function _JsArray_singleton(value)
{
    return [value];
}

function _JsArray_length(array)
{
    return array.length;
}

var _JsArray_initialize = F3(function(size, offset, func)
{
    var result = new Array(size);

    for (var i = 0; i < size; i++)
    {
        result[i] = func(offset + i);
    }

    return result;
});

var _JsArray_initializeFromList = F2(function (max, ls)
{
    var result = new Array(max);

    for (var i = 0; i < max && ls.b; i++)
    {
        result[i] = ls.a;
        ls = ls.b;
    }

    result.length = i;
    return _Utils_Tuple2(result, ls);
});

var _JsArray_unsafeGet = F2(function(index, array)
{
    return array[index];
});

var _JsArray_unsafeSet = F3(function(index, value, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[index] = value;
    return result;
});

var _JsArray_push = F2(function(value, array)
{
    var length = array.length;
    var result = new Array(length + 1);

    for (var i = 0; i < length; i++)
    {
        result[i] = array[i];
    }

    result[length] = value;
    return result;
});

var _JsArray_foldl = F3(function(func, acc, array)
{
    var length = array.length;

    for (var i = 0; i < length; i++)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_foldr = F3(function(func, acc, array)
{
    for (var i = array.length - 1; i >= 0; i--)
    {
        acc = A2(func, array[i], acc);
    }

    return acc;
});

var _JsArray_map = F2(function(func, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = func(array[i]);
    }

    return result;
});

var _JsArray_indexedMap = F3(function(func, offset, array)
{
    var length = array.length;
    var result = new Array(length);

    for (var i = 0; i < length; i++)
    {
        result[i] = A2(func, offset + i, array[i]);
    }

    return result;
});

var _JsArray_slice = F3(function(from, to, array)
{
    return array.slice(from, to);
});

var _JsArray_appendN = F3(function(n, dest, source)
{
    var destLen = dest.length;
    var itemsToCopy = n - destLen;

    if (itemsToCopy > source.length)
    {
        itemsToCopy = source.length;
    }

    var size = destLen + itemsToCopy;
    var result = new Array(size);

    for (var i = 0; i < destLen; i++)
    {
        result[i] = dest[i];
    }

    for (var i = 0; i < itemsToCopy; i++)
    {
        result[i + destLen] = source[i];
    }

    return result;
});



// LOG

var _Debug_log = F2(function(tag, value)
{
	return value;
});

var _Debug_log_UNUSED = F2(function(tag, value)
{
	console.log(tag + ': ' + _Debug_toString(value));
	return value;
});


// TODOS

function _Debug_todo(moduleName, region)
{
	return function(message) {
		_Debug_crash(8, moduleName, region, message);
	};
}

function _Debug_todoCase(moduleName, region, value)
{
	return function(message) {
		_Debug_crash(9, moduleName, region, value, message);
	};
}


// TO STRING

function _Debug_toString(value)
{
	return '<internals>';
}

function _Debug_toString_UNUSED(value)
{
	return _Debug_toAnsiString(false, value);
}

function _Debug_toAnsiString(ansi, value)
{
	if (typeof value === 'function')
	{
		return _Debug_internalColor(ansi, '<function>');
	}

	if (typeof value === 'boolean')
	{
		return _Debug_ctorColor(ansi, value ? 'True' : 'False');
	}

	if (typeof value === 'number')
	{
		return _Debug_numberColor(ansi, value + '');
	}

	if (value instanceof String)
	{
		return _Debug_charColor(ansi, "'" + _Debug_addSlashes(value, true) + "'");
	}

	if (typeof value === 'string')
	{
		return _Debug_stringColor(ansi, '"' + _Debug_addSlashes(value, false) + '"');
	}

	if (typeof value === 'object' && '$' in value)
	{
		var tag = value.$;

		if (typeof tag === 'number')
		{
			return _Debug_internalColor(ansi, '<internals>');
		}

		if (tag[0] === '#')
		{
			var output = [];
			for (var k in value)
			{
				if (k === '$') continue;
				output.push(_Debug_toAnsiString(ansi, value[k]));
			}
			return '(' + output.join(',') + ')';
		}

		if (tag === 'Set_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Set')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Set$toList(value));
		}

		if (tag === 'RBNode_elm_builtin' || tag === 'RBEmpty_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Dict')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Dict$toList(value));
		}

		if (tag === 'Array_elm_builtin')
		{
			return _Debug_ctorColor(ansi, 'Array')
				+ _Debug_fadeColor(ansi, '.fromList') + ' '
				+ _Debug_toAnsiString(ansi, $elm$core$Array$toList(value));
		}

		if (tag === '::' || tag === '[]')
		{
			var output = '[';

			value.b && (output += _Debug_toAnsiString(ansi, value.a), value = value.b)

			for (; value.b; value = value.b) // WHILE_CONS
			{
				output += ',' + _Debug_toAnsiString(ansi, value.a);
			}
			return output + ']';
		}

		var output = '';
		for (var i in value)
		{
			if (i === '$') continue;
			var str = _Debug_toAnsiString(ansi, value[i]);
			var c0 = str[0];
			var parenless = c0 === '{' || c0 === '(' || c0 === '[' || c0 === '<' || c0 === '"' || str.indexOf(' ') < 0;
			output += ' ' + (parenless ? str : '(' + str + ')');
		}
		return _Debug_ctorColor(ansi, tag) + output;
	}

	if (typeof DataView === 'function' && value instanceof DataView)
	{
		return _Debug_stringColor(ansi, '<' + value.byteLength + ' bytes>');
	}

	if (typeof File !== 'undefined' && value instanceof File)
	{
		return _Debug_internalColor(ansi, '<' + value.name + '>');
	}

	if (typeof value === 'object')
	{
		var output = [];
		for (var key in value)
		{
			var field = key[0] === '_' ? key.slice(1) : key;
			output.push(_Debug_fadeColor(ansi, field) + ' = ' + _Debug_toAnsiString(ansi, value[key]));
		}
		if (output.length === 0)
		{
			return '{}';
		}
		return '{ ' + output.join(', ') + ' }';
	}

	return _Debug_internalColor(ansi, '<internals>');
}

function _Debug_addSlashes(str, isChar)
{
	var s = str
		.replace(/\\/g, '\\\\')
		.replace(/\n/g, '\\n')
		.replace(/\t/g, '\\t')
		.replace(/\r/g, '\\r')
		.replace(/\v/g, '\\v')
		.replace(/\0/g, '\\0');

	if (isChar)
	{
		return s.replace(/\'/g, '\\\'');
	}
	else
	{
		return s.replace(/\"/g, '\\"');
	}
}

function _Debug_ctorColor(ansi, string)
{
	return ansi ? '\x1b[96m' + string + '\x1b[0m' : string;
}

function _Debug_numberColor(ansi, string)
{
	return ansi ? '\x1b[95m' + string + '\x1b[0m' : string;
}

function _Debug_stringColor(ansi, string)
{
	return ansi ? '\x1b[93m' + string + '\x1b[0m' : string;
}

function _Debug_charColor(ansi, string)
{
	return ansi ? '\x1b[92m' + string + '\x1b[0m' : string;
}

function _Debug_fadeColor(ansi, string)
{
	return ansi ? '\x1b[37m' + string + '\x1b[0m' : string;
}

function _Debug_internalColor(ansi, string)
{
	return ansi ? '\x1b[36m' + string + '\x1b[0m' : string;
}

function _Debug_toHexDigit(n)
{
	return String.fromCharCode(n < 10 ? 48 + n : 55 + n);
}


// CRASH


function _Debug_crash(identifier)
{
	throw new Error('https://github.com/elm/core/blob/1.0.0/hints/' + identifier + '.md');
}


function _Debug_crash_UNUSED(identifier, fact1, fact2, fact3, fact4)
{
	switch(identifier)
	{
		case 0:
			throw new Error('What node should I take over? In JavaScript I need something like:\n\n    Elm.Main.init({\n        node: document.getElementById("elm-node")\n    })\n\nYou need to do this with any Browser.sandbox or Browser.element program.');

		case 1:
			throw new Error('Browser.application programs cannot handle URLs like this:\n\n    ' + document.location.href + '\n\nWhat is the root? The root of your file system? Try looking at this program with `elm reactor` or some other server.');

		case 2:
			var jsonErrorString = fact1;
			throw new Error('Problem with the flags given to your Elm program on initialization.\n\n' + jsonErrorString);

		case 3:
			var portName = fact1;
			throw new Error('There can only be one port named `' + portName + '`, but your program has multiple.');

		case 4:
			var portName = fact1;
			var problem = fact2;
			throw new Error('Trying to send an unexpected type of value through port `' + portName + '`:\n' + problem);

		case 5:
			throw new Error('Trying to use `(==)` on functions.\nThere is no way to know if functions are "the same" in the Elm sense.\nRead more about this at https://package.elm-lang.org/packages/elm/core/latest/Basics#== which describes why it is this way and what the better version will look like.');

		case 6:
			var moduleName = fact1;
			throw new Error('Your page is loading multiple Elm scripts with a module named ' + moduleName + '. Maybe a duplicate script is getting loaded accidentally? If not, rename one of them so I know which is which!');

		case 8:
			var moduleName = fact1;
			var region = fact2;
			var message = fact3;
			throw new Error('TODO in module `' + moduleName + '` ' + _Debug_regionToString(region) + '\n\n' + message);

		case 9:
			var moduleName = fact1;
			var region = fact2;
			var value = fact3;
			var message = fact4;
			throw new Error(
				'TODO in module `' + moduleName + '` from the `case` expression '
				+ _Debug_regionToString(region) + '\n\nIt received the following value:\n\n    '
				+ _Debug_toString(value).replace('\n', '\n    ')
				+ '\n\nBut the branch that handles it says:\n\n    ' + message.replace('\n', '\n    ')
			);

		case 10:
			throw new Error('Bug in https://github.com/elm/virtual-dom/issues');

		case 11:
			throw new Error('Cannot perform mod 0. Division by zero error.');
	}
}

function _Debug_regionToString(region)
{
	if (region.bm.aV === region.bz.aV)
	{
		return 'on line ' + region.bm.aV;
	}
	return 'on lines ' + region.bm.aV + ' through ' + region.bz.aV;
}



// EQUALITY

function _Utils_eq(x, y)
{
	for (
		var pair, stack = [], isEqual = _Utils_eqHelp(x, y, 0, stack);
		isEqual && (pair = stack.pop());
		isEqual = _Utils_eqHelp(pair.a, pair.b, 0, stack)
		)
	{}

	return isEqual;
}

function _Utils_eqHelp(x, y, depth, stack)
{
	if (x === y)
	{
		return true;
	}

	if (typeof x !== 'object' || x === null || y === null)
	{
		typeof x === 'function' && _Debug_crash(5);
		return false;
	}

	if (depth > 100)
	{
		stack.push(_Utils_Tuple2(x,y));
		return true;
	}

	/**_UNUSED/
	if (x.$ === 'Set_elm_builtin')
	{
		x = $elm$core$Set$toList(x);
		y = $elm$core$Set$toList(y);
	}
	if (x.$ === 'RBNode_elm_builtin' || x.$ === 'RBEmpty_elm_builtin')
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	/**/
	if (x.$ < 0)
	{
		x = $elm$core$Dict$toList(x);
		y = $elm$core$Dict$toList(y);
	}
	//*/

	for (var key in x)
	{
		if (!_Utils_eqHelp(x[key], y[key], depth + 1, stack))
		{
			return false;
		}
	}
	return true;
}

var _Utils_equal = F2(_Utils_eq);
var _Utils_notEqual = F2(function(a, b) { return !_Utils_eq(a,b); });



// COMPARISONS

// Code in Generate/JavaScript.hs, Basics.js, and List.js depends on
// the particular integer values assigned to LT, EQ, and GT.

function _Utils_cmp(x, y, ord)
{
	if (typeof x !== 'object')
	{
		return x === y ? /*EQ*/ 0 : x < y ? /*LT*/ -1 : /*GT*/ 1;
	}

	/**_UNUSED/
	if (x instanceof String)
	{
		var a = x.valueOf();
		var b = y.valueOf();
		return a === b ? 0 : a < b ? -1 : 1;
	}
	//*/

	/**/
	if (typeof x.$ === 'undefined')
	//*/
	/**_UNUSED/
	if (x.$[0] === '#')
	//*/
	{
		return (ord = _Utils_cmp(x.a, y.a))
			? ord
			: (ord = _Utils_cmp(x.b, y.b))
				? ord
				: _Utils_cmp(x.c, y.c);
	}

	// traverse conses until end of a list or a mismatch
	for (; x.b && y.b && !(ord = _Utils_cmp(x.a, y.a)); x = x.b, y = y.b) {} // WHILE_CONSES
	return ord || (x.b ? /*GT*/ 1 : y.b ? /*LT*/ -1 : /*EQ*/ 0);
}

var _Utils_lt = F2(function(a, b) { return _Utils_cmp(a, b) < 0; });
var _Utils_le = F2(function(a, b) { return _Utils_cmp(a, b) < 1; });
var _Utils_gt = F2(function(a, b) { return _Utils_cmp(a, b) > 0; });
var _Utils_ge = F2(function(a, b) { return _Utils_cmp(a, b) >= 0; });

var _Utils_compare = F2(function(x, y)
{
	var n = _Utils_cmp(x, y);
	return n < 0 ? $elm$core$Basics$LT : n ? $elm$core$Basics$GT : $elm$core$Basics$EQ;
});


// COMMON VALUES

var _Utils_Tuple0 = 0;
var _Utils_Tuple0_UNUSED = { $: '#0' };

function _Utils_Tuple2(a, b) { return { a: a, b: b }; }
function _Utils_Tuple2_UNUSED(a, b) { return { $: '#2', a: a, b: b }; }

function _Utils_Tuple3(a, b, c) { return { a: a, b: b, c: c }; }
function _Utils_Tuple3_UNUSED(a, b, c) { return { $: '#3', a: a, b: b, c: c }; }

function _Utils_chr(c) { return c; }
function _Utils_chr_UNUSED(c) { return new String(c); }


// RECORDS

function _Utils_update(oldRecord, updatedFields)
{
	var newRecord = {};

	for (var key in oldRecord)
	{
		newRecord[key] = oldRecord[key];
	}

	for (var key in updatedFields)
	{
		newRecord[key] = updatedFields[key];
	}

	return newRecord;
}


// APPEND

var _Utils_append = F2(_Utils_ap);

function _Utils_ap(xs, ys)
{
	// append Strings
	if (typeof xs === 'string')
	{
		return xs + ys;
	}

	// append Lists
	if (!xs.b)
	{
		return ys;
	}
	var root = _List_Cons(xs.a, ys);
	xs = xs.b
	for (var curr = root; xs.b; xs = xs.b) // WHILE_CONS
	{
		curr = curr.b = _List_Cons(xs.a, ys);
	}
	return root;
}



var _List_Nil = { $: 0 };
var _List_Nil_UNUSED = { $: '[]' };

function _List_Cons(hd, tl) { return { $: 1, a: hd, b: tl }; }
function _List_Cons_UNUSED(hd, tl) { return { $: '::', a: hd, b: tl }; }


var _List_cons = F2(_List_Cons);

function _List_fromArray(arr)
{
	var out = _List_Nil;
	for (var i = arr.length; i--; )
	{
		out = _List_Cons(arr[i], out);
	}
	return out;
}

function _List_toArray(xs)
{
	for (var out = []; xs.b; xs = xs.b) // WHILE_CONS
	{
		out.push(xs.a);
	}
	return out;
}

var _List_map2 = F3(function(f, xs, ys)
{
	for (var arr = []; xs.b && ys.b; xs = xs.b, ys = ys.b) // WHILE_CONSES
	{
		arr.push(A2(f, xs.a, ys.a));
	}
	return _List_fromArray(arr);
});

var _List_map3 = F4(function(f, xs, ys, zs)
{
	for (var arr = []; xs.b && ys.b && zs.b; xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A3(f, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map4 = F5(function(f, ws, xs, ys, zs)
{
	for (var arr = []; ws.b && xs.b && ys.b && zs.b; ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A4(f, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_map5 = F6(function(f, vs, ws, xs, ys, zs)
{
	for (var arr = []; vs.b && ws.b && xs.b && ys.b && zs.b; vs = vs.b, ws = ws.b, xs = xs.b, ys = ys.b, zs = zs.b) // WHILE_CONSES
	{
		arr.push(A5(f, vs.a, ws.a, xs.a, ys.a, zs.a));
	}
	return _List_fromArray(arr);
});

var _List_sortBy = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		return _Utils_cmp(f(a), f(b));
	}));
});

var _List_sortWith = F2(function(f, xs)
{
	return _List_fromArray(_List_toArray(xs).sort(function(a, b) {
		var ord = A2(f, a, b);
		return ord === $elm$core$Basics$EQ ? 0 : ord === $elm$core$Basics$LT ? -1 : 1;
	}));
});



// MATH

var _Basics_add = F2(function(a, b) { return a + b; });
var _Basics_sub = F2(function(a, b) { return a - b; });
var _Basics_mul = F2(function(a, b) { return a * b; });
var _Basics_fdiv = F2(function(a, b) { return a / b; });
var _Basics_idiv = F2(function(a, b) { return (a / b) | 0; });
var _Basics_pow = F2(Math.pow);

var _Basics_remainderBy = F2(function(b, a) { return a % b; });

// https://www.microsoft.com/en-us/research/wp-content/uploads/2016/02/divmodnote-letter.pdf
var _Basics_modBy = F2(function(modulus, x)
{
	var answer = x % modulus;
	return modulus === 0
		? _Debug_crash(11)
		:
	((answer > 0 && modulus < 0) || (answer < 0 && modulus > 0))
		? answer + modulus
		: answer;
});


// TRIGONOMETRY

var _Basics_pi = Math.PI;
var _Basics_e = Math.E;
var _Basics_cos = Math.cos;
var _Basics_sin = Math.sin;
var _Basics_tan = Math.tan;
var _Basics_acos = Math.acos;
var _Basics_asin = Math.asin;
var _Basics_atan = Math.atan;
var _Basics_atan2 = F2(Math.atan2);


// MORE MATH

function _Basics_toFloat(x) { return x; }
function _Basics_truncate(n) { return n | 0; }
function _Basics_isInfinite(n) { return n === Infinity || n === -Infinity; }

var _Basics_ceiling = Math.ceil;
var _Basics_floor = Math.floor;
var _Basics_round = Math.round;
var _Basics_sqrt = Math.sqrt;
var _Basics_log = Math.log;
var _Basics_isNaN = isNaN;


// BOOLEANS

function _Basics_not(bool) { return !bool; }
var _Basics_and = F2(function(a, b) { return a && b; });
var _Basics_or  = F2(function(a, b) { return a || b; });
var _Basics_xor = F2(function(a, b) { return a !== b; });



var _String_cons = F2(function(chr, str)
{
	return chr + str;
});

function _String_uncons(string)
{
	var word = string.charCodeAt(0);
	return !isNaN(word)
		? $elm$core$Maybe$Just(
			0xD800 <= word && word <= 0xDBFF
				? _Utils_Tuple2(_Utils_chr(string[0] + string[1]), string.slice(2))
				: _Utils_Tuple2(_Utils_chr(string[0]), string.slice(1))
		)
		: $elm$core$Maybe$Nothing;
}

var _String_append = F2(function(a, b)
{
	return a + b;
});

function _String_length(str)
{
	return str.length;
}

var _String_map = F2(function(func, string)
{
	var len = string.length;
	var array = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = string.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			array[i] = func(_Utils_chr(string[i] + string[i+1]));
			i += 2;
			continue;
		}
		array[i] = func(_Utils_chr(string[i]));
		i++;
	}
	return array.join('');
});

var _String_filter = F2(function(isGood, str)
{
	var arr = [];
	var len = str.length;
	var i = 0;
	while (i < len)
	{
		var char = str[i];
		var word = str.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += str[i];
			i++;
		}

		if (isGood(_Utils_chr(char)))
		{
			arr.push(char);
		}
	}
	return arr.join('');
});

function _String_reverse(str)
{
	var len = str.length;
	var arr = new Array(len);
	var i = 0;
	while (i < len)
	{
		var word = str.charCodeAt(i);
		if (0xD800 <= word && word <= 0xDBFF)
		{
			arr[len - i] = str[i + 1];
			i++;
			arr[len - i] = str[i - 1];
			i++;
		}
		else
		{
			arr[len - i] = str[i];
			i++;
		}
	}
	return arr.join('');
}

var _String_foldl = F3(function(func, state, string)
{
	var len = string.length;
	var i = 0;
	while (i < len)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		i++;
		if (0xD800 <= word && word <= 0xDBFF)
		{
			char += string[i];
			i++;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_foldr = F3(function(func, state, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		state = A2(func, _Utils_chr(char), state);
	}
	return state;
});

var _String_split = F2(function(sep, str)
{
	return str.split(sep);
});

var _String_join = F2(function(sep, strs)
{
	return strs.join(sep);
});

var _String_slice = F3(function(start, end, str) {
	return str.slice(start, end);
});

function _String_trim(str)
{
	return str.trim();
}

function _String_trimLeft(str)
{
	return str.replace(/^\s+/, '');
}

function _String_trimRight(str)
{
	return str.replace(/\s+$/, '');
}

function _String_words(str)
{
	return _List_fromArray(str.trim().split(/\s+/g));
}

function _String_lines(str)
{
	return _List_fromArray(str.split(/\r\n|\r|\n/g));
}

function _String_toUpper(str)
{
	return str.toUpperCase();
}

function _String_toLower(str)
{
	return str.toLowerCase();
}

var _String_any = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (isGood(_Utils_chr(char)))
		{
			return true;
		}
	}
	return false;
});

var _String_all = F2(function(isGood, string)
{
	var i = string.length;
	while (i--)
	{
		var char = string[i];
		var word = string.charCodeAt(i);
		if (0xDC00 <= word && word <= 0xDFFF)
		{
			i--;
			char = string[i] + char;
		}
		if (!isGood(_Utils_chr(char)))
		{
			return false;
		}
	}
	return true;
});

var _String_contains = F2(function(sub, str)
{
	return str.indexOf(sub) > -1;
});

var _String_startsWith = F2(function(sub, str)
{
	return str.indexOf(sub) === 0;
});

var _String_endsWith = F2(function(sub, str)
{
	return str.length >= sub.length &&
		str.lastIndexOf(sub) === str.length - sub.length;
});

var _String_indexes = F2(function(sub, str)
{
	var subLen = sub.length;

	if (subLen < 1)
	{
		return _List_Nil;
	}

	var i = 0;
	var is = [];

	while ((i = str.indexOf(sub, i)) > -1)
	{
		is.push(i);
		i = i + subLen;
	}

	return _List_fromArray(is);
});


// TO STRING

function _String_fromNumber(number)
{
	return number + '';
}


// INT CONVERSIONS

function _String_toInt(str)
{
	var total = 0;
	var code0 = str.charCodeAt(0);
	var start = code0 == 0x2B /* + */ || code0 == 0x2D /* - */ ? 1 : 0;

	for (var i = start; i < str.length; ++i)
	{
		var code = str.charCodeAt(i);
		if (code < 0x30 || 0x39 < code)
		{
			return $elm$core$Maybe$Nothing;
		}
		total = 10 * total + code - 0x30;
	}

	return i == start
		? $elm$core$Maybe$Nothing
		: $elm$core$Maybe$Just(code0 == 0x2D ? -total : total);
}


// FLOAT CONVERSIONS

function _String_toFloat(s)
{
	// check if it is a hex, octal, or binary number
	if (s.length === 0 || /[\sxbo]/.test(s))
	{
		return $elm$core$Maybe$Nothing;
	}
	var n = +s;
	// faster isNaN check
	return n === n ? $elm$core$Maybe$Just(n) : $elm$core$Maybe$Nothing;
}

function _String_fromList(chars)
{
	return _List_toArray(chars).join('');
}




function _Char_toCode(char)
{
	var code = char.charCodeAt(0);
	if (0xD800 <= code && code <= 0xDBFF)
	{
		return (code - 0xD800) * 0x400 + char.charCodeAt(1) - 0xDC00 + 0x10000
	}
	return code;
}

function _Char_fromCode(code)
{
	return _Utils_chr(
		(code < 0 || 0x10FFFF < code)
			? '\uFFFD'
			:
		(code <= 0xFFFF)
			? String.fromCharCode(code)
			:
		(code -= 0x10000,
			String.fromCharCode(Math.floor(code / 0x400) + 0xD800, code % 0x400 + 0xDC00)
		)
	);
}

function _Char_toUpper(char)
{
	return _Utils_chr(char.toUpperCase());
}

function _Char_toLower(char)
{
	return _Utils_chr(char.toLowerCase());
}

function _Char_toLocaleUpper(char)
{
	return _Utils_chr(char.toLocaleUpperCase());
}

function _Char_toLocaleLower(char)
{
	return _Utils_chr(char.toLocaleLowerCase());
}



/**_UNUSED/
function _Json_errorToString(error)
{
	return $elm$json$Json$Decode$errorToString(error);
}
//*/


// CORE DECODERS

function _Json_succeed(msg)
{
	return {
		$: 0,
		a: msg
	};
}

function _Json_fail(msg)
{
	return {
		$: 1,
		a: msg
	};
}

function _Json_decodePrim(decoder)
{
	return { $: 2, b: decoder };
}

var _Json_decodeInt = _Json_decodePrim(function(value) {
	return (typeof value !== 'number')
		? _Json_expecting('an INT', value)
		:
	(-2147483647 < value && value < 2147483647 && (value | 0) === value)
		? $elm$core$Result$Ok(value)
		:
	(isFinite(value) && !(value % 1))
		? $elm$core$Result$Ok(value)
		: _Json_expecting('an INT', value);
});

var _Json_decodeBool = _Json_decodePrim(function(value) {
	return (typeof value === 'boolean')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a BOOL', value);
});

var _Json_decodeFloat = _Json_decodePrim(function(value) {
	return (typeof value === 'number')
		? $elm$core$Result$Ok(value)
		: _Json_expecting('a FLOAT', value);
});

var _Json_decodeValue = _Json_decodePrim(function(value) {
	return $elm$core$Result$Ok(_Json_wrap(value));
});

var _Json_decodeString = _Json_decodePrim(function(value) {
	return (typeof value === 'string')
		? $elm$core$Result$Ok(value)
		: (value instanceof String)
			? $elm$core$Result$Ok(value + '')
			: _Json_expecting('a STRING', value);
});

function _Json_decodeList(decoder) { return { $: 3, b: decoder }; }
function _Json_decodeArray(decoder) { return { $: 4, b: decoder }; }

function _Json_decodeNull(value) { return { $: 5, c: value }; }

var _Json_decodeField = F2(function(field, decoder)
{
	return {
		$: 6,
		d: field,
		b: decoder
	};
});

var _Json_decodeIndex = F2(function(index, decoder)
{
	return {
		$: 7,
		e: index,
		b: decoder
	};
});

function _Json_decodeKeyValuePairs(decoder)
{
	return {
		$: 8,
		b: decoder
	};
}

function _Json_mapMany(f, decoders)
{
	return {
		$: 9,
		f: f,
		g: decoders
	};
}

var _Json_andThen = F2(function(callback, decoder)
{
	return {
		$: 10,
		b: decoder,
		h: callback
	};
});

function _Json_oneOf(decoders)
{
	return {
		$: 11,
		g: decoders
	};
}


// DECODING OBJECTS

var _Json_map1 = F2(function(f, d1)
{
	return _Json_mapMany(f, [d1]);
});

var _Json_map2 = F3(function(f, d1, d2)
{
	return _Json_mapMany(f, [d1, d2]);
});

var _Json_map3 = F4(function(f, d1, d2, d3)
{
	return _Json_mapMany(f, [d1, d2, d3]);
});

var _Json_map4 = F5(function(f, d1, d2, d3, d4)
{
	return _Json_mapMany(f, [d1, d2, d3, d4]);
});

var _Json_map5 = F6(function(f, d1, d2, d3, d4, d5)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5]);
});

var _Json_map6 = F7(function(f, d1, d2, d3, d4, d5, d6)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6]);
});

var _Json_map7 = F8(function(f, d1, d2, d3, d4, d5, d6, d7)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7]);
});

var _Json_map8 = F9(function(f, d1, d2, d3, d4, d5, d6, d7, d8)
{
	return _Json_mapMany(f, [d1, d2, d3, d4, d5, d6, d7, d8]);
});


// DECODE

var _Json_runOnString = F2(function(decoder, string)
{
	try
	{
		var value = JSON.parse(string);
		return _Json_runHelp(decoder, value);
	}
	catch (e)
	{
		return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'This is not valid JSON! ' + e.message, _Json_wrap(string)));
	}
});

var _Json_run = F2(function(decoder, value)
{
	return _Json_runHelp(decoder, _Json_unwrap(value));
});

function _Json_runHelp(decoder, value)
{
	switch (decoder.$)
	{
		case 2:
			return decoder.b(value);

		case 5:
			return (value === null)
				? $elm$core$Result$Ok(decoder.c)
				: _Json_expecting('null', value);

		case 3:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('a LIST', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _List_fromArray);

		case 4:
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			return _Json_runArrayDecoder(decoder.b, value, _Json_toElmArray);

		case 6:
			var field = decoder.d;
			if (typeof value !== 'object' || value === null || !(field in value))
			{
				return _Json_expecting('an OBJECT with a field named `' + field + '`', value);
			}
			var result = _Json_runHelp(decoder.b, value[field]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, field, result.a));

		case 7:
			var index = decoder.e;
			if (!_Json_isArray(value))
			{
				return _Json_expecting('an ARRAY', value);
			}
			if (index >= value.length)
			{
				return _Json_expecting('a LONGER array. Need index ' + index + ' but only see ' + value.length + ' entries', value);
			}
			var result = _Json_runHelp(decoder.b, value[index]);
			return ($elm$core$Result$isOk(result)) ? result : $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, index, result.a));

		case 8:
			if (typeof value !== 'object' || value === null || _Json_isArray(value))
			{
				return _Json_expecting('an OBJECT', value);
			}

			var keyValuePairs = _List_Nil;
			// TODO test perf of Object.keys and switch when support is good enough
			for (var key in value)
			{
				if (value.hasOwnProperty(key))
				{
					var result = _Json_runHelp(decoder.b, value[key]);
					if (!$elm$core$Result$isOk(result))
					{
						return $elm$core$Result$Err(A2($elm$json$Json$Decode$Field, key, result.a));
					}
					keyValuePairs = _List_Cons(_Utils_Tuple2(key, result.a), keyValuePairs);
				}
			}
			return $elm$core$Result$Ok($elm$core$List$reverse(keyValuePairs));

		case 9:
			var answer = decoder.f;
			var decoders = decoder.g;
			for (var i = 0; i < decoders.length; i++)
			{
				var result = _Json_runHelp(decoders[i], value);
				if (!$elm$core$Result$isOk(result))
				{
					return result;
				}
				answer = answer(result.a);
			}
			return $elm$core$Result$Ok(answer);

		case 10:
			var result = _Json_runHelp(decoder.b, value);
			return (!$elm$core$Result$isOk(result))
				? result
				: _Json_runHelp(decoder.h(result.a), value);

		case 11:
			var errors = _List_Nil;
			for (var temp = decoder.g; temp.b; temp = temp.b) // WHILE_CONS
			{
				var result = _Json_runHelp(temp.a, value);
				if ($elm$core$Result$isOk(result))
				{
					return result;
				}
				errors = _List_Cons(result.a, errors);
			}
			return $elm$core$Result$Err($elm$json$Json$Decode$OneOf($elm$core$List$reverse(errors)));

		case 1:
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, decoder.a, _Json_wrap(value)));

		case 0:
			return $elm$core$Result$Ok(decoder.a);
	}
}

function _Json_runArrayDecoder(decoder, value, toElmValue)
{
	var len = value.length;
	var array = new Array(len);
	for (var i = 0; i < len; i++)
	{
		var result = _Json_runHelp(decoder, value[i]);
		if (!$elm$core$Result$isOk(result))
		{
			return $elm$core$Result$Err(A2($elm$json$Json$Decode$Index, i, result.a));
		}
		array[i] = result.a;
	}
	return $elm$core$Result$Ok(toElmValue(array));
}

function _Json_isArray(value)
{
	return Array.isArray(value) || (typeof FileList === 'function' && value instanceof FileList);
}

function _Json_toElmArray(array)
{
	return A2($elm$core$Array$initialize, array.length, function(i) { return array[i]; });
}

function _Json_expecting(type, value)
{
	return $elm$core$Result$Err(A2($elm$json$Json$Decode$Failure, 'Expecting ' + type, _Json_wrap(value)));
}


// EQUALITY

function _Json_equality(x, y)
{
	if (x === y)
	{
		return true;
	}

	if (x.$ !== y.$)
	{
		return false;
	}

	switch (x.$)
	{
		case 0:
		case 1:
			return x.a === y.a;

		case 2:
			return x.b === y.b;

		case 5:
			return x.c === y.c;

		case 3:
		case 4:
		case 8:
			return _Json_equality(x.b, y.b);

		case 6:
			return x.d === y.d && _Json_equality(x.b, y.b);

		case 7:
			return x.e === y.e && _Json_equality(x.b, y.b);

		case 9:
			return x.f === y.f && _Json_listEquality(x.g, y.g);

		case 10:
			return x.h === y.h && _Json_equality(x.b, y.b);

		case 11:
			return _Json_listEquality(x.g, y.g);
	}
}

function _Json_listEquality(aDecoders, bDecoders)
{
	var len = aDecoders.length;
	if (len !== bDecoders.length)
	{
		return false;
	}
	for (var i = 0; i < len; i++)
	{
		if (!_Json_equality(aDecoders[i], bDecoders[i]))
		{
			return false;
		}
	}
	return true;
}


// ENCODE

var _Json_encode = F2(function(indentLevel, value)
{
	return JSON.stringify(_Json_unwrap(value), null, indentLevel) + '';
});

function _Json_wrap_UNUSED(value) { return { $: 0, a: value }; }
function _Json_unwrap_UNUSED(value) { return value.a; }

function _Json_wrap(value) { return value; }
function _Json_unwrap(value) { return value; }

function _Json_emptyArray() { return []; }
function _Json_emptyObject() { return {}; }

var _Json_addField = F3(function(key, value, object)
{
	object[key] = _Json_unwrap(value);
	return object;
});

function _Json_addEntry(func)
{
	return F2(function(entry, array)
	{
		array.push(_Json_unwrap(func(entry)));
		return array;
	});
}

var _Json_encodeNull = _Json_wrap(null);



// TASKS

function _Scheduler_succeed(value)
{
	return {
		$: 0,
		a: value
	};
}

function _Scheduler_fail(error)
{
	return {
		$: 1,
		a: error
	};
}

function _Scheduler_binding(callback)
{
	return {
		$: 2,
		b: callback,
		c: null
	};
}

var _Scheduler_andThen = F2(function(callback, task)
{
	return {
		$: 3,
		b: callback,
		d: task
	};
});

var _Scheduler_onError = F2(function(callback, task)
{
	return {
		$: 4,
		b: callback,
		d: task
	};
});

function _Scheduler_receive(callback)
{
	return {
		$: 5,
		b: callback
	};
}


// PROCESSES

var _Scheduler_guid = 0;

function _Scheduler_rawSpawn(task)
{
	var proc = {
		$: 0,
		e: _Scheduler_guid++,
		f: task,
		g: null,
		h: []
	};

	_Scheduler_enqueue(proc);

	return proc;
}

function _Scheduler_spawn(task)
{
	return _Scheduler_binding(function(callback) {
		callback(_Scheduler_succeed(_Scheduler_rawSpawn(task)));
	});
}

function _Scheduler_rawSend(proc, msg)
{
	proc.h.push(msg);
	_Scheduler_enqueue(proc);
}

var _Scheduler_send = F2(function(proc, msg)
{
	return _Scheduler_binding(function(callback) {
		_Scheduler_rawSend(proc, msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});

function _Scheduler_kill(proc)
{
	return _Scheduler_binding(function(callback) {
		var task = proc.f;
		if (task.$ === 2 && task.c)
		{
			task.c();
		}

		proc.f = null;

		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
}


/* STEP PROCESSES

type alias Process =
  { $ : tag
  , id : unique_id
  , root : Task
  , stack : null | { $: SUCCEED | FAIL, a: callback, b: stack }
  , mailbox : [msg]
  }

*/


var _Scheduler_working = false;
var _Scheduler_queue = [];


function _Scheduler_enqueue(proc)
{
	_Scheduler_queue.push(proc);
	if (_Scheduler_working)
	{
		return;
	}
	_Scheduler_working = true;
	while (proc = _Scheduler_queue.shift())
	{
		_Scheduler_step(proc);
	}
	_Scheduler_working = false;
}


function _Scheduler_step(proc)
{
	while (proc.f)
	{
		var rootTag = proc.f.$;
		if (rootTag === 0 || rootTag === 1)
		{
			while (proc.g && proc.g.$ !== rootTag)
			{
				proc.g = proc.g.i;
			}
			if (!proc.g)
			{
				return;
			}
			proc.f = proc.g.b(proc.f.a);
			proc.g = proc.g.i;
		}
		else if (rootTag === 2)
		{
			proc.f.c = proc.f.b(function(newRoot) {
				proc.f = newRoot;
				_Scheduler_enqueue(proc);
			});
			return;
		}
		else if (rootTag === 5)
		{
			if (proc.h.length === 0)
			{
				return;
			}
			proc.f = proc.f.b(proc.h.shift());
		}
		else // if (rootTag === 3 || rootTag === 4)
		{
			proc.g = {
				$: rootTag === 3 ? 0 : 1,
				b: proc.f.b,
				i: proc.g
			};
			proc.f = proc.f.d;
		}
	}
}



function _Process_sleep(time)
{
	return _Scheduler_binding(function(callback) {
		var id = setTimeout(function() {
			callback(_Scheduler_succeed(_Utils_Tuple0));
		}, time);

		return function() { clearTimeout(id); };
	});
}




// PROGRAMS


var _Platform_worker = F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bI,
		impl.cI,
		impl.cH,
		function() { return function() {} }
	);
});



// INITIALIZE A PROGRAM


function _Platform_initialize(flagDecoder, args, init, update, subscriptions, stepperBuilder)
{
	var result = A2(_Json_run, flagDecoder, _Json_wrap(args ? args['flags'] : undefined));
	$elm$core$Result$isOk(result) || _Debug_crash(2 /**_UNUSED/, _Json_errorToString(result.a) /**/);
	var managers = {};
	result = init(result.a);
	var model = result.a;
	var stepper = stepperBuilder(sendToApp, model);
	var ports = _Platform_setupEffects(managers, sendToApp);

	function sendToApp(msg, viewMetadata)
	{
		result = A2(update, msg, model);
		stepper(model = result.a, viewMetadata);
		_Platform_enqueueEffects(managers, result.b, subscriptions(model));
	}

	_Platform_enqueueEffects(managers, result.b, subscriptions(model));

	return ports ? { ports: ports } : {};
}



// TRACK PRELOADS
//
// This is used by code in elm/browser and elm/http
// to register any HTTP requests that are triggered by init.
//


var _Platform_preload;


function _Platform_registerPreload(url)
{
	_Platform_preload.add(url);
}



// EFFECT MANAGERS


var _Platform_effectManagers = {};


function _Platform_setupEffects(managers, sendToApp)
{
	var ports;

	// setup all necessary effect managers
	for (var key in _Platform_effectManagers)
	{
		var manager = _Platform_effectManagers[key];

		if (manager.a)
		{
			ports = ports || {};
			ports[key] = manager.a(key, sendToApp);
		}

		managers[key] = _Platform_instantiateManager(manager, sendToApp);
	}

	return ports;
}


function _Platform_createManager(init, onEffects, onSelfMsg, cmdMap, subMap)
{
	return {
		b: init,
		c: onEffects,
		d: onSelfMsg,
		e: cmdMap,
		f: subMap
	};
}


function _Platform_instantiateManager(info, sendToApp)
{
	var router = {
		g: sendToApp,
		h: undefined
	};

	var onEffects = info.c;
	var onSelfMsg = info.d;
	var cmdMap = info.e;
	var subMap = info.f;

	function loop(state)
	{
		return A2(_Scheduler_andThen, loop, _Scheduler_receive(function(msg)
		{
			var value = msg.a;

			if (msg.$ === 0)
			{
				return A3(onSelfMsg, router, value, state);
			}

			return cmdMap && subMap
				? A4(onEffects, router, value.i, value.j, state)
				: A3(onEffects, router, cmdMap ? value.i : value.j, state);
		}));
	}

	return router.h = _Scheduler_rawSpawn(A2(_Scheduler_andThen, loop, info.b));
}



// ROUTING


var _Platform_sendToApp = F2(function(router, msg)
{
	return _Scheduler_binding(function(callback)
	{
		router.g(msg);
		callback(_Scheduler_succeed(_Utils_Tuple0));
	});
});


var _Platform_sendToSelf = F2(function(router, msg)
{
	return A2(_Scheduler_send, router.h, {
		$: 0,
		a: msg
	});
});



// BAGS


function _Platform_leaf(home)
{
	return function(value)
	{
		return {
			$: 1,
			k: home,
			l: value
		};
	};
}


function _Platform_batch(list)
{
	return {
		$: 2,
		m: list
	};
}


var _Platform_map = F2(function(tagger, bag)
{
	return {
		$: 3,
		n: tagger,
		o: bag
	}
});



// PIPE BAGS INTO EFFECT MANAGERS
//
// Effects must be queued!
//
// Say your init contains a synchronous command, like Time.now or Time.here
//
//   - This will produce a batch of effects (FX_1)
//   - The synchronous task triggers the subsequent `update` call
//   - This will produce a batch of effects (FX_2)
//
// If we just start dispatching FX_2, subscriptions from FX_2 can be processed
// before subscriptions from FX_1. No good! Earlier versions of this code had
// this problem, leading to these reports:
//
//   https://github.com/elm/core/issues/980
//   https://github.com/elm/core/pull/981
//   https://github.com/elm/compiler/issues/1776
//
// The queue is necessary to avoid ordering issues for synchronous commands.


// Why use true/false here? Why not just check the length of the queue?
// The goal is to detect "are we currently dispatching effects?" If we
// are, we need to bail and let the ongoing while loop handle things.
//
// Now say the queue has 1 element. When we dequeue the final element,
// the queue will be empty, but we are still actively dispatching effects.
// So you could get queue jumping in a really tricky category of cases.
//
var _Platform_effectsQueue = [];
var _Platform_effectsActive = false;


function _Platform_enqueueEffects(managers, cmdBag, subBag)
{
	_Platform_effectsQueue.push({ p: managers, q: cmdBag, r: subBag });

	if (_Platform_effectsActive) return;

	_Platform_effectsActive = true;
	for (var fx; fx = _Platform_effectsQueue.shift(); )
	{
		_Platform_dispatchEffects(fx.p, fx.q, fx.r);
	}
	_Platform_effectsActive = false;
}


function _Platform_dispatchEffects(managers, cmdBag, subBag)
{
	var effectsDict = {};
	_Platform_gatherEffects(true, cmdBag, effectsDict, null);
	_Platform_gatherEffects(false, subBag, effectsDict, null);

	for (var home in managers)
	{
		_Scheduler_rawSend(managers[home], {
			$: 'fx',
			a: effectsDict[home] || { i: _List_Nil, j: _List_Nil }
		});
	}
}


function _Platform_gatherEffects(isCmd, bag, effectsDict, taggers)
{
	switch (bag.$)
	{
		case 1:
			var home = bag.k;
			var effect = _Platform_toEffect(isCmd, home, taggers, bag.l);
			effectsDict[home] = _Platform_insert(isCmd, effect, effectsDict[home]);
			return;

		case 2:
			for (var list = bag.m; list.b; list = list.b) // WHILE_CONS
			{
				_Platform_gatherEffects(isCmd, list.a, effectsDict, taggers);
			}
			return;

		case 3:
			_Platform_gatherEffects(isCmd, bag.o, effectsDict, {
				s: bag.n,
				t: taggers
			});
			return;
	}
}


function _Platform_toEffect(isCmd, home, taggers, value)
{
	function applyTaggers(x)
	{
		for (var temp = taggers; temp; temp = temp.t)
		{
			x = temp.s(x);
		}
		return x;
	}

	var map = isCmd
		? _Platform_effectManagers[home].e
		: _Platform_effectManagers[home].f;

	return A2(map, applyTaggers, value)
}


function _Platform_insert(isCmd, newEffect, effects)
{
	effects = effects || { i: _List_Nil, j: _List_Nil };

	isCmd
		? (effects.i = _List_Cons(newEffect, effects.i))
		: (effects.j = _List_Cons(newEffect, effects.j));

	return effects;
}



// PORTS


function _Platform_checkPortName(name)
{
	if (_Platform_effectManagers[name])
	{
		_Debug_crash(3, name)
	}
}



// OUTGOING PORTS


function _Platform_outgoingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		e: _Platform_outgoingPortMap,
		u: converter,
		a: _Platform_setupOutgoingPort
	};
	return _Platform_leaf(name);
}


var _Platform_outgoingPortMap = F2(function(tagger, value) { return value; });


function _Platform_setupOutgoingPort(name)
{
	var subs = [];
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Process_sleep(0);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, cmdList, state)
	{
		for ( ; cmdList.b; cmdList = cmdList.b) // WHILE_CONS
		{
			// grab a separate reference to subs in case unsubscribe is called
			var currentSubs = subs;
			var value = _Json_unwrap(converter(cmdList.a));
			for (var i = 0; i < currentSubs.length; i++)
			{
				currentSubs[i](value);
			}
		}
		return init;
	});

	// PUBLIC API

	function subscribe(callback)
	{
		subs.push(callback);
	}

	function unsubscribe(callback)
	{
		// copy subs into a new array in case unsubscribe is called within a
		// subscribed callback
		subs = subs.slice();
		var index = subs.indexOf(callback);
		if (index >= 0)
		{
			subs.splice(index, 1);
		}
	}

	return {
		subscribe: subscribe,
		unsubscribe: unsubscribe
	};
}



// INCOMING PORTS


function _Platform_incomingPort(name, converter)
{
	_Platform_checkPortName(name);
	_Platform_effectManagers[name] = {
		f: _Platform_incomingPortMap,
		u: converter,
		a: _Platform_setupIncomingPort
	};
	return _Platform_leaf(name);
}


var _Platform_incomingPortMap = F2(function(tagger, finalTagger)
{
	return function(value)
	{
		return tagger(finalTagger(value));
	};
});


function _Platform_setupIncomingPort(name, sendToApp)
{
	var subs = _List_Nil;
	var converter = _Platform_effectManagers[name].u;

	// CREATE MANAGER

	var init = _Scheduler_succeed(null);

	_Platform_effectManagers[name].b = init;
	_Platform_effectManagers[name].c = F3(function(router, subList, state)
	{
		subs = subList;
		return init;
	});

	// PUBLIC API

	function send(incomingValue)
	{
		var result = A2(_Json_run, converter, _Json_wrap(incomingValue));

		$elm$core$Result$isOk(result) || _Debug_crash(4, name, result.a);

		var value = result.a;
		for (var temp = subs; temp.b; temp = temp.b) // WHILE_CONS
		{
			sendToApp(temp.a(value));
		}
	}

	return { send: send };
}



// EXPORT ELM MODULES
//
// Have DEBUG and PROD versions so that we can (1) give nicer errors in
// debug mode and (2) not pay for the bits needed for that in prod mode.
//


function _Platform_export(exports)
{
	scope['Elm']
		? _Platform_mergeExportsProd(scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsProd(obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6)
				: _Platform_mergeExportsProd(obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}


function _Platform_export_UNUSED(exports)
{
	scope['Elm']
		? _Platform_mergeExportsDebug('Elm', scope['Elm'], exports)
		: scope['Elm'] = exports;
}


function _Platform_mergeExportsDebug(moduleName, obj, exports)
{
	for (var name in exports)
	{
		(name in obj)
			? (name == 'init')
				? _Debug_crash(6, moduleName)
				: _Platform_mergeExportsDebug(moduleName + '.' + name, obj[name], exports[name])
			: (obj[name] = exports[name]);
	}
}




// HELPERS


var _VirtualDom_divertHrefToApp;

var _VirtualDom_doc = typeof document !== 'undefined' ? document : {};


function _VirtualDom_appendChild(parent, child)
{
	parent.appendChild(child);
}

var _VirtualDom_init = F4(function(virtualNode, flagDecoder, debugMetadata, args)
{
	// NOTE: this function needs _Platform_export available to work

	/**/
	var node = args['node'];
	//*/
	/**_UNUSED/
	var node = args && args['node'] ? args['node'] : _Debug_crash(0);
	//*/

	node.parentNode.replaceChild(
		_VirtualDom_render(virtualNode, function() {}),
		node
	);

	return {};
});



// TEXT


function _VirtualDom_text(string)
{
	return {
		$: 0,
		a: string
	};
}



// NODE


var _VirtualDom_nodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 1,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_node = _VirtualDom_nodeNS(undefined);



// KEYED NODE


var _VirtualDom_keyedNodeNS = F2(function(namespace, tag)
{
	return F2(function(factList, kidList)
	{
		for (var kids = [], descendantsCount = 0; kidList.b; kidList = kidList.b) // WHILE_CONS
		{
			var kid = kidList.a;
			descendantsCount += (kid.b.b || 0);
			kids.push(kid);
		}
		descendantsCount += kids.length;

		return {
			$: 2,
			c: tag,
			d: _VirtualDom_organizeFacts(factList),
			e: kids,
			f: namespace,
			b: descendantsCount
		};
	});
});


var _VirtualDom_keyedNode = _VirtualDom_keyedNodeNS(undefined);



// CUSTOM


function _VirtualDom_custom(factList, model, render, diff)
{
	return {
		$: 3,
		d: _VirtualDom_organizeFacts(factList),
		g: model,
		h: render,
		i: diff
	};
}



// MAP


var _VirtualDom_map = F2(function(tagger, node)
{
	return {
		$: 4,
		j: tagger,
		k: node,
		b: 1 + (node.b || 0)
	};
});



// LAZY


function _VirtualDom_thunk(refs, thunk)
{
	return {
		$: 5,
		l: refs,
		m: thunk,
		k: undefined
	};
}

var _VirtualDom_lazy = F2(function(func, a)
{
	return _VirtualDom_thunk([func, a], function() {
		return func(a);
	});
});

var _VirtualDom_lazy2 = F3(function(func, a, b)
{
	return _VirtualDom_thunk([func, a, b], function() {
		return A2(func, a, b);
	});
});

var _VirtualDom_lazy3 = F4(function(func, a, b, c)
{
	return _VirtualDom_thunk([func, a, b, c], function() {
		return A3(func, a, b, c);
	});
});

var _VirtualDom_lazy4 = F5(function(func, a, b, c, d)
{
	return _VirtualDom_thunk([func, a, b, c, d], function() {
		return A4(func, a, b, c, d);
	});
});

var _VirtualDom_lazy5 = F6(function(func, a, b, c, d, e)
{
	return _VirtualDom_thunk([func, a, b, c, d, e], function() {
		return A5(func, a, b, c, d, e);
	});
});

var _VirtualDom_lazy6 = F7(function(func, a, b, c, d, e, f)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f], function() {
		return A6(func, a, b, c, d, e, f);
	});
});

var _VirtualDom_lazy7 = F8(function(func, a, b, c, d, e, f, g)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g], function() {
		return A7(func, a, b, c, d, e, f, g);
	});
});

var _VirtualDom_lazy8 = F9(function(func, a, b, c, d, e, f, g, h)
{
	return _VirtualDom_thunk([func, a, b, c, d, e, f, g, h], function() {
		return A8(func, a, b, c, d, e, f, g, h);
	});
});



// FACTS


var _VirtualDom_on = F2(function(key, handler)
{
	return {
		$: 'a0',
		n: key,
		o: handler
	};
});
var _VirtualDom_style = F2(function(key, value)
{
	return {
		$: 'a1',
		n: key,
		o: value
	};
});
var _VirtualDom_property = F2(function(key, value)
{
	return {
		$: 'a2',
		n: key,
		o: value
	};
});
var _VirtualDom_attribute = F2(function(key, value)
{
	return {
		$: 'a3',
		n: key,
		o: value
	};
});
var _VirtualDom_attributeNS = F3(function(namespace, key, value)
{
	return {
		$: 'a4',
		n: key,
		o: { f: namespace, o: value }
	};
});



// XSS ATTACK VECTOR CHECKS


function _VirtualDom_noScript(tag)
{
	return tag == 'script' ? 'p' : tag;
}

function _VirtualDom_noOnOrFormAction(key)
{
	return /^(on|formAction$)/i.test(key) ? 'data-' + key : key;
}

function _VirtualDom_noInnerHtmlOrFormAction(key)
{
	return key == 'innerHTML' || key == 'formAction' ? 'data-' + key : key;
}

function _VirtualDom_noJavaScriptUri(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,'')) ? '' : value;
}

function _VirtualDom_noJavaScriptUri_UNUSED(value)
{
	return /^javascript:/i.test(value.replace(/\s/g,''))
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}

function _VirtualDom_noJavaScriptOrHtmlUri(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value) ? '' : value;
}

function _VirtualDom_noJavaScriptOrHtmlUri_UNUSED(value)
{
	return /^\s*(javascript:|data:text\/html)/i.test(value)
		? 'javascript:alert("This is an XSS vector. Please use ports or web components instead.")'
		: value;
}



// MAP FACTS


var _VirtualDom_mapAttribute = F2(function(func, attr)
{
	return (attr.$ === 'a0')
		? A2(_VirtualDom_on, attr.n, _VirtualDom_mapHandler(func, attr.o))
		: attr;
});

function _VirtualDom_mapHandler(func, handler)
{
	var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

	// 0 = Normal
	// 1 = MayStopPropagation
	// 2 = MayPreventDefault
	// 3 = Custom

	return {
		$: handler.$,
		a:
			!tag
				? A2($elm$json$Json$Decode$map, func, handler.a)
				:
			A3($elm$json$Json$Decode$map2,
				tag < 3
					? _VirtualDom_mapEventTuple
					: _VirtualDom_mapEventRecord,
				$elm$json$Json$Decode$succeed(func),
				handler.a
			)
	};
}

var _VirtualDom_mapEventTuple = F2(function(func, tuple)
{
	return _Utils_Tuple2(func(tuple.a), tuple.b);
});

var _VirtualDom_mapEventRecord = F2(function(func, record)
{
	return {
		bh: func(record.bh),
		bn: record.bn,
		bk: record.bk
	}
});



// ORGANIZE FACTS


function _VirtualDom_organizeFacts(factList)
{
	for (var facts = {}; factList.b; factList = factList.b) // WHILE_CONS
	{
		var entry = factList.a;

		var tag = entry.$;
		var key = entry.n;
		var value = entry.o;

		if (tag === 'a2')
		{
			(key === 'className')
				? _VirtualDom_addClass(facts, key, _Json_unwrap(value))
				: facts[key] = _Json_unwrap(value);

			continue;
		}

		var subFacts = facts[tag] || (facts[tag] = {});
		(tag === 'a3' && key === 'class')
			? _VirtualDom_addClass(subFacts, key, value)
			: subFacts[key] = value;
	}

	return facts;
}

function _VirtualDom_addClass(object, key, newClass)
{
	var classes = object[key];
	object[key] = classes ? classes + ' ' + newClass : newClass;
}



// RENDER


function _VirtualDom_render(vNode, eventNode)
{
	var tag = vNode.$;

	if (tag === 5)
	{
		return _VirtualDom_render(vNode.k || (vNode.k = vNode.m()), eventNode);
	}

	if (tag === 0)
	{
		return _VirtualDom_doc.createTextNode(vNode.a);
	}

	if (tag === 4)
	{
		var subNode = vNode.k;
		var tagger = vNode.j;

		while (subNode.$ === 4)
		{
			typeof tagger !== 'object'
				? tagger = [tagger, subNode.j]
				: tagger.push(subNode.j);

			subNode = subNode.k;
		}

		var subEventRoot = { j: tagger, p: eventNode };
		var domNode = _VirtualDom_render(subNode, subEventRoot);
		domNode.elm_event_node_ref = subEventRoot;
		return domNode;
	}

	if (tag === 3)
	{
		var domNode = vNode.h(vNode.g);
		_VirtualDom_applyFacts(domNode, eventNode, vNode.d);
		return domNode;
	}

	// at this point `tag` must be 1 or 2

	var domNode = vNode.f
		? _VirtualDom_doc.createElementNS(vNode.f, vNode.c)
		: _VirtualDom_doc.createElement(vNode.c);

	if (_VirtualDom_divertHrefToApp && vNode.c == 'a')
	{
		domNode.addEventListener('click', _VirtualDom_divertHrefToApp(domNode));
	}

	_VirtualDom_applyFacts(domNode, eventNode, vNode.d);

	for (var kids = vNode.e, i = 0; i < kids.length; i++)
	{
		_VirtualDom_appendChild(domNode, _VirtualDom_render(tag === 1 ? kids[i] : kids[i].b, eventNode));
	}

	return domNode;
}



// APPLY FACTS


function _VirtualDom_applyFacts(domNode, eventNode, facts)
{
	for (var key in facts)
	{
		var value = facts[key];

		key === 'a1'
			? _VirtualDom_applyStyles(domNode, value)
			:
		key === 'a0'
			? _VirtualDom_applyEvents(domNode, eventNode, value)
			:
		key === 'a3'
			? _VirtualDom_applyAttrs(domNode, value)
			:
		key === 'a4'
			? _VirtualDom_applyAttrsNS(domNode, value)
			:
		((key !== 'value' && key !== 'checked') || domNode[key] !== value) && (domNode[key] = value);
	}
}



// APPLY STYLES


function _VirtualDom_applyStyles(domNode, styles)
{
	var domNodeStyle = domNode.style;

	for (var key in styles)
	{
		domNodeStyle[key] = styles[key];
	}
}



// APPLY ATTRS


function _VirtualDom_applyAttrs(domNode, attrs)
{
	for (var key in attrs)
	{
		var value = attrs[key];
		typeof value !== 'undefined'
			? domNode.setAttribute(key, value)
			: domNode.removeAttribute(key);
	}
}



// APPLY NAMESPACED ATTRS


function _VirtualDom_applyAttrsNS(domNode, nsAttrs)
{
	for (var key in nsAttrs)
	{
		var pair = nsAttrs[key];
		var namespace = pair.f;
		var value = pair.o;

		typeof value !== 'undefined'
			? domNode.setAttributeNS(namespace, key, value)
			: domNode.removeAttributeNS(namespace, key);
	}
}



// APPLY EVENTS


function _VirtualDom_applyEvents(domNode, eventNode, events)
{
	var allCallbacks = domNode.elmFs || (domNode.elmFs = {});

	for (var key in events)
	{
		var newHandler = events[key];
		var oldCallback = allCallbacks[key];

		if (!newHandler)
		{
			domNode.removeEventListener(key, oldCallback);
			allCallbacks[key] = undefined;
			continue;
		}

		if (oldCallback)
		{
			var oldHandler = oldCallback.q;
			if (oldHandler.$ === newHandler.$)
			{
				oldCallback.q = newHandler;
				continue;
			}
			domNode.removeEventListener(key, oldCallback);
		}

		oldCallback = _VirtualDom_makeCallback(eventNode, newHandler);
		domNode.addEventListener(key, oldCallback,
			_VirtualDom_passiveSupported
			&& { passive: $elm$virtual_dom$VirtualDom$toHandlerInt(newHandler) < 2 }
		);
		allCallbacks[key] = oldCallback;
	}
}



// PASSIVE EVENTS


var _VirtualDom_passiveSupported;

try
{
	window.addEventListener('t', null, Object.defineProperty({}, 'passive', {
		get: function() { _VirtualDom_passiveSupported = true; }
	}));
}
catch(e) {}



// EVENT HANDLERS


function _VirtualDom_makeCallback(eventNode, initialHandler)
{
	function callback(event)
	{
		var handler = callback.q;
		var result = _Json_runHelp(handler.a, event);

		if (!$elm$core$Result$isOk(result))
		{
			return;
		}

		var tag = $elm$virtual_dom$VirtualDom$toHandlerInt(handler);

		// 0 = Normal
		// 1 = MayStopPropagation
		// 2 = MayPreventDefault
		// 3 = Custom

		var value = result.a;
		var message = !tag ? value : tag < 3 ? value.a : value.bh;
		var stopPropagation = tag == 1 ? value.b : tag == 3 && value.bn;
		var currentEventNode = (
			stopPropagation && event.stopPropagation(),
			(tag == 2 ? value.b : tag == 3 && value.bk) && event.preventDefault(),
			eventNode
		);
		var tagger;
		var i;
		while (tagger = currentEventNode.j)
		{
			if (typeof tagger == 'function')
			{
				message = tagger(message);
			}
			else
			{
				for (var i = tagger.length; i--; )
				{
					message = tagger[i](message);
				}
			}
			currentEventNode = currentEventNode.p;
		}
		currentEventNode(message, stopPropagation); // stopPropagation implies isSync
	}

	callback.q = initialHandler;

	return callback;
}

function _VirtualDom_equalEvents(x, y)
{
	return x.$ == y.$ && _Json_equality(x.a, y.a);
}



// DIFF


// TODO: Should we do patches like in iOS?
//
// type Patch
//   = At Int Patch
//   | Batch (List Patch)
//   | Change ...
//
// How could it not be better?
//
function _VirtualDom_diff(x, y)
{
	var patches = [];
	_VirtualDom_diffHelp(x, y, patches, 0);
	return patches;
}


function _VirtualDom_pushPatch(patches, type, index, data)
{
	var patch = {
		$: type,
		r: index,
		s: data,
		t: undefined,
		u: undefined
	};
	patches.push(patch);
	return patch;
}


function _VirtualDom_diffHelp(x, y, patches, index)
{
	if (x === y)
	{
		return;
	}

	var xType = x.$;
	var yType = y.$;

	// Bail if you run into different types of nodes. Implies that the
	// structure has changed significantly and it's not worth a diff.
	if (xType !== yType)
	{
		if (xType === 1 && yType === 2)
		{
			y = _VirtualDom_dekey(y);
			yType = 1;
		}
		else
		{
			_VirtualDom_pushPatch(patches, 0, index, y);
			return;
		}
	}

	// Now we know that both nodes are the same $.
	switch (yType)
	{
		case 5:
			var xRefs = x.l;
			var yRefs = y.l;
			var i = xRefs.length;
			var same = i === yRefs.length;
			while (same && i--)
			{
				same = xRefs[i] === yRefs[i];
			}
			if (same)
			{
				y.k = x.k;
				return;
			}
			y.k = y.m();
			var subPatches = [];
			_VirtualDom_diffHelp(x.k, y.k, subPatches, 0);
			subPatches.length > 0 && _VirtualDom_pushPatch(patches, 1, index, subPatches);
			return;

		case 4:
			// gather nested taggers
			var xTaggers = x.j;
			var yTaggers = y.j;
			var nesting = false;

			var xSubNode = x.k;
			while (xSubNode.$ === 4)
			{
				nesting = true;

				typeof xTaggers !== 'object'
					? xTaggers = [xTaggers, xSubNode.j]
					: xTaggers.push(xSubNode.j);

				xSubNode = xSubNode.k;
			}

			var ySubNode = y.k;
			while (ySubNode.$ === 4)
			{
				nesting = true;

				typeof yTaggers !== 'object'
					? yTaggers = [yTaggers, ySubNode.j]
					: yTaggers.push(ySubNode.j);

				ySubNode = ySubNode.k;
			}

			// Just bail if different numbers of taggers. This implies the
			// structure of the virtual DOM has changed.
			if (nesting && xTaggers.length !== yTaggers.length)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			// check if taggers are "the same"
			if (nesting ? !_VirtualDom_pairwiseRefEqual(xTaggers, yTaggers) : xTaggers !== yTaggers)
			{
				_VirtualDom_pushPatch(patches, 2, index, yTaggers);
			}

			// diff everything below the taggers
			_VirtualDom_diffHelp(xSubNode, ySubNode, patches, index + 1);
			return;

		case 0:
			if (x.a !== y.a)
			{
				_VirtualDom_pushPatch(patches, 3, index, y.a);
			}
			return;

		case 1:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKids);
			return;

		case 2:
			_VirtualDom_diffNodes(x, y, patches, index, _VirtualDom_diffKeyedKids);
			return;

		case 3:
			if (x.h !== y.h)
			{
				_VirtualDom_pushPatch(patches, 0, index, y);
				return;
			}

			var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
			factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

			var patch = y.i(x.g, y.g);
			patch && _VirtualDom_pushPatch(patches, 5, index, patch);

			return;
	}
}

// assumes the incoming arrays are the same length
function _VirtualDom_pairwiseRefEqual(as, bs)
{
	for (var i = 0; i < as.length; i++)
	{
		if (as[i] !== bs[i])
		{
			return false;
		}
	}

	return true;
}

function _VirtualDom_diffNodes(x, y, patches, index, diffKids)
{
	// Bail if obvious indicators have changed. Implies more serious
	// structural changes such that it's not worth it to diff.
	if (x.c !== y.c || x.f !== y.f)
	{
		_VirtualDom_pushPatch(patches, 0, index, y);
		return;
	}

	var factsDiff = _VirtualDom_diffFacts(x.d, y.d);
	factsDiff && _VirtualDom_pushPatch(patches, 4, index, factsDiff);

	diffKids(x, y, patches, index);
}



// DIFF FACTS


// TODO Instead of creating a new diff object, it's possible to just test if
// there *is* a diff. During the actual patch, do the diff again and make the
// modifications directly. This way, there's no new allocations. Worth it?
function _VirtualDom_diffFacts(x, y, category)
{
	var diff;

	// look for changes and removals
	for (var xKey in x)
	{
		if (xKey === 'a1' || xKey === 'a0' || xKey === 'a3' || xKey === 'a4')
		{
			var subDiff = _VirtualDom_diffFacts(x[xKey], y[xKey] || {}, xKey);
			if (subDiff)
			{
				diff = diff || {};
				diff[xKey] = subDiff;
			}
			continue;
		}

		// remove if not in the new facts
		if (!(xKey in y))
		{
			diff = diff || {};
			diff[xKey] =
				!category
					? (typeof x[xKey] === 'string' ? '' : null)
					:
				(category === 'a1')
					? ''
					:
				(category === 'a0' || category === 'a3')
					? undefined
					:
				{ f: x[xKey].f, o: undefined };

			continue;
		}

		var xValue = x[xKey];
		var yValue = y[xKey];

		// reference equal, so don't worry about it
		if (xValue === yValue && xKey !== 'value' && xKey !== 'checked'
			|| category === 'a0' && _VirtualDom_equalEvents(xValue, yValue))
		{
			continue;
		}

		diff = diff || {};
		diff[xKey] = yValue;
	}

	// add new stuff
	for (var yKey in y)
	{
		if (!(yKey in x))
		{
			diff = diff || {};
			diff[yKey] = y[yKey];
		}
	}

	return diff;
}



// DIFF KIDS


function _VirtualDom_diffKids(xParent, yParent, patches, index)
{
	var xKids = xParent.e;
	var yKids = yParent.e;

	var xLen = xKids.length;
	var yLen = yKids.length;

	// FIGURE OUT IF THERE ARE INSERTS OR REMOVALS

	if (xLen > yLen)
	{
		_VirtualDom_pushPatch(patches, 6, index, {
			v: yLen,
			i: xLen - yLen
		});
	}
	else if (xLen < yLen)
	{
		_VirtualDom_pushPatch(patches, 7, index, {
			v: xLen,
			e: yKids
		});
	}

	// PAIRWISE DIFF EVERYTHING ELSE

	for (var minLen = xLen < yLen ? xLen : yLen, i = 0; i < minLen; i++)
	{
		var xKid = xKids[i];
		_VirtualDom_diffHelp(xKid, yKids[i], patches, ++index);
		index += xKid.b || 0;
	}
}



// KEYED DIFF


function _VirtualDom_diffKeyedKids(xParent, yParent, patches, rootIndex)
{
	var localPatches = [];

	var changes = {}; // Dict String Entry
	var inserts = []; // Array { index : Int, entry : Entry }
	// type Entry = { tag : String, vnode : VNode, index : Int, data : _ }

	var xKids = xParent.e;
	var yKids = yParent.e;
	var xLen = xKids.length;
	var yLen = yKids.length;
	var xIndex = 0;
	var yIndex = 0;

	var index = rootIndex;

	while (xIndex < xLen && yIndex < yLen)
	{
		var x = xKids[xIndex];
		var y = yKids[yIndex];

		var xKey = x.a;
		var yKey = y.a;
		var xNode = x.b;
		var yNode = y.b;

		var newMatch = undefined;
		var oldMatch = undefined;

		// check if keys match

		if (xKey === yKey)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNode, localPatches, index);
			index += xNode.b || 0;

			xIndex++;
			yIndex++;
			continue;
		}

		// look ahead 1 to detect insertions and removals.

		var xNext = xKids[xIndex + 1];
		var yNext = yKids[yIndex + 1];

		if (xNext)
		{
			var xNextKey = xNext.a;
			var xNextNode = xNext.b;
			oldMatch = yKey === xNextKey;
		}

		if (yNext)
		{
			var yNextKey = yNext.a;
			var yNextNode = yNext.b;
			newMatch = xKey === yNextKey;
		}


		// swap x and y
		if (newMatch && oldMatch)
		{
			index++;
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			_VirtualDom_insertNode(changes, localPatches, xKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNextNode, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		// insert y
		if (newMatch)
		{
			index++;
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			_VirtualDom_diffHelp(xNode, yNextNode, localPatches, index);
			index += xNode.b || 0;

			xIndex += 1;
			yIndex += 2;
			continue;
		}

		// remove x
		if (oldMatch)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 1;
			continue;
		}

		// remove x, insert y
		if (xNext && xNextKey === yNextKey)
		{
			index++;
			_VirtualDom_removeNode(changes, localPatches, xKey, xNode, index);
			_VirtualDom_insertNode(changes, localPatches, yKey, yNode, yIndex, inserts);
			index += xNode.b || 0;

			index++;
			_VirtualDom_diffHelp(xNextNode, yNextNode, localPatches, index);
			index += xNextNode.b || 0;

			xIndex += 2;
			yIndex += 2;
			continue;
		}

		break;
	}

	// eat up any remaining nodes with removeNode and insertNode

	while (xIndex < xLen)
	{
		index++;
		var x = xKids[xIndex];
		var xNode = x.b;
		_VirtualDom_removeNode(changes, localPatches, x.a, xNode, index);
		index += xNode.b || 0;
		xIndex++;
	}

	while (yIndex < yLen)
	{
		var endInserts = endInserts || [];
		var y = yKids[yIndex];
		_VirtualDom_insertNode(changes, localPatches, y.a, y.b, undefined, endInserts);
		yIndex++;
	}

	if (localPatches.length > 0 || inserts.length > 0 || endInserts)
	{
		_VirtualDom_pushPatch(patches, 8, rootIndex, {
			w: localPatches,
			x: inserts,
			y: endInserts
		});
	}
}



// CHANGES FROM KEYED DIFF


var _VirtualDom_POSTFIX = '_elmW6BL';


function _VirtualDom_insertNode(changes, localPatches, key, vnode, yIndex, inserts)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		entry = {
			c: 0,
			z: vnode,
			r: yIndex,
			s: undefined
		};

		inserts.push({ r: yIndex, A: entry });
		changes[key] = entry;

		return;
	}

	// this key was removed earlier, a match!
	if (entry.c === 1)
	{
		inserts.push({ r: yIndex, A: entry });

		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(entry.z, vnode, subPatches, entry.r);
		entry.r = yIndex;
		entry.s.s = {
			w: subPatches,
			A: entry
		};

		return;
	}

	// this key has already been inserted or moved, a duplicate!
	_VirtualDom_insertNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, yIndex, inserts);
}


function _VirtualDom_removeNode(changes, localPatches, key, vnode, index)
{
	var entry = changes[key];

	// never seen this key before
	if (!entry)
	{
		var patch = _VirtualDom_pushPatch(localPatches, 9, index, undefined);

		changes[key] = {
			c: 1,
			z: vnode,
			r: index,
			s: patch
		};

		return;
	}

	// this key was inserted earlier, a match!
	if (entry.c === 0)
	{
		entry.c = 2;
		var subPatches = [];
		_VirtualDom_diffHelp(vnode, entry.z, subPatches, index);

		_VirtualDom_pushPatch(localPatches, 9, index, {
			w: subPatches,
			A: entry
		});

		return;
	}

	// this key has already been removed or moved, a duplicate!
	_VirtualDom_removeNode(changes, localPatches, key + _VirtualDom_POSTFIX, vnode, index);
}



// ADD DOM NODES
//
// Each DOM node has an "index" assigned in order of traversal. It is important
// to minimize our crawl over the actual DOM, so these indexes (along with the
// descendantsCount of virtual nodes) let us skip touching entire subtrees of
// the DOM if we know there are no patches there.


function _VirtualDom_addDomNodes(domNode, vNode, patches, eventNode)
{
	_VirtualDom_addDomNodesHelp(domNode, vNode, patches, 0, 0, vNode.b, eventNode);
}


// assumes `patches` is non-empty and indexes increase monotonically.
function _VirtualDom_addDomNodesHelp(domNode, vNode, patches, i, low, high, eventNode)
{
	var patch = patches[i];
	var index = patch.r;

	while (index === low)
	{
		var patchType = patch.$;

		if (patchType === 1)
		{
			_VirtualDom_addDomNodes(domNode, vNode.k, patch.s, eventNode);
		}
		else if (patchType === 8)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var subPatches = patch.s.w;
			if (subPatches.length > 0)
			{
				_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
			}
		}
		else if (patchType === 9)
		{
			patch.t = domNode;
			patch.u = eventNode;

			var data = patch.s;
			if (data)
			{
				data.A.s = domNode;
				var subPatches = data.w;
				if (subPatches.length > 0)
				{
					_VirtualDom_addDomNodesHelp(domNode, vNode, subPatches, 0, low, high, eventNode);
				}
			}
		}
		else
		{
			patch.t = domNode;
			patch.u = eventNode;
		}

		i++;

		if (!(patch = patches[i]) || (index = patch.r) > high)
		{
			return i;
		}
	}

	var tag = vNode.$;

	if (tag === 4)
	{
		var subNode = vNode.k;

		while (subNode.$ === 4)
		{
			subNode = subNode.k;
		}

		return _VirtualDom_addDomNodesHelp(domNode, subNode, patches, i, low + 1, high, domNode.elm_event_node_ref);
	}

	// tag must be 1 or 2 at this point

	var vKids = vNode.e;
	var childNodes = domNode.childNodes;
	for (var j = 0; j < vKids.length; j++)
	{
		low++;
		var vKid = tag === 1 ? vKids[j] : vKids[j].b;
		var nextLow = low + (vKid.b || 0);
		if (low <= index && index <= nextLow)
		{
			i = _VirtualDom_addDomNodesHelp(childNodes[j], vKid, patches, i, low, nextLow, eventNode);
			if (!(patch = patches[i]) || (index = patch.r) > high)
			{
				return i;
			}
		}
		low = nextLow;
	}
	return i;
}



// APPLY PATCHES


function _VirtualDom_applyPatches(rootDomNode, oldVirtualNode, patches, eventNode)
{
	if (patches.length === 0)
	{
		return rootDomNode;
	}

	_VirtualDom_addDomNodes(rootDomNode, oldVirtualNode, patches, eventNode);
	return _VirtualDom_applyPatchesHelp(rootDomNode, patches);
}

function _VirtualDom_applyPatchesHelp(rootDomNode, patches)
{
	for (var i = 0; i < patches.length; i++)
	{
		var patch = patches[i];
		var localDomNode = patch.t
		var newNode = _VirtualDom_applyPatch(localDomNode, patch);
		if (localDomNode === rootDomNode)
		{
			rootDomNode = newNode;
		}
	}
	return rootDomNode;
}

function _VirtualDom_applyPatch(domNode, patch)
{
	switch (patch.$)
	{
		case 0:
			return _VirtualDom_applyPatchRedraw(domNode, patch.s, patch.u);

		case 4:
			_VirtualDom_applyFacts(domNode, patch.u, patch.s);
			return domNode;

		case 3:
			domNode.replaceData(0, domNode.length, patch.s);
			return domNode;

		case 1:
			return _VirtualDom_applyPatchesHelp(domNode, patch.s);

		case 2:
			if (domNode.elm_event_node_ref)
			{
				domNode.elm_event_node_ref.j = patch.s;
			}
			else
			{
				domNode.elm_event_node_ref = { j: patch.s, p: patch.u };
			}
			return domNode;

		case 6:
			var data = patch.s;
			for (var i = 0; i < data.i; i++)
			{
				domNode.removeChild(domNode.childNodes[data.v]);
			}
			return domNode;

		case 7:
			var data = patch.s;
			var kids = data.e;
			var i = data.v;
			var theEnd = domNode.childNodes[i];
			for (; i < kids.length; i++)
			{
				domNode.insertBefore(_VirtualDom_render(kids[i], patch.u), theEnd);
			}
			return domNode;

		case 9:
			var data = patch.s;
			if (!data)
			{
				domNode.parentNode.removeChild(domNode);
				return domNode;
			}
			var entry = data.A;
			if (typeof entry.r !== 'undefined')
			{
				domNode.parentNode.removeChild(domNode);
			}
			entry.s = _VirtualDom_applyPatchesHelp(domNode, data.w);
			return domNode;

		case 8:
			return _VirtualDom_applyPatchReorder(domNode, patch);

		case 5:
			return patch.s(domNode);

		default:
			_Debug_crash(10); // 'Ran into an unknown patch!'
	}
}


function _VirtualDom_applyPatchRedraw(domNode, vNode, eventNode)
{
	var parentNode = domNode.parentNode;
	var newNode = _VirtualDom_render(vNode, eventNode);

	if (!newNode.elm_event_node_ref)
	{
		newNode.elm_event_node_ref = domNode.elm_event_node_ref;
	}

	if (parentNode && newNode !== domNode)
	{
		parentNode.replaceChild(newNode, domNode);
	}
	return newNode;
}


function _VirtualDom_applyPatchReorder(domNode, patch)
{
	var data = patch.s;

	// remove end inserts
	var frag = _VirtualDom_applyPatchReorderEndInsertsHelp(data.y, patch);

	// removals
	domNode = _VirtualDom_applyPatchesHelp(domNode, data.w);

	// inserts
	var inserts = data.x;
	for (var i = 0; i < inserts.length; i++)
	{
		var insert = inserts[i];
		var entry = insert.A;
		var node = entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u);
		domNode.insertBefore(node, domNode.childNodes[insert.r]);
	}

	// add end inserts
	if (frag)
	{
		_VirtualDom_appendChild(domNode, frag);
	}

	return domNode;
}


function _VirtualDom_applyPatchReorderEndInsertsHelp(endInserts, patch)
{
	if (!endInserts)
	{
		return;
	}

	var frag = _VirtualDom_doc.createDocumentFragment();
	for (var i = 0; i < endInserts.length; i++)
	{
		var insert = endInserts[i];
		var entry = insert.A;
		_VirtualDom_appendChild(frag, entry.c === 2
			? entry.s
			: _VirtualDom_render(entry.z, patch.u)
		);
	}
	return frag;
}


function _VirtualDom_virtualize(node)
{
	// TEXT NODES

	if (node.nodeType === 3)
	{
		return _VirtualDom_text(node.textContent);
	}


	// WEIRD NODES

	if (node.nodeType !== 1)
	{
		return _VirtualDom_text('');
	}


	// ELEMENT NODES

	var attrList = _List_Nil;
	var attrs = node.attributes;
	for (var i = attrs.length; i--; )
	{
		var attr = attrs[i];
		var name = attr.name;
		var value = attr.value;
		attrList = _List_Cons( A2(_VirtualDom_attribute, name, value), attrList );
	}

	var tag = node.tagName.toLowerCase();
	var kidList = _List_Nil;
	var kids = node.childNodes;

	for (var i = kids.length; i--; )
	{
		kidList = _List_Cons(_VirtualDom_virtualize(kids[i]), kidList);
	}
	return A3(_VirtualDom_node, tag, attrList, kidList);
}

function _VirtualDom_dekey(keyedNode)
{
	var keyedKids = keyedNode.e;
	var len = keyedKids.length;
	var kids = new Array(len);
	for (var i = 0; i < len; i++)
	{
		kids[i] = keyedKids[i].b;
	}

	return {
		$: 1,
		c: keyedNode.c,
		d: keyedNode.d,
		e: kids,
		f: keyedNode.f,
		b: keyedNode.b
	};
}




// ELEMENT


var _Debugger_element;

var _Browser_element = _Debugger_element || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bI,
		impl.cI,
		impl.cH,
		function(sendToApp, initialModel) {
			var view = impl.cJ;
			/**/
			var domNode = args['node'];
			//*/
			/**_UNUSED/
			var domNode = args && args['node'] ? args['node'] : _Debug_crash(0);
			//*/
			var currNode = _VirtualDom_virtualize(domNode);

			return _Browser_makeAnimator(initialModel, function(model)
			{
				var nextNode = view(model);
				var patches = _VirtualDom_diff(currNode, nextNode);
				domNode = _VirtualDom_applyPatches(domNode, currNode, patches, sendToApp);
				currNode = nextNode;
			});
		}
	);
});



// DOCUMENT


var _Debugger_document;

var _Browser_document = _Debugger_document || F4(function(impl, flagDecoder, debugMetadata, args)
{
	return _Platform_initialize(
		flagDecoder,
		args,
		impl.bI,
		impl.cI,
		impl.cH,
		function(sendToApp, initialModel) {
			var divertHrefToApp = impl.bl && impl.bl(sendToApp)
			var view = impl.cJ;
			var title = _VirtualDom_doc.title;
			var bodyNode = _VirtualDom_doc.body;
			var currNode = _VirtualDom_virtualize(bodyNode);
			return _Browser_makeAnimator(initialModel, function(model)
			{
				_VirtualDom_divertHrefToApp = divertHrefToApp;
				var doc = view(model);
				var nextNode = _VirtualDom_node('body')(_List_Nil)(doc.bt);
				var patches = _VirtualDom_diff(currNode, nextNode);
				bodyNode = _VirtualDom_applyPatches(bodyNode, currNode, patches, sendToApp);
				currNode = nextNode;
				_VirtualDom_divertHrefToApp = 0;
				(title !== doc.b8) && (_VirtualDom_doc.title = title = doc.b8);
			});
		}
	);
});



// ANIMATION


var _Browser_cancelAnimationFrame =
	typeof cancelAnimationFrame !== 'undefined'
		? cancelAnimationFrame
		: function(id) { clearTimeout(id); };

var _Browser_requestAnimationFrame =
	typeof requestAnimationFrame !== 'undefined'
		? requestAnimationFrame
		: function(callback) { return setTimeout(callback, 1000 / 60); };


function _Browser_makeAnimator(model, draw)
{
	draw(model);

	var state = 0;

	function updateIfNeeded()
	{
		state = state === 1
			? 0
			: ( _Browser_requestAnimationFrame(updateIfNeeded), draw(model), 1 );
	}

	return function(nextModel, isSync)
	{
		model = nextModel;

		isSync
			? ( draw(model),
				state === 2 && (state = 1)
				)
			: ( state === 0 && _Browser_requestAnimationFrame(updateIfNeeded),
				state = 2
				);
	};
}



// APPLICATION


function _Browser_application(impl)
{
	var onUrlChange = impl.cy;
	var onUrlRequest = impl.cz;
	var key = function() { key.a(onUrlChange(_Browser_getUrl())); };

	return _Browser_document({
		bl: function(sendToApp)
		{
			key.a = sendToApp;
			_Browser_window.addEventListener('popstate', key);
			_Browser_window.navigator.userAgent.indexOf('Trident') < 0 || _Browser_window.addEventListener('hashchange', key);

			return F2(function(domNode, event)
			{
				if (!event.ctrlKey && !event.metaKey && !event.shiftKey && event.button < 1 && !domNode.target && !domNode.hasAttribute('download'))
				{
					event.preventDefault();
					var href = domNode.href;
					var curr = _Browser_getUrl();
					var next = $elm$url$Url$fromString(href).a;
					sendToApp(onUrlRequest(
						(next
							&& curr.bV === next.bV
							&& curr.bH === next.bH
							&& curr.bR.a === next.bR.a
						)
							? $elm$browser$Browser$Internal(next)
							: $elm$browser$Browser$External(href)
					));
				}
			});
		},
		bI: function(flags)
		{
			return A3(impl.bI, flags, _Browser_getUrl(), key);
		},
		cJ: impl.cJ,
		cI: impl.cI,
		cH: impl.cH
	});
}

function _Browser_getUrl()
{
	return $elm$url$Url$fromString(_VirtualDom_doc.location.href).a || _Debug_crash(1);
}

var _Browser_go = F2(function(key, n)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		n && history.go(n);
		key();
	}));
});

var _Browser_pushUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.pushState({}, '', url);
		key();
	}));
});

var _Browser_replaceUrl = F2(function(key, url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function() {
		history.replaceState({}, '', url);
		key();
	}));
});



// GLOBAL EVENTS


var _Browser_fakeNode = { addEventListener: function() {}, removeEventListener: function() {} };
var _Browser_doc = typeof document !== 'undefined' ? document : _Browser_fakeNode;
var _Browser_window = typeof window !== 'undefined' ? window : _Browser_fakeNode;

var _Browser_on = F3(function(node, eventName, sendToSelf)
{
	return _Scheduler_spawn(_Scheduler_binding(function(callback)
	{
		function handler(event)	{ _Scheduler_rawSpawn(sendToSelf(event)); }
		node.addEventListener(eventName, handler, _VirtualDom_passiveSupported && { passive: true });
		return function() { node.removeEventListener(eventName, handler); };
	}));
});

var _Browser_decodeEvent = F2(function(decoder, event)
{
	var result = _Json_runHelp(decoder, event);
	return $elm$core$Result$isOk(result) ? $elm$core$Maybe$Just(result.a) : $elm$core$Maybe$Nothing;
});



// PAGE VISIBILITY


function _Browser_visibilityInfo()
{
	return (typeof _VirtualDom_doc.hidden !== 'undefined')
		? { cs: 'hidden', cj: 'visibilitychange' }
		:
	(typeof _VirtualDom_doc.mozHidden !== 'undefined')
		? { cs: 'mozHidden', cj: 'mozvisibilitychange' }
		:
	(typeof _VirtualDom_doc.msHidden !== 'undefined')
		? { cs: 'msHidden', cj: 'msvisibilitychange' }
		:
	(typeof _VirtualDom_doc.webkitHidden !== 'undefined')
		? { cs: 'webkitHidden', cj: 'webkitvisibilitychange' }
		: { cs: 'hidden', cj: 'visibilitychange' };
}



// ANIMATION FRAMES


function _Browser_rAF()
{
	return _Scheduler_binding(function(callback)
	{
		var id = _Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(Date.now()));
		});

		return function() {
			_Browser_cancelAnimationFrame(id);
		};
	});
}


function _Browser_now()
{
	return _Scheduler_binding(function(callback)
	{
		callback(_Scheduler_succeed(Date.now()));
	});
}



// DOM STUFF


function _Browser_withNode(id, doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			var node = document.getElementById(id);
			callback(node
				? _Scheduler_succeed(doStuff(node))
				: _Scheduler_fail($elm$browser$Browser$Dom$NotFound(id))
			);
		});
	});
}


function _Browser_withWindow(doStuff)
{
	return _Scheduler_binding(function(callback)
	{
		_Browser_requestAnimationFrame(function() {
			callback(_Scheduler_succeed(doStuff()));
		});
	});
}


// FOCUS and BLUR


var _Browser_call = F2(function(functionName, id)
{
	return _Browser_withNode(id, function(node) {
		node[functionName]();
		return _Utils_Tuple0;
	});
});



// WINDOW VIEWPORT


function _Browser_getViewport()
{
	return {
		b2: _Browser_getScene(),
		cb: {
			cd: _Browser_window.pageXOffset,
			ce: _Browser_window.pageYOffset,
			cc: _Browser_doc.documentElement.clientWidth,
			bF: _Browser_doc.documentElement.clientHeight
		}
	};
}

function _Browser_getScene()
{
	var body = _Browser_doc.body;
	var elem = _Browser_doc.documentElement;
	return {
		cc: Math.max(body.scrollWidth, body.offsetWidth, elem.scrollWidth, elem.offsetWidth, elem.clientWidth),
		bF: Math.max(body.scrollHeight, body.offsetHeight, elem.scrollHeight, elem.offsetHeight, elem.clientHeight)
	};
}

var _Browser_setViewport = F2(function(x, y)
{
	return _Browser_withWindow(function()
	{
		_Browser_window.scroll(x, y);
		return _Utils_Tuple0;
	});
});



// ELEMENT VIEWPORT


function _Browser_getViewportOf(id)
{
	return _Browser_withNode(id, function(node)
	{
		return {
			b2: {
				cc: node.scrollWidth,
				bF: node.scrollHeight
			},
			cb: {
				cd: node.scrollLeft,
				ce: node.scrollTop,
				cc: node.clientWidth,
				bF: node.clientHeight
			}
		};
	});
}


var _Browser_setViewportOf = F3(function(id, x, y)
{
	return _Browser_withNode(id, function(node)
	{
		node.scrollLeft = x;
		node.scrollTop = y;
		return _Utils_Tuple0;
	});
});



// ELEMENT


function _Browser_getElement(id)
{
	return _Browser_withNode(id, function(node)
	{
		var rect = node.getBoundingClientRect();
		var x = _Browser_window.pageXOffset;
		var y = _Browser_window.pageYOffset;
		return {
			b2: _Browser_getScene(),
			cb: {
				cd: x,
				ce: y,
				cc: _Browser_doc.documentElement.clientWidth,
				bF: _Browser_doc.documentElement.clientHeight
			},
			co: {
				cd: x + rect.left,
				ce: y + rect.top,
				cc: rect.width,
				bF: rect.height
			}
		};
	});
}



// LOAD and RELOAD


function _Browser_reload(skipCache)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		_VirtualDom_doc.location.reload(skipCache);
	}));
}

function _Browser_load(url)
{
	return A2($elm$core$Task$perform, $elm$core$Basics$never, _Scheduler_binding(function(callback)
	{
		try
		{
			_Browser_window.location = url;
		}
		catch(err)
		{
			// Only Firefox can throw a NS_ERROR_MALFORMED_URI exception here.
			// Other browsers reload the page, so let's be consistent about that.
			_VirtualDom_doc.location.reload(false);
		}
	}));
}



// SEND REQUEST

var _Http_toTask = F3(function(router, toTask, request)
{
	return _Scheduler_binding(function(callback)
	{
		function done(response) {
			callback(toTask(request.i.a(response)));
		}

		var xhr = new XMLHttpRequest();
		xhr.addEventListener('error', function() { done($elm$http$Http$NetworkError_); });
		xhr.addEventListener('timeout', function() { done($elm$http$Http$Timeout_); });
		xhr.addEventListener('load', function() { done(_Http_toResponse(request.i.b, xhr)); });
		$elm$core$Maybe$isJust(request.m) && _Http_track(router, xhr, request.m.a);

		try {
			xhr.open(request.k, request.bp, true);
		} catch (e) {
			return done($elm$http$Http$BadUrl_(request.bp));
		}

		_Http_configureRequest(xhr, request);

		request.bt.a && xhr.setRequestHeader('Content-Type', request.bt.a);
		xhr.send(request.bt.b);

		return function() { xhr.c = true; xhr.abort(); };
	});
});


// CONFIGURE

function _Http_configureRequest(xhr, request)
{
	for (var headers = request.j; headers.b; headers = headers.b) // WHILE_CONS
	{
		xhr.setRequestHeader(headers.a.a, headers.a.b);
	}
	xhr.timeout = request.l.a || 0;
	xhr.responseType = request.i.d;
	xhr.withCredentials = request.ch;
}


// RESPONSES

function _Http_toResponse(toBody, xhr)
{
	return A2(
		200 <= xhr.status && xhr.status < 300 ? $elm$http$Http$GoodStatus_ : $elm$http$Http$BadStatus_,
		_Http_toMetadata(xhr),
		toBody(xhr.response)
	);
}


// METADATA

function _Http_toMetadata(xhr)
{
	return {
		bp: xhr.responseURL,
		cF: xhr.status,
		cG: xhr.statusText,
		j: _Http_parseHeaders(xhr.getAllResponseHeaders())
	};
}


// HEADERS

function _Http_parseHeaders(rawHeaders)
{
	if (!rawHeaders)
	{
		return $elm$core$Dict$empty;
	}

	var headers = $elm$core$Dict$empty;
	var headerPairs = rawHeaders.split('\r\n');
	for (var i = headerPairs.length; i--; )
	{
		var headerPair = headerPairs[i];
		var index = headerPair.indexOf(': ');
		if (index > 0)
		{
			var key = headerPair.substring(0, index);
			var value = headerPair.substring(index + 2);

			headers = A3($elm$core$Dict$update, key, function(oldValue) {
				return $elm$core$Maybe$Just($elm$core$Maybe$isJust(oldValue)
					? value + ', ' + oldValue.a
					: value
				);
			}, headers);
		}
	}
	return headers;
}


// EXPECT

var _Http_expect = F3(function(type, toBody, toValue)
{
	return {
		$: 0,
		d: type,
		b: toBody,
		a: toValue
	};
});

var _Http_mapExpect = F2(function(func, expect)
{
	return {
		$: 0,
		d: expect.d,
		b: expect.b,
		a: function(x) { return func(expect.a(x)); }
	};
});

function _Http_toDataView(arrayBuffer)
{
	return new DataView(arrayBuffer);
}


// BODY and PARTS

var _Http_emptyBody = { $: 0 };
var _Http_pair = F2(function(a, b) { return { $: 0, a: a, b: b }; });

function _Http_toFormData(parts)
{
	for (var formData = new FormData(); parts.b; parts = parts.b) // WHILE_CONS
	{
		var part = parts.a;
		formData.append(part.a, part.b);
	}
	return formData;
}

var _Http_bytesToBlob = F2(function(mime, bytes)
{
	return new Blob([bytes], { type: mime });
});


// PROGRESS

function _Http_track(router, xhr, tracker)
{
	// TODO check out lengthComputable on loadstart event

	xhr.upload.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Sending({
			cE: event.loaded,
			b4: event.total
		}))));
	});
	xhr.addEventListener('progress', function(event) {
		if (xhr.c) { return; }
		_Scheduler_rawSpawn(A2($elm$core$Platform$sendToSelf, router, _Utils_Tuple2(tracker, $elm$http$Http$Receiving({
			cC: event.loaded,
			b4: event.lengthComputable ? $elm$core$Maybe$Just(event.total) : $elm$core$Maybe$Nothing
		}))));
	});
}

function _Url_percentEncode(string)
{
	return encodeURIComponent(string);
}

function _Url_percentDecode(string)
{
	try
	{
		return $elm$core$Maybe$Just(decodeURIComponent(string));
	}
	catch (e)
	{
		return $elm$core$Maybe$Nothing;
	}
}var $author$project$Main$LinkClicked = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$UrlChanged = function (a) {
	return {$: 1, a: a};
};
var $elm$core$List$cons = _List_cons;
var $elm$core$Elm$JsArray$foldr = _JsArray_foldr;
var $elm$core$Array$foldr = F3(
	function (func, baseCase, _v0) {
		var tree = _v0.c;
		var tail = _v0.d;
		var helper = F2(
			function (node, acc) {
				if (!node.$) {
					var subTree = node.a;
					return A3($elm$core$Elm$JsArray$foldr, helper, acc, subTree);
				} else {
					var values = node.a;
					return A3($elm$core$Elm$JsArray$foldr, func, acc, values);
				}
			});
		return A3(
			$elm$core$Elm$JsArray$foldr,
			helper,
			A3($elm$core$Elm$JsArray$foldr, func, baseCase, tail),
			tree);
	});
var $elm$core$Array$toList = function (array) {
	return A3($elm$core$Array$foldr, $elm$core$List$cons, _List_Nil, array);
};
var $elm$core$Dict$foldr = F3(
	function (func, acc, t) {
		foldr:
		while (true) {
			if (t.$ === -2) {
				return acc;
			} else {
				var key = t.b;
				var value = t.c;
				var left = t.d;
				var right = t.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldr, func, acc, right)),
					$temp$t = left;
				func = $temp$func;
				acc = $temp$acc;
				t = $temp$t;
				continue foldr;
			}
		}
	});
var $elm$core$Dict$toList = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, list) {
				return A2(
					$elm$core$List$cons,
					_Utils_Tuple2(key, value),
					list);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Dict$keys = function (dict) {
	return A3(
		$elm$core$Dict$foldr,
		F3(
			function (key, value, keyList) {
				return A2($elm$core$List$cons, key, keyList);
			}),
		_List_Nil,
		dict);
};
var $elm$core$Set$toList = function (_v0) {
	var dict = _v0;
	return $elm$core$Dict$keys(dict);
};
var $elm$core$Basics$EQ = 1;
var $elm$core$Basics$GT = 2;
var $elm$core$Basics$LT = 0;
var $elm$core$Result$Err = function (a) {
	return {$: 1, a: a};
};
var $elm$json$Json$Decode$Failure = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$json$Json$Decode$Field = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$json$Json$Decode$Index = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$core$Result$Ok = function (a) {
	return {$: 0, a: a};
};
var $elm$json$Json$Decode$OneOf = function (a) {
	return {$: 2, a: a};
};
var $elm$core$Basics$False = 1;
var $elm$core$Basics$add = _Basics_add;
var $elm$core$Maybe$Just = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Maybe$Nothing = {$: 1};
var $elm$core$String$all = _String_all;
var $elm$core$Basics$and = _Basics_and;
var $elm$core$Basics$append = _Utils_append;
var $elm$json$Json$Encode$encode = _Json_encode;
var $elm$core$String$fromInt = _String_fromNumber;
var $elm$core$String$join = F2(
	function (sep, chunks) {
		return A2(
			_String_join,
			sep,
			_List_toArray(chunks));
	});
var $elm$core$String$split = F2(
	function (sep, string) {
		return _List_fromArray(
			A2(_String_split, sep, string));
	});
var $elm$json$Json$Decode$indent = function (str) {
	return A2(
		$elm$core$String$join,
		'\n    ',
		A2($elm$core$String$split, '\n', str));
};
var $elm$core$List$foldl = F3(
	function (func, acc, list) {
		foldl:
		while (true) {
			if (!list.b) {
				return acc;
			} else {
				var x = list.a;
				var xs = list.b;
				var $temp$func = func,
					$temp$acc = A2(func, x, acc),
					$temp$list = xs;
				func = $temp$func;
				acc = $temp$acc;
				list = $temp$list;
				continue foldl;
			}
		}
	});
var $elm$core$List$length = function (xs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, i) {
				return i + 1;
			}),
		0,
		xs);
};
var $elm$core$List$map2 = _List_map2;
var $elm$core$Basics$le = _Utils_le;
var $elm$core$Basics$sub = _Basics_sub;
var $elm$core$List$rangeHelp = F3(
	function (lo, hi, list) {
		rangeHelp:
		while (true) {
			if (_Utils_cmp(lo, hi) < 1) {
				var $temp$lo = lo,
					$temp$hi = hi - 1,
					$temp$list = A2($elm$core$List$cons, hi, list);
				lo = $temp$lo;
				hi = $temp$hi;
				list = $temp$list;
				continue rangeHelp;
			} else {
				return list;
			}
		}
	});
var $elm$core$List$range = F2(
	function (lo, hi) {
		return A3($elm$core$List$rangeHelp, lo, hi, _List_Nil);
	});
var $elm$core$List$indexedMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$map2,
			f,
			A2(
				$elm$core$List$range,
				0,
				$elm$core$List$length(xs) - 1),
			xs);
	});
var $elm$core$Char$toCode = _Char_toCode;
var $elm$core$Char$isLower = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (97 <= code) && (code <= 122);
};
var $elm$core$Char$isUpper = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 90) && (65 <= code);
};
var $elm$core$Basics$or = _Basics_or;
var $elm$core$Char$isAlpha = function (_char) {
	return $elm$core$Char$isLower(_char) || $elm$core$Char$isUpper(_char);
};
var $elm$core$Char$isDigit = function (_char) {
	var code = $elm$core$Char$toCode(_char);
	return (code <= 57) && (48 <= code);
};
var $elm$core$Char$isAlphaNum = function (_char) {
	return $elm$core$Char$isLower(_char) || ($elm$core$Char$isUpper(_char) || $elm$core$Char$isDigit(_char));
};
var $elm$core$List$reverse = function (list) {
	return A3($elm$core$List$foldl, $elm$core$List$cons, _List_Nil, list);
};
var $elm$core$String$uncons = _String_uncons;
var $elm$json$Json$Decode$errorOneOf = F2(
	function (i, error) {
		return '\n\n(' + ($elm$core$String$fromInt(i + 1) + (') ' + $elm$json$Json$Decode$indent(
			$elm$json$Json$Decode$errorToString(error))));
	});
var $elm$json$Json$Decode$errorToString = function (error) {
	return A2($elm$json$Json$Decode$errorToStringHelp, error, _List_Nil);
};
var $elm$json$Json$Decode$errorToStringHelp = F2(
	function (error, context) {
		errorToStringHelp:
		while (true) {
			switch (error.$) {
				case 0:
					var f = error.a;
					var err = error.b;
					var isSimple = function () {
						var _v1 = $elm$core$String$uncons(f);
						if (_v1.$ === 1) {
							return false;
						} else {
							var _v2 = _v1.a;
							var _char = _v2.a;
							var rest = _v2.b;
							return $elm$core$Char$isAlpha(_char) && A2($elm$core$String$all, $elm$core$Char$isAlphaNum, rest);
						}
					}();
					var fieldName = isSimple ? ('.' + f) : ('[\'' + (f + '\']'));
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, fieldName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 1:
					var i = error.a;
					var err = error.b;
					var indexName = '[' + ($elm$core$String$fromInt(i) + ']');
					var $temp$error = err,
						$temp$context = A2($elm$core$List$cons, indexName, context);
					error = $temp$error;
					context = $temp$context;
					continue errorToStringHelp;
				case 2:
					var errors = error.a;
					if (!errors.b) {
						return 'Ran into a Json.Decode.oneOf with no possibilities' + function () {
							if (!context.b) {
								return '!';
							} else {
								return ' at json' + A2(
									$elm$core$String$join,
									'',
									$elm$core$List$reverse(context));
							}
						}();
					} else {
						if (!errors.b.b) {
							var err = errors.a;
							var $temp$error = err,
								$temp$context = context;
							error = $temp$error;
							context = $temp$context;
							continue errorToStringHelp;
						} else {
							var starter = function () {
								if (!context.b) {
									return 'Json.Decode.oneOf';
								} else {
									return 'The Json.Decode.oneOf at json' + A2(
										$elm$core$String$join,
										'',
										$elm$core$List$reverse(context));
								}
							}();
							var introduction = starter + (' failed in the following ' + ($elm$core$String$fromInt(
								$elm$core$List$length(errors)) + ' ways:'));
							return A2(
								$elm$core$String$join,
								'\n\n',
								A2(
									$elm$core$List$cons,
									introduction,
									A2($elm$core$List$indexedMap, $elm$json$Json$Decode$errorOneOf, errors)));
						}
					}
				default:
					var msg = error.a;
					var json = error.b;
					var introduction = function () {
						if (!context.b) {
							return 'Problem with the given value:\n\n';
						} else {
							return 'Problem with the value at json' + (A2(
								$elm$core$String$join,
								'',
								$elm$core$List$reverse(context)) + ':\n\n    ');
						}
					}();
					return introduction + ($elm$json$Json$Decode$indent(
						A2($elm$json$Json$Encode$encode, 4, json)) + ('\n\n' + msg));
			}
		}
	});
var $elm$core$Array$branchFactor = 32;
var $elm$core$Array$Array_elm_builtin = F4(
	function (a, b, c, d) {
		return {$: 0, a: a, b: b, c: c, d: d};
	});
var $elm$core$Elm$JsArray$empty = _JsArray_empty;
var $elm$core$Basics$ceiling = _Basics_ceiling;
var $elm$core$Basics$fdiv = _Basics_fdiv;
var $elm$core$Basics$logBase = F2(
	function (base, number) {
		return _Basics_log(number) / _Basics_log(base);
	});
var $elm$core$Basics$toFloat = _Basics_toFloat;
var $elm$core$Array$shiftStep = $elm$core$Basics$ceiling(
	A2($elm$core$Basics$logBase, 2, $elm$core$Array$branchFactor));
var $elm$core$Array$empty = A4($elm$core$Array$Array_elm_builtin, 0, $elm$core$Array$shiftStep, $elm$core$Elm$JsArray$empty, $elm$core$Elm$JsArray$empty);
var $elm$core$Elm$JsArray$initialize = _JsArray_initialize;
var $elm$core$Array$Leaf = function (a) {
	return {$: 1, a: a};
};
var $elm$core$Basics$apL = F2(
	function (f, x) {
		return f(x);
	});
var $elm$core$Basics$apR = F2(
	function (x, f) {
		return f(x);
	});
var $elm$core$Basics$eq = _Utils_equal;
var $elm$core$Basics$floor = _Basics_floor;
var $elm$core$Elm$JsArray$length = _JsArray_length;
var $elm$core$Basics$gt = _Utils_gt;
var $elm$core$Basics$max = F2(
	function (x, y) {
		return (_Utils_cmp(x, y) > 0) ? x : y;
	});
var $elm$core$Basics$mul = _Basics_mul;
var $elm$core$Array$SubTree = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Elm$JsArray$initializeFromList = _JsArray_initializeFromList;
var $elm$core$Array$compressNodes = F2(
	function (nodes, acc) {
		compressNodes:
		while (true) {
			var _v0 = A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodes);
			var node = _v0.a;
			var remainingNodes = _v0.b;
			var newAcc = A2(
				$elm$core$List$cons,
				$elm$core$Array$SubTree(node),
				acc);
			if (!remainingNodes.b) {
				return $elm$core$List$reverse(newAcc);
			} else {
				var $temp$nodes = remainingNodes,
					$temp$acc = newAcc;
				nodes = $temp$nodes;
				acc = $temp$acc;
				continue compressNodes;
			}
		}
	});
var $elm$core$Tuple$first = function (_v0) {
	var x = _v0.a;
	return x;
};
var $elm$core$Array$treeFromBuilder = F2(
	function (nodeList, nodeListSize) {
		treeFromBuilder:
		while (true) {
			var newNodeSize = $elm$core$Basics$ceiling(nodeListSize / $elm$core$Array$branchFactor);
			if (newNodeSize === 1) {
				return A2($elm$core$Elm$JsArray$initializeFromList, $elm$core$Array$branchFactor, nodeList).a;
			} else {
				var $temp$nodeList = A2($elm$core$Array$compressNodes, nodeList, _List_Nil),
					$temp$nodeListSize = newNodeSize;
				nodeList = $temp$nodeList;
				nodeListSize = $temp$nodeListSize;
				continue treeFromBuilder;
			}
		}
	});
var $elm$core$Array$builderToArray = F2(
	function (reverseNodeList, builder) {
		if (!builder.o) {
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.q),
				$elm$core$Array$shiftStep,
				$elm$core$Elm$JsArray$empty,
				builder.q);
		} else {
			var treeLen = builder.o * $elm$core$Array$branchFactor;
			var depth = $elm$core$Basics$floor(
				A2($elm$core$Basics$logBase, $elm$core$Array$branchFactor, treeLen - 1));
			var correctNodeList = reverseNodeList ? $elm$core$List$reverse(builder.r) : builder.r;
			var tree = A2($elm$core$Array$treeFromBuilder, correctNodeList, builder.o);
			return A4(
				$elm$core$Array$Array_elm_builtin,
				$elm$core$Elm$JsArray$length(builder.q) + treeLen,
				A2($elm$core$Basics$max, 5, depth * $elm$core$Array$shiftStep),
				tree,
				builder.q);
		}
	});
var $elm$core$Basics$idiv = _Basics_idiv;
var $elm$core$Basics$lt = _Utils_lt;
var $elm$core$Array$initializeHelp = F5(
	function (fn, fromIndex, len, nodeList, tail) {
		initializeHelp:
		while (true) {
			if (fromIndex < 0) {
				return A2(
					$elm$core$Array$builderToArray,
					false,
					{r: nodeList, o: (len / $elm$core$Array$branchFactor) | 0, q: tail});
			} else {
				var leaf = $elm$core$Array$Leaf(
					A3($elm$core$Elm$JsArray$initialize, $elm$core$Array$branchFactor, fromIndex, fn));
				var $temp$fn = fn,
					$temp$fromIndex = fromIndex - $elm$core$Array$branchFactor,
					$temp$len = len,
					$temp$nodeList = A2($elm$core$List$cons, leaf, nodeList),
					$temp$tail = tail;
				fn = $temp$fn;
				fromIndex = $temp$fromIndex;
				len = $temp$len;
				nodeList = $temp$nodeList;
				tail = $temp$tail;
				continue initializeHelp;
			}
		}
	});
var $elm$core$Basics$remainderBy = _Basics_remainderBy;
var $elm$core$Array$initialize = F2(
	function (len, fn) {
		if (len <= 0) {
			return $elm$core$Array$empty;
		} else {
			var tailLen = len % $elm$core$Array$branchFactor;
			var tail = A3($elm$core$Elm$JsArray$initialize, tailLen, len - tailLen, fn);
			var initialFromIndex = (len - tailLen) - $elm$core$Array$branchFactor;
			return A5($elm$core$Array$initializeHelp, fn, initialFromIndex, len, _List_Nil, tail);
		}
	});
var $elm$core$Basics$True = 0;
var $elm$core$Result$isOk = function (result) {
	if (!result.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$json$Json$Decode$andThen = _Json_andThen;
var $elm$json$Json$Decode$map = _Json_map1;
var $elm$json$Json$Decode$map2 = _Json_map2;
var $elm$json$Json$Decode$succeed = _Json_succeed;
var $elm$virtual_dom$VirtualDom$toHandlerInt = function (handler) {
	switch (handler.$) {
		case 0:
			return 0;
		case 1:
			return 1;
		case 2:
			return 2;
		default:
			return 3;
	}
};
var $elm$browser$Browser$External = function (a) {
	return {$: 1, a: a};
};
var $elm$browser$Browser$Internal = function (a) {
	return {$: 0, a: a};
};
var $elm$core$Basics$identity = function (x) {
	return x;
};
var $elm$browser$Browser$Dom$NotFound = $elm$core$Basics$identity;
var $elm$url$Url$Http = 0;
var $elm$url$Url$Https = 1;
var $elm$url$Url$Url = F6(
	function (protocol, host, port_, path, query, fragment) {
		return {bD: fragment, bH: host, bP: path, bR: port_, bV: protocol, bW: query};
	});
var $elm$core$String$contains = _String_contains;
var $elm$core$String$length = _String_length;
var $elm$core$String$slice = _String_slice;
var $elm$core$String$dropLeft = F2(
	function (n, string) {
		return (n < 1) ? string : A3(
			$elm$core$String$slice,
			n,
			$elm$core$String$length(string),
			string);
	});
var $elm$core$String$indexes = _String_indexes;
var $elm$core$String$isEmpty = function (string) {
	return string === '';
};
var $elm$core$String$left = F2(
	function (n, string) {
		return (n < 1) ? '' : A3($elm$core$String$slice, 0, n, string);
	});
var $elm$core$String$toInt = _String_toInt;
var $elm$url$Url$chompBeforePath = F5(
	function (protocol, path, params, frag, str) {
		if ($elm$core$String$isEmpty(str) || A2($elm$core$String$contains, '@', str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, ':', str);
			if (!_v0.b) {
				return $elm$core$Maybe$Just(
					A6($elm$url$Url$Url, protocol, str, $elm$core$Maybe$Nothing, path, params, frag));
			} else {
				if (!_v0.b.b) {
					var i = _v0.a;
					var _v1 = $elm$core$String$toInt(
						A2($elm$core$String$dropLeft, i + 1, str));
					if (_v1.$ === 1) {
						return $elm$core$Maybe$Nothing;
					} else {
						var port_ = _v1;
						return $elm$core$Maybe$Just(
							A6(
								$elm$url$Url$Url,
								protocol,
								A2($elm$core$String$left, i, str),
								port_,
								path,
								params,
								frag));
					}
				} else {
					return $elm$core$Maybe$Nothing;
				}
			}
		}
	});
var $elm$url$Url$chompBeforeQuery = F4(
	function (protocol, params, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '/', str);
			if (!_v0.b) {
				return A5($elm$url$Url$chompBeforePath, protocol, '/', params, frag, str);
			} else {
				var i = _v0.a;
				return A5(
					$elm$url$Url$chompBeforePath,
					protocol,
					A2($elm$core$String$dropLeft, i, str),
					params,
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompBeforeFragment = F3(
	function (protocol, frag, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '?', str);
			if (!_v0.b) {
				return A4($elm$url$Url$chompBeforeQuery, protocol, $elm$core$Maybe$Nothing, frag, str);
			} else {
				var i = _v0.a;
				return A4(
					$elm$url$Url$chompBeforeQuery,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					frag,
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$url$Url$chompAfterProtocol = F2(
	function (protocol, str) {
		if ($elm$core$String$isEmpty(str)) {
			return $elm$core$Maybe$Nothing;
		} else {
			var _v0 = A2($elm$core$String$indexes, '#', str);
			if (!_v0.b) {
				return A3($elm$url$Url$chompBeforeFragment, protocol, $elm$core$Maybe$Nothing, str);
			} else {
				var i = _v0.a;
				return A3(
					$elm$url$Url$chompBeforeFragment,
					protocol,
					$elm$core$Maybe$Just(
						A2($elm$core$String$dropLeft, i + 1, str)),
					A2($elm$core$String$left, i, str));
			}
		}
	});
var $elm$core$String$startsWith = _String_startsWith;
var $elm$url$Url$fromString = function (str) {
	return A2($elm$core$String$startsWith, 'http://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		0,
		A2($elm$core$String$dropLeft, 7, str)) : (A2($elm$core$String$startsWith, 'https://', str) ? A2(
		$elm$url$Url$chompAfterProtocol,
		1,
		A2($elm$core$String$dropLeft, 8, str)) : $elm$core$Maybe$Nothing);
};
var $elm$core$Basics$never = function (_v0) {
	never:
	while (true) {
		var nvr = _v0;
		var $temp$_v0 = nvr;
		_v0 = $temp$_v0;
		continue never;
	}
};
var $elm$core$Task$Perform = $elm$core$Basics$identity;
var $elm$core$Task$succeed = _Scheduler_succeed;
var $elm$core$Task$init = $elm$core$Task$succeed(0);
var $elm$core$List$foldrHelper = F4(
	function (fn, acc, ctr, ls) {
		if (!ls.b) {
			return acc;
		} else {
			var a = ls.a;
			var r1 = ls.b;
			if (!r1.b) {
				return A2(fn, a, acc);
			} else {
				var b = r1.a;
				var r2 = r1.b;
				if (!r2.b) {
					return A2(
						fn,
						a,
						A2(fn, b, acc));
				} else {
					var c = r2.a;
					var r3 = r2.b;
					if (!r3.b) {
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(fn, c, acc)));
					} else {
						var d = r3.a;
						var r4 = r3.b;
						var res = (ctr > 500) ? A3(
							$elm$core$List$foldl,
							fn,
							acc,
							$elm$core$List$reverse(r4)) : A4($elm$core$List$foldrHelper, fn, acc, ctr + 1, r4);
						return A2(
							fn,
							a,
							A2(
								fn,
								b,
								A2(
									fn,
									c,
									A2(fn, d, res))));
					}
				}
			}
		}
	});
var $elm$core$List$foldr = F3(
	function (fn, acc, ls) {
		return A4($elm$core$List$foldrHelper, fn, acc, 0, ls);
	});
var $elm$core$List$map = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, acc) {
					return A2(
						$elm$core$List$cons,
						f(x),
						acc);
				}),
			_List_Nil,
			xs);
	});
var $elm$core$Task$andThen = _Scheduler_andThen;
var $elm$core$Task$map = F2(
	function (func, taskA) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return $elm$core$Task$succeed(
					func(a));
			},
			taskA);
	});
var $elm$core$Task$map2 = F3(
	function (func, taskA, taskB) {
		return A2(
			$elm$core$Task$andThen,
			function (a) {
				return A2(
					$elm$core$Task$andThen,
					function (b) {
						return $elm$core$Task$succeed(
							A2(func, a, b));
					},
					taskB);
			},
			taskA);
	});
var $elm$core$Task$sequence = function (tasks) {
	return A3(
		$elm$core$List$foldr,
		$elm$core$Task$map2($elm$core$List$cons),
		$elm$core$Task$succeed(_List_Nil),
		tasks);
};
var $elm$core$Platform$sendToApp = _Platform_sendToApp;
var $elm$core$Task$spawnCmd = F2(
	function (router, _v0) {
		var task = _v0;
		return _Scheduler_spawn(
			A2(
				$elm$core$Task$andThen,
				$elm$core$Platform$sendToApp(router),
				task));
	});
var $elm$core$Task$onEffects = F3(
	function (router, commands, state) {
		return A2(
			$elm$core$Task$map,
			function (_v0) {
				return 0;
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$map,
					$elm$core$Task$spawnCmd(router),
					commands)));
	});
var $elm$core$Task$onSelfMsg = F3(
	function (_v0, _v1, _v2) {
		return $elm$core$Task$succeed(0);
	});
var $elm$core$Task$cmdMap = F2(
	function (tagger, _v0) {
		var task = _v0;
		return A2($elm$core$Task$map, tagger, task);
	});
_Platform_effectManagers['Task'] = _Platform_createManager($elm$core$Task$init, $elm$core$Task$onEffects, $elm$core$Task$onSelfMsg, $elm$core$Task$cmdMap);
var $elm$core$Task$command = _Platform_leaf('Task');
var $elm$core$Task$perform = F2(
	function (toMessage, task) {
		return $elm$core$Task$command(
			A2($elm$core$Task$map, toMessage, task));
	});
var $elm$browser$Browser$application = _Browser_application;
var $elm$json$Json$Decode$field = _Json_decodeField;
var $elm$json$Json$Encode$null = _Json_encodeNull;
var $author$project$Main$checkLocal = _Platform_outgoingPort(
	'checkLocal',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $elm$core$Dict$RBEmpty_elm_builtin = {$: -2};
var $elm$core$Dict$empty = $elm$core$Dict$RBEmpty_elm_builtin;
var $elm$core$Basics$negate = function (n) {
	return -n;
};
var $author$project$Contacten$empty = {R: '', aD: '', g: $elm$core$Dict$empty, bf: '', G: $elm$core$Dict$empty, aE: '', c: false, ai: '', ct: -1, x: $elm$core$Dict$empty, Z: -1, at: '', aM: '', bd: false, ac: 'Prospect', ax: '', aP: '', aQ: '', aS: ''};
var $author$project$Contacten$emptyAquarium = {n: -1, cl: -1, aF: '', ah: '', c: false, aI: '', am: '', aJ: '', ao: '', _: -1, aL: '', au: '', bd: true, aw: ''};
var $author$project$Contacten$emptyComment = {ck: -1, cl: -1, c: false, cB: '', ad: ''};
var $author$project$Contacten$emptyLead = {cl: -1, c: false, K: '', ap: '', M: -1, ad: ''};
var $author$project$User$init = function (endpoint) {
	return {aj: endpoint, aW: '', aK: '', aZ: '', ay: '', ca: 0, aA: ''};
};
var $author$project$Main$initAction = function (user) {
	return (user.aZ === 'admin') ? {cl: 0, t: '', w: 'dagLeads'} : {cl: 0, t: '', w: 'agendaPlanning'};
};
var $emilianobovetti$elm_toast$Toast$Private = $elm$core$Basics$identity;
var $emilianobovetti$elm_toast$Toast$tray = {a4: 0, P: _List_Nil};
var $author$project$Main$init = F3(
	function (flags, url, key) {
		return _Utils_Tuple2(
			{
				e: $author$project$Main$initAction(
					$author$project$User$init('')),
				af: _List_Nil,
				U: $elm$core$Dict$empty,
				aG: $elm$core$Dict$empty,
				a6: {S: false, W: true, ab: true},
				V: $elm$core$Dict$empty,
				D: flags,
				a7: key,
				a9: $elm$core$Dict$empty,
				ba: $elm$core$Dict$empty,
				X: $author$project$Contacten$emptyAquarium,
				aq: $author$project$Contacten$emptyComment,
				Y: $author$project$Contacten$empty,
				ar: $author$project$Contacten$emptyLead,
				aO: {S: 0, W: 0, ab: 0},
				az: $emilianobovetti$elm_toast$Toast$tray,
				bp: url,
				a: $author$project$User$init(flags.aj),
				aC: ''
			},
			$author$project$Main$checkLocal(0));
	});
var $elm$json$Json$Decode$string = _Json_decodeString;
var $author$project$Main$Load = function (a) {
	return {$: 29, a: a};
};
var $author$project$Main$LocalExists = function (a) {
	return {$: 30, a: a};
};
var $elm$core$Platform$Sub$batch = _Platform_batch;
var $elm$json$Json$Decode$bool = _Json_decodeBool;
var $author$project$Main$hasLocal = _Platform_incomingPort('hasLocal', $elm$json$Json$Decode$bool);
var $elm$json$Json$Decode$value = _Json_decodeValue;
var $author$project$Main$sendLocalStore = _Platform_incomingPort('sendLocalStore', $elm$json$Json$Decode$value);
var $author$project$Main$subscriptions = function (_v0) {
	return $elm$core$Platform$Sub$batch(
		_List_fromArray(
			[
				$author$project$Main$hasLocal($author$project$Main$LocalExists),
				$author$project$Main$sendLocalStore($author$project$Main$Load)
			]));
};
var $author$project$Main$AddToast = F2(
	function (a, b) {
		return {$: 34, a: a, b: b};
	});
var $author$project$Main$AgendaMsg = function (a) {
	return {$: 31, a: a};
};
var $author$project$Main$ContactenMsg = function (a) {
	return {$: 4, a: a};
};
var $author$project$Main$GotComments = function (a) {
	return {$: 5, a: a};
};
var $author$project$Main$GotContacten = function (a) {
	return {$: 2, a: a};
};
var $author$project$Main$GotDagLeads = function (a) {
	return {$: 7, a: a};
};
var $author$project$Agenda$GotEvents = function (a) {
	return {$: 0, a: a};
};
var $author$project$Main$GotLeads = function (a) {
	return {$: 6, a: a};
};
var $author$project$Main$GotPlanningAgenda = function (a) {
	return {$: 32, a: a};
};
var $author$project$Main$GotResponse = function (a) {
	return {$: 20, a: a};
};
var $author$project$Main$GotStats = function (a) {
	return {$: 21, a: a};
};
var $author$project$Main$Green = 1;
var $author$project$User$Load = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $author$project$Main$Red = 0;
var $author$project$Main$ToastMsg = function (a) {
	return {$: 33, a: a};
};
var $author$project$Main$UserMsg = function (a) {
	return {$: 27, a: a};
};
var $author$project$Main$Zoek = function (a) {
	return {$: 22, a: a};
};
var $emilianobovetti$elm_toast$Toast$In = 1;
var $emilianobovetti$elm_toast$Toast$Transition = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$core$Basics$always = F2(
	function (a, _v0) {
		return a;
	});
var $elm$core$Process$sleep = _Process_sleep;
var $emilianobovetti$elm_toast$Toast$delay = F2(
	function (ms, msg) {
		return A2(
			$elm$core$Task$perform,
			$elm$core$Basics$always(msg),
			$elm$core$Process$sleep(ms));
	});
var $emilianobovetti$elm_toast$Toast$Exit = 2;
var $emilianobovetti$elm_toast$Toast$PrepareExit = function (a) {
	return {$: 2, a: a};
};
var $emilianobovetti$elm_toast$Toast$onEnter = F2(
	function (id, lifespan) {
		switch (lifespan.$) {
			case 0:
				return _List_Nil;
			case 1:
				var ttl = lifespan.a;
				return _List_fromArray(
					[
						A2(
						$emilianobovetti$elm_toast$Toast$delay,
						ttl,
						A2($emilianobovetti$elm_toast$Toast$Transition, 2, id))
					]);
			default:
				var ttl = lifespan.a;
				return _List_fromArray(
					[
						A2(
						$emilianobovetti$elm_toast$Toast$delay,
						ttl,
						$emilianobovetti$elm_toast$Toast$PrepareExit(id))
					]);
		}
	});
var $elm$core$Platform$Cmd$batch = _Platform_batch;
var $emilianobovetti$elm_toast$Toast$withCmds = F2(
	function (cmds, model) {
		return _Utils_Tuple2(
			model,
			$elm$core$Platform$Cmd$batch(cmds));
	});
var $emilianobovetti$elm_toast$Toast$internalAdd = F2(
	function (model, toast) {
		var id = model.a4;
		var toasts = _Utils_ap(
			model.P,
			_List_fromArray(
				[
					_Utils_update(
					toast,
					{ct: id})
				]));
		return A2(
			$emilianobovetti$elm_toast$Toast$withCmds,
			A2(
				$elm$core$List$cons,
				A2(
					$emilianobovetti$elm_toast$Toast$delay,
					100,
					A2($emilianobovetti$elm_toast$Toast$Transition, 1, id)),
				A2($emilianobovetti$elm_toast$Toast$onEnter, id, toast.a8)),
			_Utils_update(
				model,
				{a4: id + 1, P: toasts}));
	});
var $emilianobovetti$elm_toast$Toast$add = F2(
	function (_v0, _v1) {
		var model = _v0;
		var toast = _v1;
		return A2($emilianobovetti$elm_toast$Toast$internalAdd, model, toast);
	});
var $elm$http$Http$BadStatus_ = F2(
	function (a, b) {
		return {$: 3, a: a, b: b};
	});
var $elm$http$Http$BadUrl_ = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$GoodStatus_ = F2(
	function (a, b) {
		return {$: 4, a: a, b: b};
	});
var $elm$http$Http$NetworkError_ = {$: 2};
var $elm$http$Http$Receiving = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$Sending = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$Timeout_ = {$: 1};
var $elm$core$Maybe$isJust = function (maybe) {
	if (!maybe.$) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$Platform$sendToSelf = _Platform_sendToSelf;
var $elm$core$Basics$compare = _Utils_compare;
var $elm$core$Dict$get = F2(
	function (targetKey, dict) {
		get:
		while (true) {
			if (dict.$ === -2) {
				return $elm$core$Maybe$Nothing;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var _v1 = A2($elm$core$Basics$compare, targetKey, key);
				switch (_v1) {
					case 0:
						var $temp$targetKey = targetKey,
							$temp$dict = left;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
					case 1:
						return $elm$core$Maybe$Just(value);
					default:
						var $temp$targetKey = targetKey,
							$temp$dict = right;
						targetKey = $temp$targetKey;
						dict = $temp$dict;
						continue get;
				}
			}
		}
	});
var $elm$core$Dict$Black = 1;
var $elm$core$Dict$RBNode_elm_builtin = F5(
	function (a, b, c, d, e) {
		return {$: -1, a: a, b: b, c: c, d: d, e: e};
	});
var $elm$core$Dict$Red = 0;
var $elm$core$Dict$balance = F5(
	function (color, key, value, left, right) {
		if ((right.$ === -1) && (!right.a)) {
			var _v1 = right.a;
			var rK = right.b;
			var rV = right.c;
			var rLeft = right.d;
			var rRight = right.e;
			if ((left.$ === -1) && (!left.a)) {
				var _v3 = left.a;
				var lK = left.b;
				var lV = left.c;
				var lLeft = left.d;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					key,
					value,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					rK,
					rV,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, left, rLeft),
					rRight);
			}
		} else {
			if ((((left.$ === -1) && (!left.a)) && (left.d.$ === -1)) && (!left.d.a)) {
				var _v5 = left.a;
				var lK = left.b;
				var lV = left.c;
				var _v6 = left.d;
				var _v7 = _v6.a;
				var llK = _v6.b;
				var llV = _v6.c;
				var llLeft = _v6.d;
				var llRight = _v6.e;
				var lRight = left.e;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					0,
					lK,
					lV,
					A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 1, key, value, lRight, right));
			} else {
				return A5($elm$core$Dict$RBNode_elm_builtin, color, key, value, left, right);
			}
		}
	});
var $elm$core$Dict$insertHelp = F3(
	function (key, value, dict) {
		if (dict.$ === -2) {
			return A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, $elm$core$Dict$RBEmpty_elm_builtin, $elm$core$Dict$RBEmpty_elm_builtin);
		} else {
			var nColor = dict.a;
			var nKey = dict.b;
			var nValue = dict.c;
			var nLeft = dict.d;
			var nRight = dict.e;
			var _v1 = A2($elm$core$Basics$compare, key, nKey);
			switch (_v1) {
				case 0:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						A3($elm$core$Dict$insertHelp, key, value, nLeft),
						nRight);
				case 1:
					return A5($elm$core$Dict$RBNode_elm_builtin, nColor, nKey, value, nLeft, nRight);
				default:
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						nLeft,
						A3($elm$core$Dict$insertHelp, key, value, nRight));
			}
		}
	});
var $elm$core$Dict$insert = F3(
	function (key, value, dict) {
		var _v0 = A3($elm$core$Dict$insertHelp, key, value, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$getMin = function (dict) {
	getMin:
	while (true) {
		if ((dict.$ === -1) && (dict.d.$ === -1)) {
			var left = dict.d;
			var $temp$dict = left;
			dict = $temp$dict;
			continue getMin;
		} else {
			return dict;
		}
	}
};
var $elm$core$Dict$moveRedLeft = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.e.d.$ === -1) && (!dict.e.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var lLeft = _v1.d;
			var lRight = _v1.e;
			var _v2 = dict.e;
			var rClr = _v2.a;
			var rK = _v2.b;
			var rV = _v2.c;
			var rLeft = _v2.d;
			var _v3 = rLeft.a;
			var rlK = rLeft.b;
			var rlV = rLeft.c;
			var rlL = rLeft.d;
			var rlR = rLeft.e;
			var rRight = _v2.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				rlK,
				rlV,
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					rlL),
				A5($elm$core$Dict$RBNode_elm_builtin, 1, rK, rV, rlR, rRight));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v4 = dict.d;
			var lClr = _v4.a;
			var lK = _v4.b;
			var lV = _v4.c;
			var lLeft = _v4.d;
			var lRight = _v4.e;
			var _v5 = dict.e;
			var rClr = _v5.a;
			var rK = _v5.b;
			var rV = _v5.c;
			var rLeft = _v5.d;
			var rRight = _v5.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$moveRedRight = function (dict) {
	if (((dict.$ === -1) && (dict.d.$ === -1)) && (dict.e.$ === -1)) {
		if ((dict.d.d.$ === -1) && (!dict.d.d.a)) {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v1 = dict.d;
			var lClr = _v1.a;
			var lK = _v1.b;
			var lV = _v1.c;
			var _v2 = _v1.d;
			var _v3 = _v2.a;
			var llK = _v2.b;
			var llV = _v2.c;
			var llLeft = _v2.d;
			var llRight = _v2.e;
			var lRight = _v1.e;
			var _v4 = dict.e;
			var rClr = _v4.a;
			var rK = _v4.b;
			var rV = _v4.c;
			var rLeft = _v4.d;
			var rRight = _v4.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				0,
				lK,
				lV,
				A5($elm$core$Dict$RBNode_elm_builtin, 1, llK, llV, llLeft, llRight),
				A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					lRight,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight)));
		} else {
			var clr = dict.a;
			var k = dict.b;
			var v = dict.c;
			var _v5 = dict.d;
			var lClr = _v5.a;
			var lK = _v5.b;
			var lV = _v5.c;
			var lLeft = _v5.d;
			var lRight = _v5.e;
			var _v6 = dict.e;
			var rClr = _v6.a;
			var rK = _v6.b;
			var rV = _v6.c;
			var rLeft = _v6.d;
			var rRight = _v6.e;
			if (clr === 1) {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			} else {
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					1,
					k,
					v,
					A5($elm$core$Dict$RBNode_elm_builtin, 0, lK, lV, lLeft, lRight),
					A5($elm$core$Dict$RBNode_elm_builtin, 0, rK, rV, rLeft, rRight));
			}
		}
	} else {
		return dict;
	}
};
var $elm$core$Dict$removeHelpPrepEQGT = F7(
	function (targetKey, dict, color, key, value, left, right) {
		if ((left.$ === -1) && (!left.a)) {
			var _v1 = left.a;
			var lK = left.b;
			var lV = left.c;
			var lLeft = left.d;
			var lRight = left.e;
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				lK,
				lV,
				lLeft,
				A5($elm$core$Dict$RBNode_elm_builtin, 0, key, value, lRight, right));
		} else {
			_v2$2:
			while (true) {
				if ((right.$ === -1) && (right.a === 1)) {
					if (right.d.$ === -1) {
						if (right.d.a === 1) {
							var _v3 = right.a;
							var _v4 = right.d;
							var _v5 = _v4.a;
							return $elm$core$Dict$moveRedRight(dict);
						} else {
							break _v2$2;
						}
					} else {
						var _v6 = right.a;
						var _v7 = right.d;
						return $elm$core$Dict$moveRedRight(dict);
					}
				} else {
					break _v2$2;
				}
			}
			return dict;
		}
	});
var $elm$core$Dict$removeMin = function (dict) {
	if ((dict.$ === -1) && (dict.d.$ === -1)) {
		var color = dict.a;
		var key = dict.b;
		var value = dict.c;
		var left = dict.d;
		var lColor = left.a;
		var lLeft = left.d;
		var right = dict.e;
		if (lColor === 1) {
			if ((lLeft.$ === -1) && (!lLeft.a)) {
				var _v3 = lLeft.a;
				return A5(
					$elm$core$Dict$RBNode_elm_builtin,
					color,
					key,
					value,
					$elm$core$Dict$removeMin(left),
					right);
			} else {
				var _v4 = $elm$core$Dict$moveRedLeft(dict);
				if (_v4.$ === -1) {
					var nColor = _v4.a;
					var nKey = _v4.b;
					var nValue = _v4.c;
					var nLeft = _v4.d;
					var nRight = _v4.e;
					return A5(
						$elm$core$Dict$balance,
						nColor,
						nKey,
						nValue,
						$elm$core$Dict$removeMin(nLeft),
						nRight);
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			}
		} else {
			return A5(
				$elm$core$Dict$RBNode_elm_builtin,
				color,
				key,
				value,
				$elm$core$Dict$removeMin(left),
				right);
		}
	} else {
		return $elm$core$Dict$RBEmpty_elm_builtin;
	}
};
var $elm$core$Dict$removeHelp = F2(
	function (targetKey, dict) {
		if (dict.$ === -2) {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		} else {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_cmp(targetKey, key) < 0) {
				if ((left.$ === -1) && (left.a === 1)) {
					var _v4 = left.a;
					var lLeft = left.d;
					if ((lLeft.$ === -1) && (!lLeft.a)) {
						var _v6 = lLeft.a;
						return A5(
							$elm$core$Dict$RBNode_elm_builtin,
							color,
							key,
							value,
							A2($elm$core$Dict$removeHelp, targetKey, left),
							right);
					} else {
						var _v7 = $elm$core$Dict$moveRedLeft(dict);
						if (_v7.$ === -1) {
							var nColor = _v7.a;
							var nKey = _v7.b;
							var nValue = _v7.c;
							var nLeft = _v7.d;
							var nRight = _v7.e;
							return A5(
								$elm$core$Dict$balance,
								nColor,
								nKey,
								nValue,
								A2($elm$core$Dict$removeHelp, targetKey, nLeft),
								nRight);
						} else {
							return $elm$core$Dict$RBEmpty_elm_builtin;
						}
					}
				} else {
					return A5(
						$elm$core$Dict$RBNode_elm_builtin,
						color,
						key,
						value,
						A2($elm$core$Dict$removeHelp, targetKey, left),
						right);
				}
			} else {
				return A2(
					$elm$core$Dict$removeHelpEQGT,
					targetKey,
					A7($elm$core$Dict$removeHelpPrepEQGT, targetKey, dict, color, key, value, left, right));
			}
		}
	});
var $elm$core$Dict$removeHelpEQGT = F2(
	function (targetKey, dict) {
		if (dict.$ === -1) {
			var color = dict.a;
			var key = dict.b;
			var value = dict.c;
			var left = dict.d;
			var right = dict.e;
			if (_Utils_eq(targetKey, key)) {
				var _v1 = $elm$core$Dict$getMin(right);
				if (_v1.$ === -1) {
					var minKey = _v1.b;
					var minValue = _v1.c;
					return A5(
						$elm$core$Dict$balance,
						color,
						minKey,
						minValue,
						left,
						$elm$core$Dict$removeMin(right));
				} else {
					return $elm$core$Dict$RBEmpty_elm_builtin;
				}
			} else {
				return A5(
					$elm$core$Dict$balance,
					color,
					key,
					value,
					left,
					A2($elm$core$Dict$removeHelp, targetKey, right));
			}
		} else {
			return $elm$core$Dict$RBEmpty_elm_builtin;
		}
	});
var $elm$core$Dict$remove = F2(
	function (key, dict) {
		var _v0 = A2($elm$core$Dict$removeHelp, key, dict);
		if ((_v0.$ === -1) && (!_v0.a)) {
			var _v1 = _v0.a;
			var k = _v0.b;
			var v = _v0.c;
			var l = _v0.d;
			var r = _v0.e;
			return A5($elm$core$Dict$RBNode_elm_builtin, 1, k, v, l, r);
		} else {
			var x = _v0;
			return x;
		}
	});
var $elm$core$Dict$update = F3(
	function (targetKey, alter, dictionary) {
		var _v0 = alter(
			A2($elm$core$Dict$get, targetKey, dictionary));
		if (!_v0.$) {
			var value = _v0.a;
			return A3($elm$core$Dict$insert, targetKey, value, dictionary);
		} else {
			return A2($elm$core$Dict$remove, targetKey, dictionary);
		}
	});
var $elm$core$Basics$composeR = F3(
	function (f, g, x) {
		return g(
			f(x));
	});
var $elm$http$Http$expectStringResponse = F2(
	function (toMsg, toResult) {
		return A3(
			_Http_expect,
			'',
			$elm$core$Basics$identity,
			A2($elm$core$Basics$composeR, toResult, toMsg));
	});
var $elm$http$Http$BadBody = function (a) {
	return {$: 4, a: a};
};
var $elm$http$Http$BadStatus = function (a) {
	return {$: 3, a: a};
};
var $elm$http$Http$BadUrl = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$NetworkError = {$: 2};
var $elm$http$Http$Timeout = {$: 1};
var $elm$core$Result$mapError = F2(
	function (f, result) {
		if (!result.$) {
			var v = result.a;
			return $elm$core$Result$Ok(v);
		} else {
			var e = result.a;
			return $elm$core$Result$Err(
				f(e));
		}
	});
var $elm$http$Http$resolve = F2(
	function (toResult, response) {
		switch (response.$) {
			case 0:
				var url = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadUrl(url));
			case 1:
				return $elm$core$Result$Err($elm$http$Http$Timeout);
			case 2:
				return $elm$core$Result$Err($elm$http$Http$NetworkError);
			case 3:
				var metadata = response.a;
				return $elm$core$Result$Err(
					$elm$http$Http$BadStatus(metadata.cF));
			default:
				var body = response.b;
				return A2(
					$elm$core$Result$mapError,
					$elm$http$Http$BadBody,
					toResult(body));
		}
	});
var $elm$http$Http$expectString = function (toMsg) {
	return A2(
		$elm$http$Http$expectStringResponse,
		toMsg,
		$elm$http$Http$resolve($elm$core$Result$Ok));
};
var $elm$http$Http$Header = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$header = $elm$http$Http$Header;
var $elm$http$Http$jsonBody = function (value) {
	return A2(
		_Http_pair,
		'application/json',
		A2($elm$json$Json$Encode$encode, 0, value));
};
var $elm$http$Http$Request = function (a) {
	return {$: 1, a: a};
};
var $elm$http$Http$State = F2(
	function (reqs, subs) {
		return {bY: reqs, b6: subs};
	});
var $elm$http$Http$init = $elm$core$Task$succeed(
	A2($elm$http$Http$State, $elm$core$Dict$empty, _List_Nil));
var $elm$core$Process$kill = _Scheduler_kill;
var $elm$core$Process$spawn = _Scheduler_spawn;
var $elm$http$Http$updateReqs = F3(
	function (router, cmds, reqs) {
		updateReqs:
		while (true) {
			if (!cmds.b) {
				return $elm$core$Task$succeed(reqs);
			} else {
				var cmd = cmds.a;
				var otherCmds = cmds.b;
				if (!cmd.$) {
					var tracker = cmd.a;
					var _v2 = A2($elm$core$Dict$get, tracker, reqs);
					if (_v2.$ === 1) {
						var $temp$router = router,
							$temp$cmds = otherCmds,
							$temp$reqs = reqs;
						router = $temp$router;
						cmds = $temp$cmds;
						reqs = $temp$reqs;
						continue updateReqs;
					} else {
						var pid = _v2.a;
						return A2(
							$elm$core$Task$andThen,
							function (_v3) {
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A2($elm$core$Dict$remove, tracker, reqs));
							},
							$elm$core$Process$kill(pid));
					}
				} else {
					var req = cmd.a;
					return A2(
						$elm$core$Task$andThen,
						function (pid) {
							var _v4 = req.m;
							if (_v4.$ === 1) {
								return A3($elm$http$Http$updateReqs, router, otherCmds, reqs);
							} else {
								var tracker = _v4.a;
								return A3(
									$elm$http$Http$updateReqs,
									router,
									otherCmds,
									A3($elm$core$Dict$insert, tracker, pid, reqs));
							}
						},
						$elm$core$Process$spawn(
							A3(
								_Http_toTask,
								router,
								$elm$core$Platform$sendToApp(router),
								req)));
				}
			}
		}
	});
var $elm$http$Http$onEffects = F4(
	function (router, cmds, subs, state) {
		return A2(
			$elm$core$Task$andThen,
			function (reqs) {
				return $elm$core$Task$succeed(
					A2($elm$http$Http$State, reqs, subs));
			},
			A3($elm$http$Http$updateReqs, router, cmds, state.bY));
	});
var $elm$core$List$maybeCons = F3(
	function (f, mx, xs) {
		var _v0 = f(mx);
		if (!_v0.$) {
			var x = _v0.a;
			return A2($elm$core$List$cons, x, xs);
		} else {
			return xs;
		}
	});
var $elm$core$List$filterMap = F2(
	function (f, xs) {
		return A3(
			$elm$core$List$foldr,
			$elm$core$List$maybeCons(f),
			_List_Nil,
			xs);
	});
var $elm$http$Http$maybeSend = F4(
	function (router, desiredTracker, progress, _v0) {
		var actualTracker = _v0.a;
		var toMsg = _v0.b;
		return _Utils_eq(desiredTracker, actualTracker) ? $elm$core$Maybe$Just(
			A2(
				$elm$core$Platform$sendToApp,
				router,
				toMsg(progress))) : $elm$core$Maybe$Nothing;
	});
var $elm$http$Http$onSelfMsg = F3(
	function (router, _v0, state) {
		var tracker = _v0.a;
		var progress = _v0.b;
		return A2(
			$elm$core$Task$andThen,
			function (_v1) {
				return $elm$core$Task$succeed(state);
			},
			$elm$core$Task$sequence(
				A2(
					$elm$core$List$filterMap,
					A3($elm$http$Http$maybeSend, router, tracker, progress),
					state.b6)));
	});
var $elm$http$Http$Cancel = function (a) {
	return {$: 0, a: a};
};
var $elm$http$Http$cmdMap = F2(
	function (func, cmd) {
		if (!cmd.$) {
			var tracker = cmd.a;
			return $elm$http$Http$Cancel(tracker);
		} else {
			var r = cmd.a;
			return $elm$http$Http$Request(
				{
					ch: r.ch,
					bt: r.bt,
					i: A2(_Http_mapExpect, func, r.i),
					j: r.j,
					k: r.k,
					l: r.l,
					m: r.m,
					bp: r.bp
				});
		}
	});
var $elm$http$Http$MySub = F2(
	function (a, b) {
		return {$: 0, a: a, b: b};
	});
var $elm$http$Http$subMap = F2(
	function (func, _v0) {
		var tracker = _v0.a;
		var toMsg = _v0.b;
		return A2(
			$elm$http$Http$MySub,
			tracker,
			A2($elm$core$Basics$composeR, toMsg, func));
	});
_Platform_effectManagers['Http'] = _Platform_createManager($elm$http$Http$init, $elm$http$Http$onEffects, $elm$http$Http$onSelfMsg, $elm$http$Http$cmdMap, $elm$http$Http$subMap);
var $elm$http$Http$command = _Platform_leaf('Http');
var $elm$http$Http$subscription = _Platform_leaf('Http');
var $elm$http$Http$request = function (r) {
	return $elm$http$Http$command(
		$elm$http$Http$Request(
			{ch: false, bt: r.bt, i: r.i, j: r.j, k: r.k, l: r.l, m: r.m, bp: r.bp}));
};
var $author$project$Requests$afmeldLeads = F5(
	function (msg, json, c_id, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'PUT',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/leads/afmelden/' + $elm$core$String$fromInt(c_id))
			});
	});
var $elm$json$Json$Encode$bool = _Json_wrap;
var $author$project$JWT$Model = function (token) {
	return {ay: token};
};
var $author$project$JWT$decode = A2(
	$elm$json$Json$Decode$map,
	$author$project$JWT$Model,
	A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string));
var $author$project$Agenda$Event = F4(
	function (id, name, datum, bedrijfsnaam) {
		return {bf: bedrijfsnaam, bx: datum, ct: id, cx: name};
	});
var $elm$json$Json$Decode$list = _Json_decodeList;
var $elm$json$Json$Decode$map4 = _Json_map4;
var $author$project$Agenda$decodeAgenda = $elm$json$Json$Decode$list(
	A5(
		$elm$json$Json$Decode$map4,
		$author$project$Agenda$Event,
		A2($elm$json$Json$Decode$field, 'id', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'name', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'datum', $elm$json$Json$Decode$string),
		A2($elm$json$Json$Decode$field, 'bedrijfsnaam', $elm$json$Json$Decode$string)));
var $author$project$Contacten$DagLeads = F5(
	function (contact_id, follow_up, lead, accountmng, bedrijfsnaam) {
		return {R: accountmng, bf: bedrijfsnaam, cl: contact_id, K: follow_up, ap: lead};
	});
var $elm$json$Json$Decode$decodeString = _Json_runOnString;
var $elm$json$Json$Decode$fail = _Json_fail;
var $elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples = F2(
	function (keyDecoder, tuples) {
		if (!tuples.b) {
			return $elm$json$Json$Decode$succeed($elm$core$Dict$empty);
		} else {
			var _v1 = tuples.a;
			var strKey = _v1.a;
			var value = _v1.b;
			var rest = tuples.b;
			var _v2 = A2($elm$json$Json$Decode$decodeString, keyDecoder, strKey);
			if (!_v2.$) {
				var key = _v2.a;
				return A2(
					$elm$json$Json$Decode$andThen,
					A2(
						$elm$core$Basics$composeR,
						A2($elm$core$Dict$insert, key, value),
						$elm$json$Json$Decode$succeed),
					A2($elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples, keyDecoder, rest));
			} else {
				var error = _v2.a;
				return $elm$json$Json$Decode$fail(
					$elm$json$Json$Decode$errorToString(error));
			}
		}
	});
var $elm$json$Json$Decode$keyValuePairs = _Json_decodeKeyValuePairs;
var $elm_community$json_extra$Json$Decode$Extra$dict2 = F2(
	function (keyDecoder, valueDecoder) {
		return A2(
			$elm$json$Json$Decode$andThen,
			$elm_community$json_extra$Json$Decode$Extra$decodeDictFromTuples(keyDecoder),
			$elm$json$Json$Decode$keyValuePairs(valueDecoder));
	});
var $elm$json$Json$Decode$int = _Json_decodeInt;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom = $elm$json$Json$Decode$map2($elm$core$Basics$apR);
var $elm$json$Json$Decode$decodeValue = _Json_run;
var $elm$json$Json$Decode$null = _Json_decodeNull;
var $elm$json$Json$Decode$oneOf = _Json_oneOf;
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder = F3(
	function (pathDecoder, valDecoder, fallback) {
		var nullOr = function (decoder) {
			return $elm$json$Json$Decode$oneOf(
				_List_fromArray(
					[
						decoder,
						$elm$json$Json$Decode$null(fallback)
					]));
		};
		var handleResult = function (input) {
			var _v0 = A2($elm$json$Json$Decode$decodeValue, pathDecoder, input);
			if (!_v0.$) {
				var rawValue = _v0.a;
				var _v1 = A2(
					$elm$json$Json$Decode$decodeValue,
					nullOr(valDecoder),
					rawValue);
				if (!_v1.$) {
					var finalResult = _v1.a;
					return $elm$json$Json$Decode$succeed(finalResult);
				} else {
					var finalErr = _v1.a;
					return $elm$json$Json$Decode$fail(
						$elm$json$Json$Decode$errorToString(finalErr));
				}
			} else {
				return $elm$json$Json$Decode$succeed(fallback);
			}
		};
		return A2($elm$json$Json$Decode$andThen, handleResult, $elm$json$Json$Decode$value);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional = F4(
	function (key, valDecoder, fallback, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optionalDecoder,
				A2($elm$json$Json$Decode$field, key, $elm$json$Json$Decode$value),
				valDecoder,
				fallback),
			decoder);
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required = F3(
	function (key, valDecoder, decoder) {
		return A2(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom,
			A2($elm$json$Json$Decode$field, key, valDecoder),
			decoder);
	});
var $author$project$Contacten$decodeDagLeads = A2(
	$elm_community$json_extra$Json$Decode$Extra$dict2,
	$elm$json$Json$Decode$int,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'bedrijfsnaam',
		$elm$json$Json$Decode$string,
		'',
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'accountmng',
			$elm$json$Json$Decode$string,
			'',
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'lead',
				$elm$json$Json$Decode$string,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'follow_up',
					$elm$json$Json$Decode$string,
					'',
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'contact_id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Contacten$DagLeads)))))));
var $author$project$Contacten$Comment = F5(
	function (comments_id, contact_id, opmerkingen, tx_timestamp, editing) {
		return {ck: comments_id, cl: contact_id, c: editing, cB: opmerkingen, ad: tx_timestamp};
	});
var $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded = A2($elm$core$Basics$composeR, $elm$json$Json$Decode$succeed, $NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$custom);
var $author$project$Contacten$decodeComment = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	false,
	A3(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
		'tx_timestamp',
		$elm$json$Json$Decode$string,
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'opmerkingen',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'contact_id',
				$elm$json$Json$Decode$int,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'comments_id',
					$elm$json$Json$Decode$int,
					$elm$json$Json$Decode$succeed($author$project$Contacten$Comment))))));
var $author$project$Contacten$decodeDictComment = A2($elm_community$json_extra$Json$Decode$Extra$dict2, $elm$json$Json$Decode$int, $author$project$Contacten$decodeComment);
var $author$project$Contacten$Lead = F6(
	function (leads_id, contact_id, lead, tx_timestamp, follow_up, editing) {
		return {cl: contact_id, c: editing, K: follow_up, ap: lead, M: leads_id, ad: tx_timestamp};
	});
var $author$project$Contacten$decodeLead = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	false,
	A4(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
		'follow_up',
		$elm$json$Json$Decode$string,
		'',
		A3(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
			'tx_timestamp',
			$elm$json$Json$Decode$string,
			A3(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
				'lead',
				$elm$json$Json$Decode$string,
				A3(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
					'contact_id',
					$elm$json$Json$Decode$int,
					A3(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
						'leads_id',
						$elm$json$Json$Decode$int,
						$elm$json$Json$Decode$succeed($author$project$Contacten$Lead)))))));
var $author$project$Contacten$decodeDictLead = A2($elm_community$json_extra$Json$Decode$Extra$dict2, $elm$json$Json$Decode$int, $author$project$Contacten$decodeLead);
var $author$project$Contacten$Model = function (id) {
	return function (bedrijfsnaam) {
		return function (status) {
			return function (straat) {
				return function (nummer) {
					return function (toevoeging) {
						return function (postcode) {
							return function (plaats) {
								return function (voornaam) {
									return function (achternaam) {
										return function (email) {
											return function (tel) {
												return function (accountmng) {
													return function (contactinfo) {
														return function (aquaria) {
															return function (comments) {
																return function (leads) {
																	return function (show) {
																		return function (editing) {
																			return {R: accountmng, aD: achternaam, g: aquaria, bf: bedrijfsnaam, G: comments, aE: contactinfo, c: editing, ai: email, ct: id, x: leads, Z: nummer, at: plaats, aM: postcode, bd: show, ac: status, ax: straat, aP: tel, aQ: toevoeging, aS: voornaam};
																		};
																	};
																};
															};
														};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Contacten$Aquarium = function (aquaria_id) {
	return function (contact_id) {
		return function (soort) {
			return function (plek) {
				return function (info) {
					return function (formaat) {
						return function (plaatsing_van) {
							return function (fact_verzonden) {
								return function (onderhoudfreq) {
									return function (contractsduur) {
										return function (facturering) {
											return function (contract_info) {
												return function (show) {
													return function (editing) {
														return {n: aquaria_id, cl: contact_id, aF: contract_info, ah: contractsduur, c: editing, aI: fact_verzonden, am: facturering, aJ: formaat, ao: info, _: onderhoudfreq, aL: plaatsing_van, au: plek, bd: show, aw: soort};
													};
												};
											};
										};
									};
								};
							};
						};
					};
				};
			};
		};
	};
};
var $author$project$Contacten$decodeAquarium = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	false,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		true,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'contract_info',
			$elm$json$Json$Decode$string,
			'',
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'facturering',
				$elm$json$Json$Decode$string,
				'',
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'contractsduur',
					$elm$json$Json$Decode$string,
					'',
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'onderhoudfreq',
						$elm$json$Json$Decode$int,
						0,
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'fact_verzonden',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'plaatsing_van',
								$elm$json$Json$Decode$string,
								'',
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'formaat',
									$elm$json$Json$Decode$string,
									'',
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'info',
										$elm$json$Json$Decode$string,
										'',
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'plek',
											$elm$json$Json$Decode$string,
											'',
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'soort',
												$elm$json$Json$Decode$string,
												'',
												A3(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
													'contact_id',
													$elm$json$Json$Decode$int,
													A3(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
														'aquaria_id',
														$elm$json$Json$Decode$int,
														$elm$json$Json$Decode$succeed($author$project$Contacten$Aquarium)))))))))))))));
var $author$project$Contacten$decodeDictAquarium = A2($elm_community$json_extra$Json$Decode$Extra$dict2, $elm$json$Json$Decode$int, $author$project$Contacten$decodeAquarium);
var $author$project$Contacten$decode = A2(
	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
	false,
	A2(
		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$hardcoded,
		true,
		A4(
			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
			'leads',
			$author$project$Contacten$decodeDictLead,
			$elm$core$Dict$empty,
			A4(
				$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
				'comments',
				$author$project$Contacten$decodeDictComment,
				$elm$core$Dict$empty,
				A4(
					$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
					'aquaria',
					$author$project$Contacten$decodeDictAquarium,
					$elm$core$Dict$empty,
					A4(
						$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
						'contactinfo',
						$elm$json$Json$Decode$string,
						'',
						A4(
							$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
							'accountmng',
							$elm$json$Json$Decode$string,
							'',
							A4(
								$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
								'tel',
								$elm$json$Json$Decode$string,
								'',
								A4(
									$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
									'email',
									$elm$json$Json$Decode$string,
									'',
									A4(
										$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
										'achternaam',
										$elm$json$Json$Decode$string,
										'',
										A4(
											$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
											'voornaam',
											$elm$json$Json$Decode$string,
											'',
											A4(
												$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
												'plaats',
												$elm$json$Json$Decode$string,
												'',
												A4(
													$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
													'postcode',
													$elm$json$Json$Decode$string,
													'',
													A4(
														$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
														'toevoeging',
														$elm$json$Json$Decode$string,
														'',
														A4(
															$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
															'nummer',
															$elm$json$Json$Decode$int,
															-1,
															A4(
																$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																'straat',
																$elm$json$Json$Decode$string,
																'',
																A4(
																	$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																	'status',
																	$elm$json$Json$Decode$string,
																	'',
																	A4(
																		$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$optional,
																		'bedrijfsnaam',
																		$elm$json$Json$Decode$string,
																		'',
																		A3(
																			$NoRedInk$elm_json_decode_pipeline$Json$Decode$Pipeline$required,
																			'id',
																			$elm$json$Json$Decode$int,
																			$elm$json$Json$Decode$succeed($author$project$Contacten$Model))))))))))))))))))));
var $author$project$Contacten$decodeList = $elm$json$Json$Decode$list($author$project$Contacten$decode);
var $author$project$Main$Stats = F3(
	function (klant, prospect, archief) {
		return {S: archief, W: klant, ab: prospect};
	});
var $elm$json$Json$Decode$map3 = _Json_map3;
var $author$project$Main$decodeStats = A4(
	$elm$json$Json$Decode$map3,
	$author$project$Main$Stats,
	A2($elm$json$Json$Decode$field, 'klant', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'prospect', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'archief', $elm$json$Json$Decode$int));
var $author$project$Main$emptyAction = {cl: 0, t: '', w: ''};
var $elm$json$Json$Encode$int = _Json_wrap;
var $elm$json$Json$Encode$object = function (pairs) {
	return _Json_wrap(
		A3(
			$elm$core$List$foldl,
			F2(
				function (_v0, obj) {
					var k = _v0.a;
					var v = _v0.b;
					return A3(_Json_addField, k, v, obj);
				}),
			_Json_emptyObject(0),
			pairs));
};
var $elm$json$Json$Encode$string = _Json_wrap;
var $author$project$Contacten$encode = F2(
	function (model, user) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'id',
					$elm$json$Json$Encode$int(model.ct)),
					_Utils_Tuple2(
					'bedrijfsnaam',
					$elm$json$Json$Encode$string(model.bf)),
					_Utils_Tuple2(
					'status',
					$elm$json$Json$Encode$string(model.ac)),
					_Utils_Tuple2(
					'straat',
					$elm$json$Json$Encode$string(model.ax)),
					_Utils_Tuple2(
					'nummer',
					$elm$json$Json$Encode$int(model.Z)),
					_Utils_Tuple2(
					'toevoeging',
					$elm$json$Json$Encode$string(model.aQ)),
					_Utils_Tuple2(
					'postcode',
					$elm$json$Json$Encode$string(model.aM)),
					_Utils_Tuple2(
					'plaats',
					$elm$json$Json$Encode$string(model.at)),
					_Utils_Tuple2(
					'voornaam',
					$elm$json$Json$Encode$string(model.aS)),
					_Utils_Tuple2(
					'achternaam',
					$elm$json$Json$Encode$string(model.aD)),
					_Utils_Tuple2(
					'email',
					$elm$json$Json$Encode$string(model.ai)),
					_Utils_Tuple2(
					'tel',
					$elm$json$Json$Encode$string(model.aP)),
					_Utils_Tuple2(
					'accountmng',
					$elm$json$Json$Encode$string(model.R)),
					_Utils_Tuple2(
					'contactinfo',
					$elm$json$Json$Encode$string(model.aE)),
					_Utils_Tuple2(
					'tx_user',
					$elm$json$Json$Encode$string(user))
				]));
	});
var $author$project$Contacten$encodeAquarium = F2(
	function (model, user) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'aquaria_id',
					$elm$json$Json$Encode$int(model.n)),
					_Utils_Tuple2(
					'contact_id',
					$elm$json$Json$Encode$int(model.cl)),
					_Utils_Tuple2(
					'soort',
					$elm$json$Json$Encode$string(model.aw)),
					_Utils_Tuple2(
					'plek',
					$elm$json$Json$Encode$string(model.au)),
					_Utils_Tuple2(
					'info',
					$elm$json$Json$Encode$string(model.ao)),
					_Utils_Tuple2(
					'formaat',
					$elm$json$Json$Encode$string(model.aJ)),
					_Utils_Tuple2(
					'plaatsing_van',
					$elm$json$Json$Encode$string(model.aL)),
					_Utils_Tuple2(
					'fact_verzonden',
					$elm$json$Json$Encode$string(model.aI)),
					_Utils_Tuple2(
					'onderhoudfreq',
					$elm$json$Json$Encode$int(model._)),
					_Utils_Tuple2(
					'contractsduur',
					$elm$json$Json$Encode$string(model.ah)),
					_Utils_Tuple2(
					'facturering',
					$elm$json$Json$Encode$string(model.am)),
					_Utils_Tuple2(
					'contract_info',
					$elm$json$Json$Encode$string(model.aF)),
					_Utils_Tuple2(
					'tx_user',
					$elm$json$Json$Encode$string(user))
				]));
	});
var $author$project$Contacten$encodeComment = F2(
	function (model, user) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'comments_id',
					$elm$json$Json$Encode$int(model.ck)),
					_Utils_Tuple2(
					'contact_id',
					$elm$json$Json$Encode$int(model.cl)),
					_Utils_Tuple2(
					'opmerkingen',
					$elm$json$Json$Encode$string(model.cB)),
					_Utils_Tuple2(
					'tx_timestamp',
					$elm$json$Json$Encode$string(model.ad)),
					_Utils_Tuple2(
					'tx_user',
					$elm$json$Json$Encode$string(user))
				]));
	});
var $author$project$Contacten$encodeLead = F2(
	function (model, user) {
		return $elm$json$Json$Encode$object(
			_List_fromArray(
				[
					_Utils_Tuple2(
					'contact_id',
					$elm$json$Json$Encode$int(model.cl)),
					_Utils_Tuple2(
					'leads_id',
					$elm$json$Json$Encode$int(model.M)),
					_Utils_Tuple2(
					'lead',
					$elm$json$Json$Encode$string(model.ap)),
					_Utils_Tuple2(
					'tx_timestamp',
					$elm$json$Json$Encode$string(model.ad)),
					_Utils_Tuple2(
					'follow_up',
					$elm$json$Json$Encode$string(model.K)),
					_Utils_Tuple2(
					'tx_user',
					$elm$json$Json$Encode$string(user))
				]));
	});
var $emilianobovetti$elm_toast$Toast$ExpireOnBlur = function (a) {
	return {$: 2, a: a};
};
var $emilianobovetti$elm_toast$Toast$Blur = 1;
var $emilianobovetti$elm_toast$Toast$Enter = 0;
var $emilianobovetti$elm_toast$Toast$new = F2(
	function (lifespan, content) {
		return {ag: 0, bw: content, a5: 0, ct: -1, L: 1, a8: lifespan, O: 0};
	});
var $emilianobovetti$elm_toast$Toast$expireOnBlur = function (ttl) {
	return $emilianobovetti$elm_toast$Toast$new(
		$emilianobovetti$elm_toast$Toast$ExpireOnBlur(ttl));
};
var $elm$core$Dict$foldl = F3(
	function (func, acc, dict) {
		foldl:
		while (true) {
			if (dict.$ === -2) {
				return acc;
			} else {
				var key = dict.b;
				var value = dict.c;
				var left = dict.d;
				var right = dict.e;
				var $temp$func = func,
					$temp$acc = A3(
					func,
					key,
					value,
					A3($elm$core$Dict$foldl, func, acc, left)),
					$temp$dict = right;
				func = $temp$func;
				acc = $temp$acc;
				dict = $temp$dict;
				continue foldl;
			}
		}
	});
var $elm$core$Dict$filter = F2(
	function (isGood, dict) {
		return A3(
			$elm$core$Dict$foldl,
			F3(
				function (k, v, d) {
					return A2(isGood, k, v) ? A3($elm$core$Dict$insert, k, v, d) : d;
				}),
			$elm$core$Dict$empty,
			dict);
	});
var $elm$core$List$filter = F2(
	function (isGood, list) {
		return A3(
			$elm$core$List$foldr,
			F2(
				function (x, xs) {
					return isGood(x) ? A2($elm$core$List$cons, x, xs) : xs;
				}),
			_List_Nil,
			list);
	});
var $elm$core$Basics$not = _Basics_not;
var $author$project$Main$filterTolist = function (filter) {
	var filterstatus = _List_fromArray(
		[
			filter.W ? 'klant' : '',
			filter.ab ? 'prospect' : '',
			filter.S ? 'archief' : ''
		]);
	return A2(
		$elm$core$List$filter,
		function (x) {
			return !$elm$core$String$isEmpty(x);
		},
		filterstatus);
};
var $elm$core$List$any = F2(
	function (isOkay, list) {
		any:
		while (true) {
			if (!list.b) {
				return false;
			} else {
				var x = list.a;
				var xs = list.b;
				if (isOkay(x)) {
					return true;
				} else {
					var $temp$isOkay = isOkay,
						$temp$list = xs;
					isOkay = $temp$isOkay;
					list = $temp$list;
					continue any;
				}
			}
		}
	});
var $elm$core$List$member = F2(
	function (x, xs) {
		return A2(
			$elm$core$List$any,
			function (a) {
				return _Utils_eq(a, x);
			},
			xs);
	});
var $author$project$Main$filterContacts = F2(
	function (filter, contacten) {
		return A2(
			$elm$core$Dict$filter,
			F2(
				function (_v0, v) {
					return A2(
						$elm$core$List$member,
						v.ac,
						$author$project$Main$filterTolist(filter));
				}),
			contacten);
	});
var $elm$core$Dict$fromList = function (assocs) {
	return A3(
		$elm$core$List$foldl,
		F2(
			function (_v0, dict) {
				var key = _v0.a;
				var value = _v0.b;
				return A3($elm$core$Dict$insert, key, value, dict);
			}),
		$elm$core$Dict$empty,
		assocs);
};
var $elm$http$Http$emptyBody = _Http_emptyBody;
var $elm$http$Http$expectJson = F2(
	function (toMsg, decoder) {
		return A2(
			$elm$http$Http$expectStringResponse,
			toMsg,
			$elm$http$Http$resolve(
				function (string) {
					return A2(
						$elm$core$Result$mapError,
						$elm$json$Json$Decode$errorToString,
						A2($elm$json$Json$Decode$decodeString, decoder, string));
				}));
	});
var $author$project$Requests$getAgenda = F5(
	function (msg, decoder, token, endpoint, username) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'GET',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/agenda/' + username)
			});
	});
var $author$project$Requests$getComments = F6(
	function (msg, filter, contact_id, token, endpoint, decoder) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'GET',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/comments/' + ($elm$core$String$fromInt(contact_id) + ('/zoek/' + filter)))
			});
	});
var $author$project$Requests$getContact = F5(
	function (msg, decoder, cid, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'GET',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/contact/' + $elm$core$String$fromInt(cid))
			});
	});
var $author$project$Requests$getContacten = F5(
	function (msg, filter, token, endpoint, decoder) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'GET',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/contacten/zoek/' + filter)
			});
	});
var $author$project$Requests$getLeads = F6(
	function (msg, filter, contact_id, token, endpoint, decoder) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'GET',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/leads/' + ($elm$core$String$fromInt(contact_id) + ('/zoek/' + filter)))
			});
	});
var $author$project$Requests$getLeadsPlanning = F4(
	function (msg, decoder, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'GET',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + '/leads/planning'
			});
	});
var $author$project$Requests$getStats = F4(
	function (msg, endpoint, token, decoder) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'GET',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + '/stats'
			});
	});
var $elm$browser$Browser$Navigation$load = _Browser_load;
var $elm$core$Platform$Cmd$map = _Platform_map;
var $elm$core$Platform$Cmd$none = $elm$core$Platform$Cmd$batch(_List_Nil);
var $author$project$Requests$postAquarium = F4(
	function (msg, json, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'POST',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + '/aquaria/new'
			});
	});
var $author$project$Requests$postComments = F4(
	function (msg, json, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'POST',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + '/comments/new'
			});
	});
var $author$project$Requests$postContacten = F4(
	function (msg, json, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'POST',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + '/contacten/new'
			});
	});
var $author$project$Requests$postLeads = F4(
	function (msg, json, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'POST',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + '/leads/new'
			});
	});
var $elm$browser$Browser$Navigation$pushUrl = _Browser_pushUrl;
var $author$project$Main$removeLocal = _Platform_outgoingPort(
	'removeLocal',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Main$requestLocal = _Platform_outgoingPort(
	'requestLocal',
	function ($) {
		return $elm$json$Json$Encode$null;
	});
var $author$project$Contacten$settersAquariaSingle = F3(
	function (field, val, aquarium) {
		switch (field) {
			case 'soort':
				return _Utils_update(
					aquarium,
					{c: true, aw: val});
			case 'plek':
				return _Utils_update(
					aquarium,
					{c: true, au: val});
			case 'info':
				return _Utils_update(
					aquarium,
					{c: true, ao: val});
			case 'formaat':
				return _Utils_update(
					aquarium,
					{c: true, aJ: val});
			case 'plaatsing_van':
				return _Utils_update(
					aquarium,
					{c: true, aL: val});
			case 'fact_verzonden':
				return _Utils_update(
					aquarium,
					{c: true, aI: val});
			case 'onderhoudfreq':
				var _v1 = $elm$core$String$toInt(val);
				if (!_v1.$) {
					var num = _v1.a;
					return _Utils_update(
						aquarium,
						{c: true, _: num});
				} else {
					return aquarium;
				}
			case 'contractsduur':
				return _Utils_update(
					aquarium,
					{ah: val, c: true});
			case 'facturering':
				return _Utils_update(
					aquarium,
					{c: true, am: val});
			case 'contract_info':
				return _Utils_update(
					aquarium,
					{aF: val, c: true});
			case 'editing':
				return _Utils_update(
					aquarium,
					{c: val === 'true'});
			default:
				return aquarium;
		}
	});
var $elm$core$Maybe$withDefault = F2(
	function (_default, maybe) {
		if (!maybe.$) {
			var value = maybe.a;
			return value;
		} else {
			return _default;
		}
	});
var $author$project$Contacten$settersContactenSingle = F3(
	function (field, val, a) {
		switch (field) {
			case 'bedrijfsnaam':
				return _Utils_update(
					a,
					{bf: val, c: true});
			case 'status':
				return _Utils_update(
					a,
					{ac: val});
			case 'straat':
				return _Utils_update(
					a,
					{ax: val});
			case 'nummer':
				return _Utils_update(
					a,
					{
						Z: A2(
							$elm$core$Maybe$withDefault,
							-1,
							$elm$core$String$toInt(val))
					});
			case 'toevoeging':
				return _Utils_update(
					a,
					{aQ: val});
			case 'postcode':
				return _Utils_update(
					a,
					{aM: val});
			case 'plaats':
				return _Utils_update(
					a,
					{at: val});
			case 'voornaam':
				return _Utils_update(
					a,
					{aS: val});
			case 'achternaam':
				return _Utils_update(
					a,
					{aD: val});
			case 'email':
				return _Utils_update(
					a,
					{ai: val});
			case 'tel':
				return _Utils_update(
					a,
					{aP: val});
			case 'accountmng':
				return _Utils_update(
					a,
					{R: val});
			case 'contactinfo':
				return _Utils_update(
					a,
					{aE: val});
			default:
				return a;
		}
	});
var $elm$url$Url$addPort = F2(
	function (maybePort, starter) {
		if (maybePort.$ === 1) {
			return starter;
		} else {
			var port_ = maybePort.a;
			return starter + (':' + $elm$core$String$fromInt(port_));
		}
	});
var $elm$url$Url$addPrefixed = F3(
	function (prefix, maybeSegment, starter) {
		if (maybeSegment.$ === 1) {
			return starter;
		} else {
			var segment = maybeSegment.a;
			return _Utils_ap(
				starter,
				_Utils_ap(prefix, segment));
		}
	});
var $elm$url$Url$toString = function (url) {
	var http = function () {
		var _v0 = url.bV;
		if (!_v0) {
			return 'http://';
		} else {
			return 'https://';
		}
	}();
	return A3(
		$elm$url$Url$addPrefixed,
		'#',
		url.bD,
		A3(
			$elm$url$Url$addPrefixed,
			'?',
			url.bW,
			_Utils_ap(
				A2(
					$elm$url$Url$addPort,
					url.bR,
					_Utils_ap(http, url.bH)),
				url.bP)));
};
var $author$project$Agenda$update = F2(
	function (msg, events) {
		if (!msg.$) {
			var res = msg.a;
			if (!res.$) {
				var list = res.a;
				return _Utils_Tuple2(list, $elm$core$Platform$Cmd$none);
			} else {
				return _Utils_Tuple2(events, $elm$core$Platform$Cmd$none);
			}
		} else {
			return _Utils_Tuple2(events, $elm$core$Platform$Cmd$none);
		}
	});
var $author$project$Contacten$GotResponse = function (a) {
	return {$: 3, a: a};
};
var $author$project$Requests$deleteAquarium = F5(
	function (msg, c_id, user, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$string(user)),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'DELETE',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/aquaria/' + $elm$core$String$fromInt(c_id))
			});
	});
var $author$project$Requests$deleteComment = F5(
	function (msg, c_id, user, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$string(user)),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'DELETE',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/comments/' + $elm$core$String$fromInt(c_id))
			});
	});
var $author$project$Requests$deleteLead = F5(
	function (msg, c_id, user, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(
					$elm$json$Json$Encode$string(user)),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'DELETE',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/leads/' + $elm$core$String$fromInt(c_id))
			});
	});
var $author$project$Requests$putAquaria = F5(
	function (msg, json, c_id, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'PUT',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/aquaria/' + $elm$core$String$fromInt(c_id))
			});
	});
var $author$project$Requests$putComments = F5(
	function (msg, json, c_id, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'PUT',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/comments/' + $elm$core$String$fromInt(c_id))
			});
	});
var $author$project$Requests$putContacten = F5(
	function (msg, json, c_id, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'PUT',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/contacten/' + $elm$core$String$fromInt(c_id))
			});
	});
var $author$project$Requests$putLeads = F5(
	function (msg, json, c_id, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(json),
				i: $elm$http$Http$expectString(msg),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'PUT',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/leads/' + $elm$core$String$fromInt(c_id))
			});
	});
var $author$project$Contacten$settersAquaria = F4(
	function (field, val, cid, model) {
		var aquarium = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Contacten$emptyAquarium,
			A2($elm$core$Dict$get, cid, model.g));
		switch (field) {
			case 'soort':
				var updatedAquarium = _Utils_update(
					aquarium,
					{c: true, aw: val});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'plek':
				var updatedAquarium = _Utils_update(
					aquarium,
					{c: true, au: val});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'info':
				var updatedAquarium = _Utils_update(
					aquarium,
					{c: true, ao: val});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'formaat':
				var updatedAquarium = _Utils_update(
					aquarium,
					{c: true, aJ: val});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'plaatsing_van':
				var updatedAquarium = _Utils_update(
					aquarium,
					{c: true, aL: val});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'fact_verzonden':
				var updatedAquarium = _Utils_update(
					aquarium,
					{c: true, aI: val});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'onderhoudfreq':
				var updatedAquarium = function () {
					var _v1 = $elm$core$String$toInt(val);
					if (!_v1.$) {
						var num = _v1.a;
						return _Utils_update(
							aquarium,
							{c: true, _: num});
					} else {
						return aquarium;
					}
				}();
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'contractsduur':
				var updatedAquarium = _Utils_update(
					aquarium,
					{ah: val, c: true});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'facturering':
				var updatedAquarium = _Utils_update(
					aquarium,
					{c: true, am: val});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'contract_info':
				var updatedAquarium = _Utils_update(
					aquarium,
					{aF: val, c: true});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			case 'editing':
				var updatedAquarium = _Utils_update(
					aquarium,
					{c: val === 'true'});
				return _Utils_update(
					model,
					{
						g: A3($elm$core$Dict$insert, cid, updatedAquarium, model.g)
					});
			default:
				return model;
		}
	});
var $author$project$Contacten$settersComments = F4(
	function (field, val, cid, model) {
		var comment = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Contacten$emptyComment,
			A2($elm$core$Dict$get, cid, model.G));
		switch (field) {
			case 'opmerkingen':
				var updatedComment = _Utils_update(
					comment,
					{c: true, cB: val});
				return _Utils_update(
					model,
					{
						G: A3($elm$core$Dict$insert, cid, updatedComment, model.G)
					});
			case 'editing':
				var updatedComment = _Utils_update(
					comment,
					{c: val === 'true'});
				return _Utils_update(
					model,
					{
						G: A3($elm$core$Dict$insert, cid, updatedComment, model.G)
					});
			default:
				return model;
		}
	});
var $author$project$Contacten$settersContactenField = F3(
	function (field, val, a) {
		switch (field) {
			case 'bedrijfsnaam':
				return _Utils_update(
					a,
					{bf: val});
			case 'status':
				return _Utils_update(
					a,
					{ac: val});
			case 'straat':
				return _Utils_update(
					a,
					{ax: val});
			case 'nummer':
				return _Utils_update(
					a,
					{
						Z: A2(
							$elm$core$Maybe$withDefault,
							-1,
							$elm$core$String$toInt(val))
					});
			case 'toevoeging':
				return _Utils_update(
					a,
					{aQ: val});
			case 'postcode':
				return _Utils_update(
					a,
					{aM: val});
			case 'plaats':
				return _Utils_update(
					a,
					{at: val});
			case 'voornaam':
				return _Utils_update(
					a,
					{aS: val});
			case 'achternaam':
				return _Utils_update(
					a,
					{aD: val});
			case 'email':
				return _Utils_update(
					a,
					{ai: val});
			case 'tel':
				return _Utils_update(
					a,
					{aP: val});
			case 'accountmng':
				return _Utils_update(
					a,
					{R: val});
			case 'contactinfo':
				return _Utils_update(
					a,
					{aE: val});
			default:
				return a;
		}
	});
var $author$project$Contacten$settersLeads = F4(
	function (field, val, cid, model) {
		var lead = A2(
			$elm$core$Maybe$withDefault,
			$author$project$Contacten$emptyLead,
			A2($elm$core$Dict$get, cid, model.x));
		switch (field) {
			case 'lead':
				var updatedLead = _Utils_update(
					lead,
					{c: true, ap: val});
				return _Utils_update(
					model,
					{
						x: A3($elm$core$Dict$insert, cid, updatedLead, model.x)
					});
			case 'editing':
				var updatedLead = _Utils_update(
					lead,
					{c: val === 'true'});
				return _Utils_update(
					model,
					{
						x: A3($elm$core$Dict$insert, cid, updatedLead, model.x)
					});
			case 'follow_up':
				var updatedLead = _Utils_update(
					lead,
					{c: true, K: val});
				return _Utils_update(
					model,
					{
						x: A3($elm$core$Dict$insert, cid, updatedLead, model.x)
					});
			default:
				return model;
		}
	});
var $author$project$Contacten$update = F3(
	function (msg, models, user) {
		switch (msg.$) {
			case 0:
				var contactenId = msg.a;
				var field = msg.b;
				var val = msg.c;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A3($author$project$Contacten$settersContactenField, field, val, model),
						models),
					$elm$core$Platform$Cmd$none);
			case 1:
				var contactenId = msg.a;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						_Utils_update(
							model,
							{c: false}),
						models),
					A5(
						$author$project$Requests$putContacten,
						$author$project$Contacten$GotResponse,
						A2($author$project$Contacten$encode, model, user.aA),
						contactenId,
						user.ay,
						user.aj));
			case 2:
				var contactenId = msg.a;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersComments, 'editing', 'false', contactenId, model),
						models),
					$elm$core$Platform$Cmd$none);
			case 22:
				return _Utils_Tuple2(models, $elm$core$Platform$Cmd$none);
			case 7:
				return _Utils_Tuple2(models, $elm$core$Platform$Cmd$none);
			case 8:
				return _Utils_Tuple2(models, $elm$core$Platform$Cmd$none);
			case 5:
				var contactenId = msg.a;
				var commentid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				var comment = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$emptyComment,
					A2($elm$core$Dict$get, commentid, model.G));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersComments, 'editing', 'false', commentid, model),
						models),
					A5(
						$author$project$Requests$putComments,
						$author$project$Contacten$GotResponse,
						A2($author$project$Contacten$encodeComment, comment, user.aA),
						commentid,
						user.ay,
						user.aj));
			case 6:
				var contactenId = msg.a;
				var cid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				var comment = A2($elm$core$Dict$remove, cid, model.G);
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						_Utils_update(
							model,
							{G: comment}),
						models),
					A5($author$project$Requests$deleteComment, $author$project$Contacten$GotResponse, cid, user.aA, user.ay, user.aj));
			case 9:
				var contactenId = msg.a;
				var commentid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersComments, 'editing', 'false', commentid, model),
						models),
					$elm$core$Platform$Cmd$none);
			case 4:
				var contactenId = msg.a;
				var commentid = msg.b;
				var field = msg.c;
				var val = msg.d;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersComments, field, val, commentid, model),
						models),
					$elm$core$Platform$Cmd$none);
			case 18:
				return _Utils_Tuple2(models, $elm$core$Platform$Cmd$none);
			case 19:
				return _Utils_Tuple2(models, $elm$core$Platform$Cmd$none);
			case 16:
				var contactenId = msg.a;
				var leadid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				var lead = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$emptyLead,
					A2($elm$core$Dict$get, leadid, model.x));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersLeads, 'editing', 'false', leadid, model),
						models),
					A5(
						$author$project$Requests$putLeads,
						$author$project$Contacten$GotResponse,
						A2($author$project$Contacten$encodeLead, lead, user.aA),
						leadid,
						user.ay,
						user.aj));
			case 20:
				var contactenId = msg.a;
				var leadid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersLeads, 'editing', 'false', leadid, model),
						models),
					$elm$core$Platform$Cmd$none);
			case 15:
				var contactenId = msg.a;
				var leadid = msg.b;
				var field = msg.c;
				var val = msg.d;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersLeads, field, val, leadid, model),
						models),
					$elm$core$Platform$Cmd$none);
			case 17:
				var contactenId = msg.a;
				var leadid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				var lead = A2($elm$core$Dict$remove, leadid, model.x);
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						_Utils_update(
							model,
							{x: lead}),
						models),
					A5($author$project$Requests$deleteLead, $author$project$Contacten$GotResponse, leadid, user.aA, user.ay, user.aj));
			case 21:
				return _Utils_Tuple2(models, $elm$core$Platform$Cmd$none);
			case 14:
				return _Utils_Tuple2(models, $elm$core$Platform$Cmd$none);
			case 11:
				var contactenId = msg.a;
				var aquariumid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				var aquarium = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$emptyAquarium,
					A2($elm$core$Dict$get, aquariumid, model.g));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersAquaria, 'editing', 'false', aquariumid, model),
						models),
					A5(
						$author$project$Requests$putAquaria,
						$author$project$Contacten$GotResponse,
						A2($author$project$Contacten$encodeAquarium, aquarium, user.aA),
						aquariumid,
						user.ay,
						user.aj));
			case 12:
				var contactenId = msg.a;
				var aquariumid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersAquaria, 'editing', 'false', aquariumid, model),
						models),
					$elm$core$Platform$Cmd$none);
			case 10:
				var contactenId = msg.a;
				var aquariumid = msg.b;
				var field = msg.c;
				var val = msg.d;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						A4($author$project$Contacten$settersAquaria, field, val, aquariumid, model),
						models),
					$elm$core$Platform$Cmd$none);
			case 13:
				var contactenId = msg.a;
				var aquariumid = msg.b;
				var key = $elm$core$String$fromInt(contactenId);
				var model = A2(
					$elm$core$Maybe$withDefault,
					$author$project$Contacten$empty,
					A2($elm$core$Dict$get, key, models));
				var aquarium = A2($elm$core$Dict$remove, aquariumid, model.g);
				return _Utils_Tuple2(
					A3(
						$elm$core$Dict$insert,
						key,
						_Utils_update(
							model,
							{g: aquarium}),
						models),
					A5($author$project$Requests$deleteAquarium, $author$project$Contacten$GotResponse, aquariumid, user.aA, user.ay, user.aj));
			default:
				return _Utils_Tuple2(models, $elm$core$Platform$Cmd$none);
		}
	});
var $emilianobovetti$elm_toast$Toast$Focus = 0;
var $emilianobovetti$elm_toast$Toast$onBlur = function (toast) {
	var _v0 = toast.a8;
	if (_v0.$ === 2) {
		var ttl = _v0.a;
		return _List_fromArray(
			[
				A2(
				$emilianobovetti$elm_toast$Toast$delay,
				ttl,
				$emilianobovetti$elm_toast$Toast$PrepareExit(toast.ct))
			]);
	} else {
		return _List_Nil;
	}
};
var $emilianobovetti$elm_toast$Toast$handleBlur = function (toast) {
	return A2(
		$emilianobovetti$elm_toast$Toast$withCmds,
		$emilianobovetti$elm_toast$Toast$onBlur(toast),
		_Utils_update(
			toast,
			{ag: toast.ag + 1, L: 1}));
};
var $emilianobovetti$elm_toast$Toast$fire = function (msg) {
	return A2(
		$elm$core$Task$perform,
		$elm$core$Basics$identity,
		$elm$core$Task$succeed(msg));
};
var $emilianobovetti$elm_toast$Toast$withoutCmd = function (model) {
	return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
};
var $emilianobovetti$elm_toast$Toast$handlePrepareExit = function (toast) {
	return ((!toast.L) || (toast.ag > 0)) ? $emilianobovetti$elm_toast$Toast$withoutCmd(
		_Utils_update(
			toast,
			{ag: toast.ag - 1})) : _Utils_Tuple2(
		toast,
		$emilianobovetti$elm_toast$Toast$fire(
			A2($emilianobovetti$elm_toast$Toast$Transition, 2, toast.ct)));
};
var $emilianobovetti$elm_toast$Toast$Remove = function (a) {
	return {$: 3, a: a};
};
var $emilianobovetti$elm_toast$Toast$handleStartExit = function (toast) {
	return _Utils_Tuple2(
		_Utils_update(
			toast,
			{O: 2}),
		A2(
			$emilianobovetti$elm_toast$Toast$delay,
			toast.a5,
			$emilianobovetti$elm_toast$Toast$Remove(toast.ct)));
};
var $elm$core$Basics$neq = _Utils_notEqual;
var $emilianobovetti$elm_toast$Toast$setToasts = F2(
	function (model, toasts) {
		return _Utils_update(
			model,
			{P: toasts});
	});
var $emilianobovetti$elm_toast$Toast$removeToast = F2(
	function (id, model) {
		return $emilianobovetti$elm_toast$Toast$withoutCmd(
			A2(
				$emilianobovetti$elm_toast$Toast$setToasts,
				model,
				A2(
					$elm$core$List$filter,
					function (toast) {
						return !_Utils_eq(toast.ct, id);
					},
					model.P)));
	});
var $emilianobovetti$elm_toast$Toast$findToastAndUpdate = F3(
	function (updater, dest, src) {
		findToastAndUpdate:
		while (true) {
			if (src.b) {
				var hd = src.a;
				var tl = src.b;
				var _v1 = updater(hd);
				if (!_v1.$) {
					var _v2 = _v1.a;
					var toast = _v2.a;
					var cmd = _v2.b;
					return _Utils_Tuple2(
						_Utils_ap(
							$elm$core$List$reverse(dest),
							A2($elm$core$List$cons, toast, tl)),
						$elm$core$Maybe$Just(cmd));
				} else {
					var $temp$updater = updater,
						$temp$dest = A2($elm$core$List$cons, hd, dest),
						$temp$src = tl;
					updater = $temp$updater;
					dest = $temp$dest;
					src = $temp$src;
					continue findToastAndUpdate;
				}
			} else {
				return _Utils_Tuple2(
					$elm$core$List$reverse(dest),
					$elm$core$Maybe$Nothing);
			}
		}
	});
var $elm$core$Tuple$mapBoth = F3(
	function (funcA, funcB, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			funcA(x),
			funcB(y));
	});
var $emilianobovetti$elm_toast$Toast$updateToastWithCmd = F3(
	function (updater, id, model) {
		var doUpdate = function (toast) {
			return _Utils_eq(toast.ct, id) ? $elm$core$Maybe$Just(
				updater(toast)) : $elm$core$Maybe$Nothing;
		};
		return A3(
			$elm$core$Tuple$mapBoth,
			$emilianobovetti$elm_toast$Toast$setToasts(model),
			$elm$core$Maybe$withDefault($elm$core$Platform$Cmd$none),
			A3($emilianobovetti$elm_toast$Toast$findToastAndUpdate, doUpdate, _List_Nil, model.P));
	});
var $emilianobovetti$elm_toast$Toast$updateToast = function (updater) {
	return $emilianobovetti$elm_toast$Toast$updateToastWithCmd(
		A2($elm$core$Basics$composeR, updater, $emilianobovetti$elm_toast$Toast$withoutCmd));
};
var $emilianobovetti$elm_toast$Toast$internalUpdate = function (msg) {
	switch (msg.$) {
		case 0:
			switch (msg.a) {
				case 1:
					var _v1 = msg.a;
					var id = msg.b;
					return A2(
						$emilianobovetti$elm_toast$Toast$updateToast,
						function (toast) {
							return _Utils_update(
								toast,
								{O: 1});
						},
						id);
				case 0:
					var _v2 = msg.a;
					var id = msg.b;
					return A2(
						$emilianobovetti$elm_toast$Toast$updateToast,
						function (toast) {
							return _Utils_update(
								toast,
								{O: 0});
						},
						id);
				default:
					var _v3 = msg.a;
					var id = msg.b;
					return A2($emilianobovetti$elm_toast$Toast$updateToastWithCmd, $emilianobovetti$elm_toast$Toast$handleStartExit, id);
			}
		case 1:
			if (!msg.a) {
				var _v4 = msg.a;
				var id = msg.b;
				return A2(
					$emilianobovetti$elm_toast$Toast$updateToast,
					function (toast) {
						return _Utils_update(
							toast,
							{L: 0});
					},
					id);
			} else {
				var _v5 = msg.a;
				var id = msg.b;
				return A2($emilianobovetti$elm_toast$Toast$updateToastWithCmd, $emilianobovetti$elm_toast$Toast$handleBlur, id);
			}
		case 2:
			var id = msg.a;
			return A2($emilianobovetti$elm_toast$Toast$updateToastWithCmd, $emilianobovetti$elm_toast$Toast$handlePrepareExit, id);
		default:
			var id = msg.a;
			return $emilianobovetti$elm_toast$Toast$removeToast(id);
	}
};
var $elm$core$Tuple$mapFirst = F2(
	function (func, _v0) {
		var x = _v0.a;
		var y = _v0.b;
		return _Utils_Tuple2(
			func(x),
			y);
	});
var $emilianobovetti$elm_toast$Toast$update = F2(
	function (msg, _v0) {
		var model = _v0;
		return A2(
			$elm$core$Tuple$mapFirst,
			$elm$core$Basics$identity,
			A2($emilianobovetti$elm_toast$Toast$internalUpdate, msg, model));
	});
var $author$project$User$GotUser = function (a) {
	return {$: 3, a: a};
};
var $author$project$User$Model = F7(
	function (user_id, username, password, endpoint, msg, token, rol) {
		return {aj: endpoint, aW: msg, aK: password, aZ: rol, ay: token, ca: user_id, aA: username};
	});
var $elm$json$Json$Decode$map7 = _Json_map7;
var $author$project$User$decodeModel = A8(
	$elm$json$Json$Decode$map7,
	$author$project$User$Model,
	A2($elm$json$Json$Decode$field, 'user_id', $elm$json$Json$Decode$int),
	A2($elm$json$Json$Decode$field, 'username', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'password', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'endpoint', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'msg', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'token', $elm$json$Json$Decode$string),
	A2($elm$json$Json$Decode$field, 'rol', $elm$json$Json$Decode$string));
var $author$project$JWT$encode = function (model) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'token',
				$elm$json$Json$Encode$string(model.ay))
			]));
};
var $author$project$User$encodeUser = function (user) {
	return $elm$json$Json$Encode$object(
		_List_fromArray(
			[
				_Utils_Tuple2(
				'username',
				$elm$json$Json$Encode$string(user.aA)),
				_Utils_Tuple2(
				'password',
				$elm$json$Json$Encode$string(user.aK))
			]));
};
var $author$project$Requests$loadUser = F4(
	function (msg, endpoint, token, decoder) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'POST',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + '/login/loaded'
			});
	});
var $author$project$Requests$login = F5(
	function (msg, endpoint, token, decoder, user) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$jsonBody(user),
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'POST',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + '/login'
			});
	});
var $author$project$User$storeLocal = _Platform_outgoingPort('storeLocal', $elm$core$Basics$identity);
var $author$project$User$update = F2(
	function (msg, model) {
		switch (msg.$) {
			case 2:
				return _Utils_Tuple2(
					model,
					A5(
						$author$project$Requests$login,
						$author$project$User$GotUser,
						model.aj,
						model.ay,
						$author$project$User$decodeModel,
						$author$project$User$encodeUser(model)));
			case 4:
				var e = msg.a;
				var t = msg.b;
				return _Utils_Tuple2(
					model,
					A4($author$project$Requests$loadUser, $author$project$User$GotUser, e, t, $author$project$User$decodeModel));
			case 0:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aA: str}),
					$elm$core$Platform$Cmd$none);
			case 1:
				var str = msg.a;
				return _Utils_Tuple2(
					_Utils_update(
						model,
						{aK: str}),
					$elm$core$Platform$Cmd$none);
			default:
				var result = msg.a;
				if (!result.$) {
					var u = result.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{aW: u.aW, aK: u.aK, aZ: u.aZ, ay: u.ay, ca: u.ca, aA: u.aA}),
						$author$project$User$storeLocal(
							$author$project$JWT$encode(
								$author$project$JWT$Model(u.ay))));
				} else {
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				}
		}
	});
var $author$project$Main$updateFilter = F3(
	function (ctype, checked, filter) {
		switch (ctype) {
			case 0:
				return _Utils_update(
					filter,
					{W: checked});
			case 1:
				return _Utils_update(
					filter,
					{ab: checked});
			default:
				return _Utils_update(
					filter,
					{S: checked});
		}
	});
var $author$project$Contacten$updateNewComment = F2(
	function (val, comment) {
		return _Utils_update(
			comment,
			{cB: val});
	});
var $author$project$Contacten$updateNewLead = F3(
	function (field, val, lead) {
		if (field === 'follow_up') {
			return _Utils_update(
				lead,
				{K: val});
		} else {
			return _Utils_update(
				lead,
				{ap: val});
		}
	});
var $author$project$Requests$zoekContacten = F5(
	function (msg, decoder, tag, token, endpoint) {
		return $elm$http$Http$request(
			{
				bt: $elm$http$Http$emptyBody,
				i: A2($elm$http$Http$expectJson, msg, decoder),
				j: _List_fromArray(
					[
						A2($elm$http$Http$header, 'Authorization', 'Bearer ' + token)
					]),
				k: 'GET',
				l: $elm$core$Maybe$Nothing,
				m: $elm$core$Maybe$Nothing,
				bp: endpoint + ('/contacten/zoek/' + tag)
			});
	});
var $author$project$Main$update = F2(
	function (msg, model) {
		update:
		while (true) {
			switch (msg.$) {
				case 0:
					var urlRequest = msg.a;
					if (!urlRequest.$) {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{bp: url}),
							A2(
								$elm$browser$Browser$Navigation$pushUrl,
								model.a7,
								$elm$url$Url$toString(url)));
					} else {
						var url = urlRequest.a;
						return _Utils_Tuple2(
							model,
							$elm$browser$Browser$Navigation$load(url));
					}
				case 1:
					return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 2:
					var result = msg.a;
					if (!result.$) {
						var resp = result.a;
						var asDict = $elm$core$Dict$fromList(
							A2(
								$elm$core$List$map,
								function (x) {
									return _Utils_Tuple2(
										$elm$core$String$fromInt(x.ct),
										x);
								},
								resp));
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{U: asDict, V: asDict}),
							$elm$core$Platform$Cmd$none);
					} else {
						var e = result.a;
						if ((e.$ === 3) && (e.a === 401)) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										a: $author$project$User$init(model.D.aj)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}
				case 5:
					var result = msg.a;
					if (!result.$) {
						var dict = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{a9: dict}),
							$elm$core$Platform$Cmd$none);
					} else {
						var e = result.a;
						if ((e.$ === 3) && (e.a === 401)) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										a: $author$project$User$init(model.D.aj)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}
				case 6:
					var result = msg.a;
					if (!result.$) {
						var dict = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{ba: dict}),
							$elm$core$Platform$Cmd$none);
					} else {
						var e = result.a;
						if ((e.$ === 3) && (e.a === 401)) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										a: $author$project$User$init(model.D.aj)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}
				case 7:
					var result = msg.a;
					if (!result.$) {
						var dict = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aG: dict}),
							$elm$core$Platform$Cmd$none);
					} else {
						var e = result.a;
						if ((e.$ === 3) && (e.a === 401)) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										a: $author$project$User$init(model.D.aj)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}
				case 32:
					var result = msg.a;
					var _v10 = A2(
						$author$project$Agenda$update,
						$author$project$Agenda$GotEvents(result),
						model.af);
					var a = _v10.a;
					var amsg = _v10.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{af: a}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$AgendaMsg, amsg));
				case 20:
					var result = msg.a;
					if (!result.$) {
						var str = result.a;
						var f = $elm$core$String$isEmpty(model.aC) ? model.Y.bf : model.aC;
						return _Utils_Tuple2(
							model,
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A5($author$project$Requests$getContacten, $author$project$Main$GotContacten, f, model.a.ay, model.a.aj, $author$project$Contacten$decodeList),
										A2(
										$elm$core$Task$perform,
										$elm$core$Basics$always(
											A2($author$project$Main$AddToast, str, 1)),
										$elm$core$Process$sleep(0))
									])));
					} else {
						var e = result.a;
						if ((e.$ === 3) && (e.a === 401)) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										a: $author$project$User$init(model.D.aj)
									}),
								A2(
									$elm$core$Task$perform,
									$elm$core$Basics$always(
										A2($author$project$Main$AddToast, 'bad status 401', 0)),
									$elm$core$Process$sleep(0)));
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}
				case 21:
					var result = msg.a;
					if (!result.$) {
						var aantal = result.a;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{aO: aantal}),
							$elm$core$Platform$Cmd$none);
					} else {
						var e = result.a;
						if ((e.$ === 3) && (e.a === 401)) {
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										a: $author$project$User$init(model.D.aj)
									}),
								$elm$core$Platform$Cmd$none);
						} else {
							return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
						}
					}
				case 3:
					var bool = msg.a;
					var ctype = msg.b;
					var filterUpdated = A3($author$project$Main$updateFilter, ctype, bool, model.a6);
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								e: $author$project$Main$emptyAction,
								a6: filterUpdated,
								V: A2($author$project$Main$filterContacts, filterUpdated, model.U)
							}),
						$elm$core$Platform$Cmd$none);
				case 4:
					var cmsg = msg.a;
					switch (cmsg.$) {
						case 21:
							var lid = cmsg.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										aG: A2($elm$core$Dict$remove, lid, model.aG)
									}),
								A5(
									$author$project$Requests$afmeldLeads,
									$author$project$Main$GotResponse,
									$elm$json$Json$Encode$object(
										_List_fromArray(
											[
												_Utils_Tuple2(
												'afgemeld',
												$elm$json$Json$Encode$bool(true)),
												_Utils_Tuple2(
												'tx_user',
												$elm$json$Json$Encode$string(model.a.aA))
											])),
									lid,
									model.a.ay,
									model.a.aj));
						case 22:
							var cid = cmsg.a;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{e: $author$project$Main$emptyAction}),
								A5($author$project$Requests$getContact, $author$project$Main$GotContacten, $author$project$Contacten$decodeList, cid, model.a.ay, model.a.aj));
						case 7:
							var cid = cmsg.a;
							var newComment = model.aq;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										e: {cl: cid, t: '', w: 'opmerking'},
										aq: _Utils_update(
											newComment,
											{cl: cid})
									}),
								$elm$core$Platform$Cmd$none);
						case 18:
							var cid = cmsg.a;
							var newLead = model.ar;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										e: {cl: cid, t: '', w: 'lead'},
										ar: _Utils_update(
											newLead,
											{cl: cid})
									}),
								$elm$core$Platform$Cmd$none);
						case 14:
							var cid = cmsg.a;
							var newAquarium = model.X;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										e: {cl: cid, t: '', w: 'aquarium'},
										X: _Utils_update(
											newAquarium,
											{cl: cid})
									}),
								$elm$core$Platform$Cmd$none);
						case 8:
							var cid = cmsg.a;
							var filter = cmsg.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										e: {cl: cid, t: '', w: 'zoek_opm'}
									}),
								A6($author$project$Requests$getComments, $author$project$Main$GotComments, filter, cid, model.a.ay, model.a.aj, $author$project$Contacten$decodeDictComment));
						case 19:
							var cid = cmsg.a;
							var filter = cmsg.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{
										e: {cl: cid, t: '', w: 'zoek_lead'}
									}),
								A6($author$project$Requests$getLeads, $author$project$Main$GotLeads, filter, cid, model.a.ay, model.a.aj, $author$project$Contacten$decodeDictLead));
						case 3:
							var result = cmsg.a;
							if (!result.$) {
								var r = result.a;
								return _Utils_Tuple2(
									model,
									A2(
										$elm$core$Task$perform,
										$elm$core$Basics$always(
											A2($author$project$Main$AddToast, r, 1)),
										$elm$core$Process$sleep(0)));
							} else {
								var e = result.a;
								return _Utils_Tuple2(
									model,
									A2(
										$elm$core$Task$perform,
										$elm$core$Basics$always(
											A2($author$project$Main$AddToast, 'error', 0)),
										$elm$core$Process$sleep(0)));
							}
						default:
							var _v18 = A3($author$project$Contacten$update, cmsg, model.U, model.a);
							var a = _v18.a;
							var b = _v18.b;
							return _Utils_Tuple2(
								_Utils_update(
									model,
									{U: a, V: a}),
								A2($elm$core$Platform$Cmd$map, $author$project$Main$ContactenMsg, b));
					}
				case 8:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction}),
						A4(
							$author$project$Requests$postComments,
							$author$project$Main$GotResponse,
							A2($author$project$Contacten$encodeComment, model.aq, model.a.aA),
							model.a.ay,
							model.a.aj));
				case 9:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction}),
						$elm$core$Platform$Cmd$none);
				case 10:
					var string = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								aq: A2($author$project$Contacten$updateNewComment, string, model.aq)
							}),
						$elm$core$Platform$Cmd$none);
				case 11:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction}),
						A4(
							$author$project$Requests$postLeads,
							$author$project$Main$GotResponse,
							A2($author$project$Contacten$encodeLead, model.ar, model.a.aA),
							model.a.ay,
							model.a.aj));
				case 13:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction}),
						$elm$core$Platform$Cmd$none);
				case 12:
					var field = msg.a;
					var string = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								ar: A3($author$project$Contacten$updateNewLead, field, string, model.ar)
							}),
						$elm$core$Platform$Cmd$none);
				case 15:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction}),
						A4(
							$author$project$Requests$postAquarium,
							$author$project$Main$GotResponse,
							A2($author$project$Contacten$encodeAquarium, model.X, model.a.aA),
							model.a.ay,
							model.a.aj));
				case 16:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction}),
						$elm$core$Platform$Cmd$none);
				case 14:
					var veld = msg.a;
					var val = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								X: A3($author$project$Contacten$settersAquariaSingle, veld, val, model.X)
							}),
						$elm$core$Platform$Cmd$none);
				case 18:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction}),
						A4(
							$author$project$Requests$postContacten,
							$author$project$Main$GotResponse,
							A2($author$project$Contacten$encode, model.Y, model.a.aA),
							model.a.ay,
							model.a.aj));
				case 19:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								e: $author$project$Main$initAction(model.a),
								U: $elm$core$Dict$empty,
								V: $elm$core$Dict$empty
							}),
						(model.a.aZ === 'admin') ? A4($author$project$Requests$getLeadsPlanning, $author$project$Main$GotDagLeads, $author$project$Contacten$decodeDagLeads, model.a.ay, model.D.aj) : $elm$core$Platform$Cmd$none);
				case 17:
					var veld = msg.a;
					var val = msg.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								Y: A3($author$project$Contacten$settersContactenSingle, veld, val, model.Y)
							}),
						$elm$core$Platform$Cmd$none);
				case 22:
					var tag = msg.a;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction}),
						A5($author$project$Requests$zoekContacten, $author$project$Main$GotContacten, $author$project$Contacten$decodeList, tag, model.a.ay, model.a.aj));
				case 23:
					var cmd = function () {
						var _v19 = model.e.w;
						switch (_v19) {
							case 'zoek_opm':
								return A6($author$project$Requests$getComments, $author$project$Main$GotComments, model.e.t, model.e.cl, model.a.ay, model.a.aj, $author$project$Contacten$decodeDictComment);
							case 'zoek_lead':
								return A6($author$project$Requests$getLeads, $author$project$Main$GotLeads, model.e.t, model.e.cl, model.a.ay, model.a.aj, $author$project$Contacten$decodeDictLead);
							default:
								return $elm$core$Platform$Cmd$none;
						}
					}();
					return _Utils_Tuple2(model, cmd);
				case 24:
					var str = msg.a;
					var zoeklength = $elm$core$String$length(str);
					return (zoeklength > 3) ? _Utils_Tuple2(
						_Utils_update(
							model,
							{aC: str}),
						A5($author$project$Requests$getContacten, $author$project$Main$GotContacten, str, model.a.ay, model.a.aj, $author$project$Contacten$decodeList)) : _Utils_Tuple2(
						_Utils_update(
							model,
							{e: $author$project$Main$emptyAction, aC: str}),
						$elm$core$Platform$Cmd$none);
				case 25:
					var str = msg.a;
					var zoeklength = $elm$core$String$length(str);
					var a = model.e;
					if (zoeklength > 3) {
						var cmd = function () {
							var _v20 = model.e.w;
							switch (_v20) {
								case 'zoek_opm':
									return A6($author$project$Requests$getComments, $author$project$Main$GotComments, a.t, a.cl, model.a.ay, model.a.aj, $author$project$Contacten$decodeDictComment);
								case 'zoek_lead':
									return A6($author$project$Requests$getLeads, $author$project$Main$GotLeads, a.t, a.cl, model.a.ay, model.a.aj, $author$project$Contacten$decodeDictLead);
								default:
									return $elm$core$Platform$Cmd$none;
							}
						}();
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									e: _Utils_update(
										a,
										{t: str})
								}),
							cmd);
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									e: _Utils_update(
										a,
										{t: str})
								}),
							$elm$core$Platform$Cmd$none);
					}
				case 26:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								e: {cl: -1, t: '', w: 'contact'},
								U: $elm$core$Dict$empty,
								V: $elm$core$Dict$empty,
								Y: $author$project$Contacten$empty
							}),
						$elm$core$Platform$Cmd$none);
				case 27:
					var umsg = msg.a;
					var _v21 = A2($author$project$User$update, umsg, model.a);
					var a = _v21.a;
					var b = _v21.b;
					if (umsg.$ === 3) {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{
									e: $author$project$Main$initAction(a),
									a: a
								}),
							$elm$core$Platform$Cmd$batch(
								_List_fromArray(
									[
										A2($elm$browser$Browser$Navigation$pushUrl, model.a7, ''),
										A2($elm$core$Platform$Cmd$map, $author$project$Main$UserMsg, b),
										A4($author$project$Requests$getStats, $author$project$Main$GotStats, model.D.aj, a.ay, $author$project$Main$decodeStats),
										(a.aZ === 'admin') ? A4($author$project$Requests$getLeadsPlanning, $author$project$Main$GotDagLeads, $author$project$Contacten$decodeDagLeads, a.ay, a.aj) : A5($author$project$Requests$getAgenda, $author$project$Main$GotPlanningAgenda, $author$project$Agenda$decodeAgenda, a.ay, a.aj, a.aA)
									])));
					} else {
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{a: a}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$UserMsg, b));
					}
				case 31:
					var amsg = msg.a;
					if (amsg.$ === 2) {
						var name = amsg.a;
						var $temp$msg = $author$project$Main$Zoek(name),
							$temp$model = model;
						msg = $temp$msg;
						model = $temp$model;
						continue update;
					} else {
						var _v24 = A2($author$project$Agenda$update, amsg, model.af);
						var a = _v24.a;
						var b = _v24.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{af: a}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$AgendaMsg, b));
					}
				case 28:
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{
								a: $author$project$User$init(model.D.aj)
							}),
						$author$project$Main$removeLocal(0));
				case 29:
					var json = msg.a;
					var _v25 = A2($elm$json$Json$Decode$decodeValue, $author$project$JWT$decode, json);
					if (!_v25.$) {
						var a = _v25.a;
						var _v26 = A2(
							$author$project$User$update,
							A2($author$project$User$Load, model.D.aj, a.ay),
							model.a);
						var u = _v26.a;
						var usermsg = _v26.b;
						return _Utils_Tuple2(
							_Utils_update(
								model,
								{a: u}),
							A2($elm$core$Platform$Cmd$map, $author$project$Main$UserMsg, usermsg));
					} else {
						return _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
					}
				case 30:
					var bool = msg.a;
					return bool ? _Utils_Tuple2(
						model,
						$author$project$Main$requestLocal(0)) : _Utils_Tuple2(model, $elm$core$Platform$Cmd$none);
				case 34:
					var str = msg.a;
					var color = msg.b;
					var _v27 = A2(
						$emilianobovetti$elm_toast$Toast$add,
						model.az,
						A2(
							$emilianobovetti$elm_toast$Toast$expireOnBlur,
							3000,
							{bg: color, bh: str}));
					var tray = _v27.a;
					var tmesg = _v27.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{az: tray}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$ToastMsg, tmesg));
				default:
					var tmsg = msg.a;
					var _v28 = A2($emilianobovetti$elm_toast$Toast$update, tmsg, model.az);
					var tray = _v28.a;
					var newTmesg = _v28.b;
					return _Utils_Tuple2(
						_Utils_update(
							model,
							{az: tray}),
						A2($elm$core$Platform$Cmd$map, $author$project$Main$ToastMsg, newTmesg));
			}
		}
	});
var $author$project$Main$CancelNewContact = {$: 19};
var $author$project$Main$LogOut = {$: 28};
var $elm$html$Html$a = _VirtualDom_node('a');
var $elm$html$Html$Attributes$stringProperty = F2(
	function (key, string) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$string(string));
	});
var $elm$html$Html$Attributes$class = $elm$html$Html$Attributes$stringProperty('className');
var $emilianobovetti$elm_toast$Toast$config = function (toAppMsg) {
	return {ak: _List_Nil, al: _List_Nil, aU: _List_Nil, bo: toAppMsg, a0: _List_Nil, a1: _List_Nil, be: 'div'};
};
var $elm$html$Html$div = _VirtualDom_node('div');
var $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon = function (a) {
	return {$: 0, a: a};
};
var $frandibar$elm_font_awesome_5$FontAwesome$fileAlt = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('file-alt');
var $elm$core$Basics$ge = _Utils_ge;
var $elm$html$Html$Attributes$height = function (n) {
	return A2(
		_VirtualDom_attribute,
		'height',
		$elm$core$String$fromInt(n));
};
var $elm$html$Html$Attributes$href = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'href',
		_VirtualDom_noJavaScriptUri(url));
};
var $frandibar$elm_font_awesome_5$FontAwesome$Solid = 0;
var $elm$core$Tuple$second = function (_v0) {
	var y = _v0.b;
	return y;
};
var $elm$html$Html$Attributes$classList = function (classes) {
	return $elm$html$Html$Attributes$class(
		A2(
			$elm$core$String$join,
			' ',
			A2(
				$elm$core$List$map,
				$elm$core$Tuple$first,
				A2($elm$core$List$filter, $elm$core$Tuple$second, classes))));
};
var $frandibar$elm_font_awesome_5$FontAwesome$animationClass = function (animation) {
	if (!animation) {
		return 'fa-spin';
	} else {
		return 'fa-pulse';
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$borderClass = 'fa-border';
var $frandibar$elm_font_awesome_5$FontAwesome$invertClass = 'fa-inverse';
var $frandibar$elm_font_awesome_5$FontAwesome$pullClass = function (p) {
	if (!p) {
		return 'fa-pull-left';
	} else {
		return 'fa-pull-right';
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$sizeClass = function (size) {
	switch (size.$) {
		case 0:
			return 'fa-xs';
		case 1:
			return 'fa-sm';
		case 2:
			return 'fa-lg';
		default:
			var n = size.a;
			return 'fa-' + ($elm$core$String$fromInt(n) + 'x');
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$widthClass = 'fa-fw';
var $frandibar$elm_font_awesome_5$FontAwesome$className = function (opt) {
	switch (opt.$) {
		case 0:
			var animation = opt.a;
			return _Utils_Tuple2(
				$frandibar$elm_font_awesome_5$FontAwesome$animationClass(animation),
				true);
		case 1:
			return _Utils_Tuple2($frandibar$elm_font_awesome_5$FontAwesome$borderClass, true);
		case 2:
			return _Utils_Tuple2($frandibar$elm_font_awesome_5$FontAwesome$widthClass, true);
		case 4:
			return _Utils_Tuple2($frandibar$elm_font_awesome_5$FontAwesome$invertClass, true);
		case 6:
			var direction = opt.a;
			return _Utils_Tuple2(
				$frandibar$elm_font_awesome_5$FontAwesome$pullClass(direction),
				true);
		case 7:
			var size = opt.a;
			return _Utils_Tuple2(
				$frandibar$elm_font_awesome_5$FontAwesome$sizeClass(size),
				true);
		default:
			return _Utils_Tuple2('', false);
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$iconClass = function (iconOrLogo) {
	var root = function () {
		if (!iconOrLogo.$) {
			var name = iconOrLogo.a;
			return name;
		} else {
			var name = iconOrLogo.a;
			return name;
		}
	}();
	return 'fa-' + root;
};
var $frandibar$elm_font_awesome_5$FontAwesome$styleClass = F2(
	function (iconOrLogo, style) {
		if (iconOrLogo.$ === 1) {
			return 'fab';
		} else {
			switch (style) {
				case 0:
					return 'fas';
				case 1:
					return 'far';
				default:
					return 'fal';
			}
		}
	});
var $frandibar$elm_font_awesome_5$FontAwesome$classes = F3(
	function (iconUnstyled, style, options) {
		return $elm$html$Html$Attributes$classList(
			A2(
				$elm$core$List$cons,
				_Utils_Tuple2(
					A2($frandibar$elm_font_awesome_5$FontAwesome$styleClass, iconUnstyled, style),
					true),
				A2(
					$elm$core$List$cons,
					_Utils_Tuple2(
						$frandibar$elm_font_awesome_5$FontAwesome$iconClass(iconUnstyled),
						true),
					A2($elm$core$List$map, $frandibar$elm_font_awesome_5$FontAwesome$className, options))));
	});
var $frandibar$elm_font_awesome_5$FontAwesome$Utils$onlyOne = F3(
	function (f, curr, _v0) {
		var found = _v0.a;
		var list = _v0.b;
		var _v1 = _Utils_Tuple2(
			f(curr),
			found);
		if (_v1.a) {
			if (!_v1.b) {
				return _Utils_Tuple2(
					true,
					A2($elm$core$List$cons, curr, list));
			} else {
				return _Utils_Tuple2(found, list);
			}
		} else {
			return _Utils_Tuple2(
				found,
				A2($elm$core$List$cons, curr, list));
		}
	});
var $frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup = F2(
	function (f, list) {
		return A3(
			$elm$core$List$foldr,
			$frandibar$elm_font_awesome_5$FontAwesome$Utils$onlyOne(f),
			_Utils_Tuple2(false, _List_Nil),
			list).b;
	});
var $frandibar$elm_font_awesome_5$FontAwesome$isAnimation = function (option) {
	if (!option.$) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$isBorder = function (option) {
	if (option.$ === 1) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$isHtmlTag = function (option) {
	if (option.$ === 3) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$isInvertColor = function (option) {
	if (option.$ === 4) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$isMask = function (option) {
	if (option.$ === 5) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$isPull = function (option) {
	if (option.$ === 6) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$isSize = function (option) {
	if (option.$ === 7) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$isTransform = function (option) {
	if (option.$ === 8) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$isWidth = function (option) {
	if (option.$ === 2) {
		return true;
	} else {
		return false;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$filterAttrs = function (options) {
	return A2(
		$frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup,
		$frandibar$elm_font_awesome_5$FontAwesome$isWidth,
		A2(
			$frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup,
			$frandibar$elm_font_awesome_5$FontAwesome$isTransform,
			A2(
				$frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup,
				$frandibar$elm_font_awesome_5$FontAwesome$isSize,
				A2(
					$frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup,
					$frandibar$elm_font_awesome_5$FontAwesome$isPull,
					A2(
						$frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup,
						$frandibar$elm_font_awesome_5$FontAwesome$isMask,
						A2(
							$frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup,
							$frandibar$elm_font_awesome_5$FontAwesome$isInvertColor,
							A2(
								$frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup,
								$frandibar$elm_font_awesome_5$FontAwesome$isHtmlTag,
								A2(
									$frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup,
									$frandibar$elm_font_awesome_5$FontAwesome$isBorder,
									A2($frandibar$elm_font_awesome_5$FontAwesome$Utils$dedup, $frandibar$elm_font_awesome_5$FontAwesome$isAnimation, options)))))))));
};
var $elm$virtual_dom$VirtualDom$attribute = F2(
	function (key, value) {
		return A2(
			_VirtualDom_attribute,
			_VirtualDom_noOnOrFormAction(key),
			_VirtualDom_noJavaScriptOrHtmlUri(value));
	});
var $elm$html$Html$Attributes$attribute = $elm$virtual_dom$VirtualDom$attribute;
var $frandibar$elm_font_awesome_5$FontAwesome$mask = F2(
	function (opt, opts) {
		if (opt.$ === 5) {
			var maskIcon = opt.a;
			var style = opt.b;
			var val = A2($frandibar$elm_font_awesome_5$FontAwesome$styleClass, maskIcon, style) + (' ' + $frandibar$elm_font_awesome_5$FontAwesome$iconClass(maskIcon));
			return A2(
				$elm$core$List$cons,
				A2($elm$html$Html$Attributes$attribute, 'data-fa-mask', val),
				opts);
		} else {
			return opts;
		}
	});
var $frandibar$elm_font_awesome_5$FontAwesome$maskAttr = function (options) {
	return A3($elm$core$List$foldr, $frandibar$elm_font_awesome_5$FontAwesome$mask, _List_Nil, options);
};
var $elm$core$String$fromFloat = _String_fromNumber;
var $frandibar$elm_font_awesome_5$FontAwesome$transformVal = function (transformValue) {
	switch (transformValue.$) {
		case 0:
			var n = transformValue.a;
			return 'grow-' + $elm$core$String$fromFloat(n);
		case 1:
			var n = transformValue.a;
			return 'shrink-' + $elm$core$String$fromFloat(n);
		case 2:
			var n = transformValue.a;
			return 'down-' + $elm$core$String$fromFloat(n);
		case 3:
			var n = transformValue.a;
			return 'left-' + $elm$core$String$fromFloat(n);
		case 4:
			var n = transformValue.a;
			return 'right-' + $elm$core$String$fromFloat(n);
		case 5:
			var n = transformValue.a;
			return 'up-' + $elm$core$String$fromFloat(n);
		case 6:
			var n = transformValue.a;
			return 'rotate-' + $elm$core$String$fromFloat(n);
		case 7:
			return 'flip-h';
		default:
			return 'flip-v';
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$transform = F2(
	function (opt, opts) {
		if (opt.$ === 8) {
			var transforms = opt.a;
			var val = A2(
				$elm$core$String$join,
				' ',
				A2($elm$core$List$map, $frandibar$elm_font_awesome_5$FontAwesome$transformVal, transforms));
			return A2(
				$elm$core$List$cons,
				A2($elm$html$Html$Attributes$attribute, 'data-fa-transform', val),
				opts);
		} else {
			return opts;
		}
	});
var $frandibar$elm_font_awesome_5$FontAwesome$transformAttr = function (options) {
	return A3($elm$core$List$foldr, $frandibar$elm_font_awesome_5$FontAwesome$transform, _List_Nil, options);
};
var $frandibar$elm_font_awesome_5$FontAwesome$htmlAttrs = F2(
	function (options, htmlAttributes) {
		return _Utils_ap(
			$frandibar$elm_font_awesome_5$FontAwesome$transformAttr(options),
			_Utils_ap(
				$frandibar$elm_font_awesome_5$FontAwesome$maskAttr(options),
				htmlAttributes));
	});
var $frandibar$elm_font_awesome_5$FontAwesome$I = 0;
var $frandibar$elm_font_awesome_5$FontAwesome$findTag = F2(
	function (option, previousTag) {
		if (option.$ === 3) {
			var newTag = option.a;
			return newTag;
		} else {
			return previousTag;
		}
	});
var $elm$html$Html$i = _VirtualDom_node('i');
var $elm$html$Html$span = _VirtualDom_node('span');
var $frandibar$elm_font_awesome_5$FontAwesome$htmlTag = function (opts) {
	var foundTag = A3($elm$core$List$foldl, $frandibar$elm_font_awesome_5$FontAwesome$findTag, 0, opts);
	if (!foundTag) {
		return $elm$html$Html$i;
	} else {
		return $elm$html$Html$span;
	}
};
var $frandibar$elm_font_awesome_5$FontAwesome$iconWithOptions = F4(
	function (iconName, style, options, htmlAttributes) {
		var opts = $frandibar$elm_font_awesome_5$FontAwesome$filterAttrs(options);
		return A3(
			$frandibar$elm_font_awesome_5$FontAwesome$htmlTag,
			opts,
			A2(
				$elm$core$List$cons,
				A3($frandibar$elm_font_awesome_5$FontAwesome$classes, iconName, style, opts),
				A2($frandibar$elm_font_awesome_5$FontAwesome$htmlAttrs, opts, htmlAttributes)),
			_List_Nil);
	});
var $frandibar$elm_font_awesome_5$FontAwesome$icon = function (iconName) {
	return A4($frandibar$elm_font_awesome_5$FontAwesome$iconWithOptions, iconName, 0, _List_Nil, _List_Nil);
};
var $elm$html$Html$img = _VirtualDom_node('img');
var $author$project$Main$NewContact = {$: 26};
var $author$project$Main$UpdateFilter = function (a) {
	return {$: 24, a: a};
};
var $frandibar$elm_font_awesome_5$FontAwesome$home = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('home');
var $elm$html$Html$input = _VirtualDom_node('input');
var $author$project$Contacten$NewAquarium = function (a) {
	return {$: 14, a: a};
};
var $author$project$Contacten$NewComment = function (a) {
	return {$: 7, a: a};
};
var $author$project$Contacten$NewLead = function (a) {
	return {$: 18, a: a};
};
var $author$project$Contacten$SaveContacten = function (a) {
	return {$: 1, a: a};
};
var $author$project$Contacten$SearchComment = F2(
	function (a, b) {
		return {$: 8, a: a, b: b};
	});
var $author$project$Contacten$SearchLead = F2(
	function (a, b) {
		return {$: 19, a: a, b: b};
	});
var $author$project$Contacten$UpdateContacten = F3(
	function (a, b, c) {
		return {$: 0, a: a, b: b, c: c};
	});
var $frandibar$elm_font_awesome_5$FontAwesome$envelope = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('envelope');
var $elm$html$Html$option = _VirtualDom_node('option');
var $elm$html$Html$Attributes$boolProperty = F2(
	function (key, bool) {
		return A2(
			_VirtualDom_property,
			key,
			$elm$json$Json$Encode$bool(bool));
	});
var $elm$html$Html$Attributes$selected = $elm$html$Html$Attributes$boolProperty('selected');
var $elm$virtual_dom$VirtualDom$text = _VirtualDom_text;
var $elm$html$Html$text = $elm$virtual_dom$VirtualDom$text;
var $elm$html$Html$Attributes$value = $elm$html$Html$Attributes$stringProperty('value');
var $author$project$Contacten$makeContactOptions = function (val) {
	var o = _List_fromArray(
		['Prospect', 'Klant', 'Archief']);
	return A2(
		$elm$core$List$map,
		function (x) {
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(x),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(x, val))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(x)
					]));
		},
		o);
};
var $author$project$Contacten$CancelAquarium = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Contacten$DeleteAquarium = F2(
	function (a, b) {
		return {$: 13, a: a, b: b};
	});
var $author$project$Contacten$SaveAquarium = F2(
	function (a, b) {
		return {$: 11, a: a, b: b};
	});
var $author$project$Contacten$UpdateAquarium = F4(
	function (a, b, c, d) {
		return {$: 10, a: a, b: b, c: c, d: d};
	});
var $author$project$Contacten$makeMonthOptions = function (val) {
	var o = _List_fromArray(
		['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72']);
	return A2(
		$elm$core$List$map,
		function (x) {
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(x),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(x, val))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(x)
					]));
		},
		o);
};
var $author$project$Contacten$makeOnderhoudOptions = function (val) {
	var o = _List_fromArray(
		['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '70', '71', '72', '73', '74', '75', '76', '77', '78', '79', '80', '81', '82', '83', '84', '85', '86', '87', '88', '89', '90']);
	return A2(
		$elm$core$List$map,
		function (x) {
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(x),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(x, val))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(x)
					]));
		},
		o);
};
var $elm$virtual_dom$VirtualDom$Normal = function (a) {
	return {$: 0, a: a};
};
var $elm$virtual_dom$VirtualDom$on = _VirtualDom_on;
var $elm$html$Html$Events$on = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$Normal(decoder));
	});
var $author$project$Contacten$onClickCustom2 = F4(
	function (contactId, changeId, listener, msg) {
		var decoder = A3(
			$elm$json$Json$Decode$map2,
			msg,
			$elm$json$Json$Decode$succeed(contactId),
			$elm$json$Json$Decode$succeed(changeId));
		return A2($elm$html$Html$Events$on, listener, decoder);
	});
var $elm$json$Json$Decode$at = F2(
	function (fields, decoder) {
		return A3($elm$core$List$foldr, $elm$json$Json$Decode$field, decoder, fields);
	});
var $elm$html$Html$Events$targetValue = A2(
	$elm$json$Json$Decode$at,
	_List_fromArray(
		['target', 'value']),
	$elm$json$Json$Decode$string);
var $author$project$Contacten$onInputCustom4 = F5(
	function (contactId, changeId, listener, field, msg) {
		var decoder = A5(
			$elm$json$Json$Decode$map4,
			msg,
			$elm$json$Json$Decode$succeed(contactId),
			$elm$json$Json$Decode$succeed(changeId),
			$elm$json$Json$Decode$succeed(field),
			$elm$html$Html$Events$targetValue);
		return A2($elm$html$Html$Events$on, listener, decoder);
	});
var $frandibar$elm_font_awesome_5$FontAwesome$save = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('save');
var $elm$html$Html$select = _VirtualDom_node('select');
var $elm$html$Html$Attributes$title = $elm$html$Html$Attributes$stringProperty('title');
var $frandibar$elm_font_awesome_5$FontAwesome$trash = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('trash');
var $elm$html$Html$Attributes$type_ = $elm$html$Html$Attributes$stringProperty('type');
var $author$project$Contacten$mapAquaria = F2(
	function (dict, rol) {
		return A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('flex-fieldcolumnaqua')
				]),
			A2(
				$elm$core$List$map,
				function (_v0) {
					var x = _v0.b;
					return (rol === 'admin') ? A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-row')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-rowmore')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-fieldcolumn')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Plek :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-r'),
																		$elm$html$Html$Attributes$value(x.au),
																		A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'plek', $author$project$Contacten$UpdateAquarium)
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Aquarium :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-r'),
																		$elm$html$Html$Attributes$value(x.aw),
																		A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'soort', $author$project$Contacten$UpdateAquarium)
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Info :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-r'),
																		$elm$html$Html$Attributes$value(x.ao),
																		A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'info', $author$project$Contacten$UpdateAquarium)
																	]),
																_List_Nil)
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-fieldcolumn')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Formaat :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-r'),
																		$elm$html$Html$Attributes$value(x.aJ),
																		A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'formaat', $author$project$Contacten$UpdateAquarium)
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Plaatsing :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-r'),
																		$elm$html$Html$Attributes$type_('date'),
																		$elm$html$Html$Attributes$value(x.aL),
																		A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'plaatsing_van', $author$project$Contacten$UpdateAquarium)
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Factuur verzonden :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-r'),
																		$elm$html$Html$Attributes$type_('date'),
																		$elm$html$Html$Attributes$value(x.aI),
																		A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'fact_verzonden', $author$project$Contacten$UpdateAquarium)
																	]),
																_List_Nil)
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-fieldcolumn')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-input')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Onderhoud :')
																			])),
																		A2(
																		$elm$html$Html$select,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-nr'),
																				$elm$html$Html$Attributes$value(
																				_Utils_eq(x._, -1) ? '' : $elm$core$String$fromInt(x._)),
																				A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'onderhoudfreq', $author$project$Contacten$UpdateAquarium)
																			]),
																		$author$project$Contacten$makeOnderhoudOptions(
																			$elm$core$String$fromInt(x._)))
																	])),
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-text')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('dagen')
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-input')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Contr.duur :')
																			])),
																		A2(
																		$elm$html$Html$select,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-nr'),
																				$elm$html$Html$Attributes$value(x.ah),
																				A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'contractsduur', $author$project$Contacten$UpdateAquarium)
																			]),
																		$author$project$Contacten$makeMonthOptions(x.ah))
																	])),
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-text')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('maanden')
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-input')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Facturering :')
																			])),
																		A2(
																		$elm$html$Html$select,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-nr'),
																				$elm$html$Html$Attributes$value(x.am),
																				A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'facturering', $author$project$Contacten$UpdateAquarium)
																			]),
																		$author$project$Contacten$makeMonthOptions(x.am))
																	])),
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-text')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('maanden')
																	]))
															]))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-column')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Contract info :')
													])),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-contract'),
														$elm$html$Html$Attributes$value(x.aF),
														A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'contract_info', $author$project$Contacten$UpdateAquarium)
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('button-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
												A4($author$project$Contacten$onClickCustom2, x.cl, x.n, 'click', $author$project$Contacten$SaveAquarium),
												$elm$html$Html$Attributes$title('Aquarium info opslaan')
											]),
										_List_fromArray(
											[
												$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$save)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
												$elm$html$Html$Attributes$title('Aquarium info verwijderen'),
												A4($author$project$Contacten$onClickCustom2, x.cl, x.n, 'click', $author$project$Contacten$DeleteAquarium)
											]),
										_List_fromArray(
											[
												$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$trash)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
												A4($author$project$Contacten$onClickCustom2, x.cl, x.n, 'click', $author$project$Contacten$CancelAquarium),
												$elm$html$Html$Attributes$title('Cancel')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('x')
											]))
									]))
							])) : A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-fieldcolumn')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-rowmore')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-column')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Plek :')
													])),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-r'),
														$elm$html$Html$Attributes$value(x.au),
														A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'plek', $author$project$Contacten$UpdateAquarium)
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-column')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Aquarium :')
													])),
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-r'),
														$elm$html$Html$Attributes$value(x.aw),
														A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'soort', $author$project$Contacten$UpdateAquarium)
													]),
												_List_Nil)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-name')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Info :')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-r'),
												$elm$html$Html$Attributes$value(x.ao),
												A5($author$project$Contacten$onInputCustom4, x.cl, x.n, 'input', 'info', $author$project$Contacten$UpdateAquarium)
											]),
										_List_Nil)
									]))
							]));
				},
				$elm$core$List$reverse(
					$elm$core$Dict$toList(dict))));
	});
var $author$project$Contacten$CancelComment = F2(
	function (a, b) {
		return {$: 9, a: a, b: b};
	});
var $author$project$Contacten$DeleteComment = F2(
	function (a, b) {
		return {$: 6, a: a, b: b};
	});
var $author$project$Contacten$SaveComment = F2(
	function (a, b) {
		return {$: 5, a: a, b: b};
	});
var $author$project$Contacten$UpdateComment = F4(
	function (a, b, c, d) {
		return {$: 4, a: a, b: b, c: c, d: d};
	});
var $elm$html$Html$textarea = _VirtualDom_node('textarea');
var $author$project$Contacten$mapComments = function (dict) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (_v0) {
				var x = _v0.b;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-rowcomments')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-fieldopm')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('opmerkingen-input')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field-opml'),
													$elm$html$Html$Attributes$value(x.ad)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$textarea,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field-r'),
													$elm$html$Html$Attributes$value(x.cB),
													A5($author$project$Contacten$onInputCustom4, x.cl, x.ck, 'input', 'opmerkingen', $author$project$Contacten$UpdateComment)
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
											A4($author$project$Contacten$onClickCustom2, x.cl, x.ck, 'click', $author$project$Contacten$SaveComment),
											$elm$html$Html$Attributes$title('Opmerking opslaan')
										]),
									_List_fromArray(
										[
											$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$save)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
											$elm$html$Html$Attributes$title('Opmerking verwijderen'),
											A4($author$project$Contacten$onClickCustom2, x.cl, x.ck, 'click', $author$project$Contacten$DeleteComment)
										]),
									_List_fromArray(
										[
											$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$trash)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
											A4($author$project$Contacten$onClickCustom2, x.cl, x.ck, 'click', $author$project$Contacten$CancelComment),
											$elm$html$Html$Attributes$title('Cancel')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('x')
										]))
								]))
						]));
			},
			$elm$core$List$reverse(
				$elm$core$Dict$toList(dict))));
};
var $author$project$Contacten$CancelLead = F2(
	function (a, b) {
		return {$: 20, a: a, b: b};
	});
var $author$project$Contacten$DeleteLead = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Contacten$SaveLead = F2(
	function (a, b) {
		return {$: 16, a: a, b: b};
	});
var $author$project$Contacten$UpdateLead = F4(
	function (a, b, c, d) {
		return {$: 15, a: a, b: b, c: c, d: d};
	});
var $author$project$Contacten$mapLeads = function (dict) {
	return A2(
		$elm$html$Html$div,
		_List_Nil,
		A2(
			$elm$core$List$map,
			function (_v0) {
				var x = _v0.b;
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('flex-rowcomments')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('follow-input')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('flex-column')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field-opml'),
													$elm$html$Html$Attributes$value(x.ad),
													A5($author$project$Contacten$onInputCustom4, x.cl, x.M, 'input', 'tx_timestamp', $author$project$Contacten$UpdateLead)
												]),
											_List_Nil),
											A2(
											$elm$html$Html$textarea,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field-r'),
													$elm$html$Html$Attributes$value(x.ap),
													A5($author$project$Contacten$onInputCustom4, x.cl, x.M, 'input', 'lead', $author$project$Contacten$UpdateLead)
												]),
											_List_Nil)
										])),
									A2(
									$elm$html$Html$div,
									_List_Nil,
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field-opml')
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('Follow-up')
												])),
											A2(
											$elm$html$Html$input,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('field-date'),
													$elm$html$Html$Attributes$type_('date'),
													$elm$html$Html$Attributes$value(x.K),
													A5($author$project$Contacten$onInputCustom4, x.cl, x.M, 'input', 'follow_up', $author$project$Contacten$UpdateLead)
												]),
											_List_Nil)
										]))
								])),
							A2(
							$elm$html$Html$div,
							_List_Nil,
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
											A4($author$project$Contacten$onClickCustom2, x.cl, x.M, 'click', $author$project$Contacten$SaveLead),
											$elm$html$Html$Attributes$title('Follow-up info opslaan')
										]),
									_List_fromArray(
										[
											$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$save)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
											$elm$html$Html$Attributes$title('Follow-up info verwijderen'),
											A4($author$project$Contacten$onClickCustom2, x.cl, x.M, 'click', $author$project$Contacten$DeleteLead)
										]),
									_List_fromArray(
										[
											$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$trash)
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											x.c ? $elm$html$Html$Attributes$class('button-refresh') : $elm$html$Html$Attributes$class('button-hide'),
											A4($author$project$Contacten$onClickCustom2, x.cl, x.M, 'click', $author$project$Contacten$CancelLead),
											$elm$html$Html$Attributes$title('Cancel ')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('x')
										]))
								]))
						]));
			},
			$elm$core$List$reverse(
				$elm$core$Dict$toList(dict))));
};
var $frandibar$elm_font_awesome_5$FontAwesome$mapMarked = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('map-marked');
var $author$project$Contacten$onClickCustom = F3(
	function (contactId, listener, msg) {
		var decoder = A2(
			$elm$json$Json$Decode$map,
			msg,
			$elm$json$Json$Decode$succeed(contactId));
		return A2($elm$html$Html$Events$on, listener, decoder);
	});
var $author$project$Contacten$onClickCustom2String = F4(
	function (contactId, filter, listener, msg) {
		var decoder = A3(
			$elm$json$Json$Decode$map2,
			msg,
			$elm$json$Json$Decode$succeed(contactId),
			$elm$json$Json$Decode$succeed(filter));
		return A2($elm$html$Html$Events$on, listener, decoder);
	});
var $author$project$Contacten$onInputCustom = F4(
	function (contactId, listener, field, msg) {
		var decoder = A4(
			$elm$json$Json$Decode$map3,
			msg,
			$elm$json$Json$Decode$succeed(contactId),
			$elm$json$Json$Decode$succeed(field),
			$elm$html$Html$Events$targetValue);
		return A2($elm$html$Html$Events$on, listener, decoder);
	});
var $elm$url$Url$percentEncode = _Url_percentEncode;
var $frandibar$elm_font_awesome_5$FontAwesome$search = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('search');
var $elm$virtual_dom$VirtualDom$style = _VirtualDom_style;
var $elm$html$Html$Attributes$style = $elm$virtual_dom$VirtualDom$style;
var $elm$html$Html$Attributes$target = $elm$html$Html$Attributes$stringProperty('target');
var $author$project$Contacten$map = F2(
	function (dict, rol) {
		return A2(
			$elm$html$Html$div,
			_List_Nil,
			A2(
				$elm$core$List$map,
				function (_v0) {
					var x = _v0.b;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('fieldbar-2')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-fieldcontact')
											]),
										_List_fromArray(
											[
												(rol === 'admin') ? A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-rowbtn')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$select,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-sel'),
																		A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'status', $author$project$Contacten$UpdateContacten)
																	]),
																$author$project$Contacten$makeContactOptions(x.ac)),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class(x.ac)
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button-refresh'),
																		A3($author$project$Contacten$onClickCustom, x.ct, 'click', $author$project$Contacten$SaveContacten),
																		$elm$html$Html$Attributes$title('Contact opslaan')
																	]),
																_List_fromArray(
																	[
																		$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$save)
																	]))
															]))
													])) : A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('opmerkingen')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(x.ac)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-fieldrow')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$span,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Bedrijfsnaam :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-head'),
																				$elm$html$Html$Attributes$value(x.bf),
																				A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'bedrijfsnaam', $author$project$Contacten$UpdateContacten)
																			]),
																		_List_Nil)
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$span,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Adres :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-headadr'),
																				$elm$html$Html$Attributes$value(x.ax),
																				A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'straat', $author$project$Contacten$UpdateContacten)
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-rowcombi')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('flex-column')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$span,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('field-name')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Nr :')
																					])),
																				A2(
																				$elm$html$Html$input,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('field-headnr'),
																						$elm$html$Html$Attributes$value(
																						_Utils_eq(x.Z, -1) ? '' : $elm$core$String$fromInt(x.Z)),
																						A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'nummer', $author$project$Contacten$UpdateContacten)
																					]),
																				_List_Nil)
																			])),
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('flex-column')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$span,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('field-name')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Tv :')
																					])),
																				A2(
																				$elm$html$Html$input,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('field-headnr'),
																						$elm$html$Html$Attributes$value(x.aQ),
																						A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'toevoeging', $author$project$Contacten$UpdateContacten)
																					]),
																				_List_Nil)
																			]))
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-row')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('flex-input')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$span,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('field-name')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Pc :')
																					])),
																				A2(
																				$elm$html$Html$input,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('field-headpc'),
																						$elm$html$Html$Attributes$value(x.aM),
																						A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'postcode', $author$project$Contacten$UpdateContacten)
																					]),
																				_List_Nil)
																			])),
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('flex-column')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$span,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('field-name')
																					]),
																				_List_fromArray(
																					[
																						$elm$html$Html$text('Plaats :')
																					])),
																				A2(
																				$elm$html$Html$input,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('field-headpl'),
																						$elm$html$Html$Attributes$value(x.at),
																						A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'plaats', $author$project$Contacten$UpdateContacten)
																					]),
																				_List_Nil)
																			])),
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-mail')
																			]),
																		_List_fromArray(
																			[
																				A2(
																				$elm$html$Html$a,
																				_List_fromArray(
																					[
																						$elm$html$Html$Attributes$class('button-maps'),
																						$elm$html$Html$Attributes$title('ga naar maps'),
																						$elm$html$Html$Attributes$href(
																						'https://www.google.nl/maps/place/' + (x.ax + ('+' + ($elm$core$String$fromInt(x.Z) + ('+,' + x.at))))),
																						$elm$html$Html$Attributes$target('blank')
																					]),
																				_List_fromArray(
																					[
																						$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$mapMarked)
																					]))
																			]))
																	]))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-fieldrow')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Contact voornaam :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-contact'),
																				$elm$html$Html$Attributes$value(x.aS),
																				A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'voornaam', $author$project$Contacten$UpdateContacten)
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('achternaam :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-contact'),
																				$elm$html$Html$Attributes$value(x.aD),
																				A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'achternaam', $author$project$Contacten$UpdateContacten)
																			]),
																		_List_Nil)
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Telefoon :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-tel'),
																				$elm$html$Html$Attributes$value(x.aP),
																				A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'tel', $author$project$Contacten$UpdateContacten)
																			]),
																		_List_Nil)
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Email :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-r'),
																				$elm$html$Html$Attributes$value(x.ai),
																				A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'email', $author$project$Contacten$UpdateContacten)
																			]),
																		_List_Nil)
																	])),
																(rol === 'admin') ? A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-mail')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$a,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('button-mail'),
																				$elm$html$Html$Attributes$title('ga naar gmail'),
																				$elm$html$Html$Attributes$href(
																				'https://mail.google.com/mail/u/0/#search/' + $elm$url$Url$percentEncode(x.ai)),
																				$elm$html$Html$Attributes$target('blank')
																			]),
																		_List_fromArray(
																			[
																				$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$envelope)
																			]))
																	])) : A2($elm$html$Html$div, _List_Nil, _List_Nil)
															]))
													])),
												(rol === 'admin') ? A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-fieldrow')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-mng')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Accountmng :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-mng'),
																		$elm$html$Html$Attributes$value(x.R),
																		A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'accountmng', $author$project$Contacten$UpdateContacten)
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Contact info :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-mnginfo'),
																		$elm$html$Html$Attributes$value(x.aE),
																		A4($author$project$Contacten$onInputCustom, x.ct, 'input', 'contactinfo', $author$project$Contacten$UpdateContacten)
																	]),
																_List_Nil)
															]))
													])) : A2($elm$html$Html$div, _List_Nil, _List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-fieldcontact')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-rowbtn')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('opmerkingen')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Aquaria :')
															])),
														(rol === 'admin') ? A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button-plus'),
																$elm$html$Html$Attributes$title('Nieuw Aquarium'),
																A3($author$project$Contacten$onClickCustom, x.ct, 'click', $author$project$Contacten$NewAquarium)
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('+')
															])) : A2($elm$html$Html$div, _List_Nil, _List_Nil)
													])),
												A2($author$project$Contacten$mapAquaria, x.g, rol)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-fieldcontact')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-rowbtn')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('opmerkingen')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field'),
																		A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Opmerkingen :')
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field')
																	]),
																_List_Nil),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field')
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button-refresh'),
																		$elm$html$Html$Attributes$title('Zoek opmerkingen'),
																		A4($author$project$Contacten$onClickCustom2String, x.ct, '', 'click', $author$project$Contacten$SearchComment)
																	]),
																_List_fromArray(
																	[
																		$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$search)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button-plus'),
																		$elm$html$Html$Attributes$title('Nieuwe opmerking'),
																		A3($author$project$Contacten$onClickCustom, x.ct, 'click', $author$project$Contacten$NewComment)
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('+')
																	]))
															]))
													])),
												$author$project$Contacten$mapComments(x.G)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-fieldcontact')
											]),
										(rol === 'admin') ? _List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-rowbtn')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('opmerkingen')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field'),
																		A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Follow-up info :')
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field')
																	]),
																_List_Nil),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field')
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button-refresh'),
																		$elm$html$Html$Attributes$title('Zoek follow-up'),
																		A4($author$project$Contacten$onClickCustom2String, x.ct, '', 'click', $author$project$Contacten$SearchLead)
																	]),
																_List_fromArray(
																	[
																		$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$search)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('button-plus'),
																		$elm$html$Html$Attributes$title('Nieuwe follow-up'),
																		A3($author$project$Contacten$onClickCustom, x.ct, 'click', $author$project$Contacten$NewLead)
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('+')
																	]))
															]))
													])),
												$author$project$Contacten$mapLeads(x.x)
											]) : _List_Nil)
									]))
							]));
				},
				$elm$core$Dict$toList(dict)));
	});
var $elm$virtual_dom$VirtualDom$map = _VirtualDom_map;
var $elm$html$Html$map = $elm$virtual_dom$VirtualDom$map;
var $author$project$Main$CancelNewAquarium = {$: 16};
var $author$project$Main$CancelNewComment = {$: 9};
var $author$project$Main$CancelNewLead = {$: 13};
var $author$project$Main$SaveNewAquarium = {$: 15};
var $author$project$Main$SaveNewComment = {$: 8};
var $author$project$Main$SaveNewLead = {$: 11};
var $author$project$Main$UpdateActionFilter = function (a) {
	return {$: 25, a: a};
};
var $author$project$Main$UpdateNewComment = function (a) {
	return {$: 10, a: a};
};
var $author$project$Main$UpdateNewLead = F2(
	function (a, b) {
		return {$: 12, a: a, b: b};
	});
var $author$project$Main$ZoekAction = {$: 23};
var $author$project$Main$UpdateNewAquarium = F2(
	function (a, b) {
		return {$: 14, a: a, b: b};
	});
var $author$project$Main$makeMonthOptions = function (val) {
	var o = _List_fromArray(
		['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31', '32', '33', '34', '35', '36', '37', '38', '39', '40', '41', '42', '43', '44', '45', '46', '47', '48', '49', '50', '51', '52', '53', '54', '55', '56', '57', '58', '59', '60', '61', '62', '63', '64', '65', '66', '67', '68', '69', '70', '71', '72']);
	return A2(
		$elm$core$List$map,
		function (x) {
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(x),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(x, val))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(x)
					]));
		},
		o);
};
var $author$project$Main$makeOnderhoudOptions = function (val) {
	var o = _List_fromArray(
		['', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']);
	return A2(
		$elm$core$List$map,
		function (x) {
			return A2(
				$elm$html$Html$option,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$value(x),
						$elm$html$Html$Attributes$selected(
						_Utils_eq(x, val))
					]),
				_List_fromArray(
					[
						$elm$html$Html$text(x)
					]));
		},
		o);
};
var $author$project$Main$onInputCustom = F3(
	function (listener, field, msg) {
		var decoder = A3(
			$elm$json$Json$Decode$map2,
			msg,
			$elm$json$Json$Decode$succeed(field),
			$elm$html$Html$Events$targetValue);
		return A2($elm$html$Html$Events$on, listener, decoder);
	});
var $author$project$Main$mapAquariaNew = function (x) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('flex-column')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex-rowmore')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-fieldcolumn')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-name')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Plek :')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-r'),
												$elm$html$Html$Attributes$value(x.au),
												A3($author$project$Main$onInputCustom, 'input', 'plek', $author$project$Main$UpdateNewAquarium)
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-name')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Aquarium :')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-r'),
												$elm$html$Html$Attributes$value(x.aw),
												A3($author$project$Main$onInputCustom, 'input', 'soort', $author$project$Main$UpdateNewAquarium)
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-name')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Info :')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-r'),
												$elm$html$Html$Attributes$value(x.ao),
												A3($author$project$Main$onInputCustom, 'input', 'info', $author$project$Main$UpdateNewAquarium)
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-fieldcolumn')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-name')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Formaat :')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-r'),
												A3($author$project$Main$onInputCustom, 'input', 'formaat', $author$project$Main$UpdateNewAquarium)
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-name')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Plaatsing :')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-r'),
												$elm$html$Html$Attributes$type_('date'),
												$elm$html$Html$Attributes$value(x.aL),
												A3($author$project$Main$onInputCustom, 'input', 'plaatsing_van', $author$project$Main$UpdateNewAquarium)
											]),
										_List_Nil)
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-name')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Factuur verzonden :')
											])),
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-r'),
												$elm$html$Html$Attributes$type_('date'),
												$elm$html$Html$Attributes$value(x.aI),
												A3($author$project$Main$onInputCustom, 'input', 'fact_verzonden', $author$project$Main$UpdateNewAquarium)
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-fieldcolumn')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-input')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Onderhoud :')
													])),
												A2(
												$elm$html$Html$select,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-nr'),
														$elm$html$Html$Attributes$value(
														_Utils_eq(x._, -1) ? '' : $elm$core$String$fromInt(x._)),
														A3($author$project$Main$onInputCustom, 'input', 'onderhoudfreq', $author$project$Main$UpdateNewAquarium)
													]),
												$author$project$Main$makeOnderhoudOptions(
													$elm$core$String$fromInt(x._)))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('dagen')
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-input')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Contr.duur :')
													])),
												A2(
												$elm$html$Html$select,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-r'),
														$elm$html$Html$Attributes$value(x.ah),
														A3($author$project$Main$onInputCustom, 'input', 'contractsduur', $author$project$Main$UpdateNewAquarium)
													]),
												$author$project$Main$makeMonthOptions(x.ah))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('maanden')
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-input')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Facturering :')
													])),
												A2(
												$elm$html$Html$select,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-r'),
														$elm$html$Html$Attributes$value(x.am),
														A3($author$project$Main$onInputCustom, 'input', 'facturering', $author$project$Main$UpdateNewAquarium)
													]),
												$author$project$Main$makeMonthOptions(x.am))
											])),
										A2(
										$elm$html$Html$span,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('field-text')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('maanden')
											]))
									]))
							]))
					])),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex-column')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field-name')
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('Contract info :')
							])),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('field-contract'),
								$elm$html$Html$Attributes$value(x.aF),
								A3($author$project$Main$onInputCustom, 'input', 'contract_info', $author$project$Main$UpdateNewAquarium)
							]),
						_List_Nil)
					]))
			]));
};
var $elm$virtual_dom$VirtualDom$keyedNode = function (tag) {
	return _VirtualDom_keyedNode(
		_VirtualDom_noScript(tag));
};
var $elm$html$Html$Keyed$node = $elm$virtual_dom$VirtualDom$keyedNode;
var $elm$html$Html$Events$onClick = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'click',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$keyCode = A2($elm$json$Json$Decode$field, 'keyCode', $elm$json$Json$Decode$int);
var $author$project$Main$onEnter = function (msg) {
	var isEnter = function (code) {
		return (code === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('not ENTER');
	};
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$andThen, isEnter, $elm$html$Html$Events$keyCode));
};
var $elm$html$Html$Events$alwaysStop = function (x) {
	return _Utils_Tuple2(x, true);
};
var $elm$virtual_dom$VirtualDom$MayStopPropagation = function (a) {
	return {$: 1, a: a};
};
var $elm$html$Html$Events$stopPropagationOn = F2(
	function (event, decoder) {
		return A2(
			$elm$virtual_dom$VirtualDom$on,
			event,
			$elm$virtual_dom$VirtualDom$MayStopPropagation(decoder));
	});
var $elm$html$Html$Events$onInput = function (tagger) {
	return A2(
		$elm$html$Html$Events$stopPropagationOn,
		'input',
		A2(
			$elm$json$Json$Decode$map,
			$elm$html$Html$Events$alwaysStop,
			A2($elm$json$Json$Decode$map, tagger, $elm$html$Html$Events$targetValue)));
};
var $elm$html$Html$Attributes$placeholder = $elm$html$Html$Attributes$stringProperty('placeholder');
var $author$project$Main$mapAction = F2(
	function (n, model) {
		var _v0 = n.w;
		switch (_v0) {
			case 'opmerking':
				return A3(
					$elm$html$Html$Keyed$node,
					'div',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fieldbar-3')
						]),
					_List_fromArray(
						[
							_Utils_Tuple2(
							'opm',
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-rowbtn')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fieldheader')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Nieuwe opmerking')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button-row')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button-refresh'),
														$elm$html$Html$Events$onClick($author$project$Main$SaveNewComment)
													]),
												_List_fromArray(
													[
														$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$save)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button-refresh'),
														$elm$html$Html$Events$onClick($author$project$Main$CancelNewComment)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('x')
													]))
											]))
									]))),
							_Utils_Tuple2(
							'opmMap',
							A2(
								$elm$html$Html$textarea,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('field-r'),
										$elm$html$Html$Attributes$placeholder('opmerking'),
										$elm$html$Html$Events$onInput($author$project$Main$UpdateNewComment)
									]),
								_List_Nil))
						]));
			case 'lead':
				return A3(
					$elm$html$Html$Keyed$node,
					'div',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fieldbar-3')
						]),
					_List_fromArray(
						[
							_Utils_Tuple2(
							'lead',
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-rowbtn')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fieldheader')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('Nieuwe lead info')
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button-row')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button-refresh'),
														$elm$html$Html$Events$onClick($author$project$Main$SaveNewLead)
													]),
												_List_fromArray(
													[
														$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$save)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button-refresh'),
														$elm$html$Html$Events$onClick($author$project$Main$CancelNewLead)
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('x')
													]))
											]))
									]))),
							_Utils_Tuple2(
							'leadMap',
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('follow-input')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-column')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$textarea,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-r'),
														$elm$html$Html$Attributes$placeholder('follow-up info'),
														$elm$html$Html$Events$onInput(
														$author$project$Main$UpdateNewLead('lead'))
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_Nil,
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-date'),
														$elm$html$Html$Attributes$type_('date'),
														$elm$html$Html$Events$onInput(
														$author$project$Main$UpdateNewLead('follow_up'))
													]),
												_List_Nil)
											]))
									])))
						]));
			case 'aquarium':
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fieldbar-2')
						]),
					_List_fromArray(
						[
							A2(
							$elm$html$Html$div,
							_List_fromArray(
								[
									$elm$html$Html$Attributes$class('flex-rowbtn')
								]),
							_List_fromArray(
								[
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('fieldheader')
										]),
									_List_fromArray(
										[
											$elm$html$Html$text('Nieuw Aquarium')
										])),
									A2(
									$elm$html$Html$div,
									_List_fromArray(
										[
											$elm$html$Html$Attributes$class('button-row')
										]),
									_List_fromArray(
										[
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('button-refresh'),
													$elm$html$Html$Events$onClick($author$project$Main$SaveNewAquarium)
												]),
											_List_fromArray(
												[
													$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$save)
												])),
											A2(
											$elm$html$Html$div,
											_List_fromArray(
												[
													$elm$html$Html$Attributes$class('button-refresh'),
													$elm$html$Html$Events$onClick($author$project$Main$CancelNewAquarium)
												]),
											_List_fromArray(
												[
													$elm$html$Html$text('x')
												]))
										]))
								])),
							$author$project$Main$mapAquariaNew(model.X)
						]));
			case 'zoek_opm':
				return A3(
					$elm$html$Html$Keyed$node,
					'div',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fieldbar-2')
						]),
					_List_fromArray(
						[
							_Utils_Tuple2(
							'zoek_opm',
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fieldheader search-2'),
												$elm$html$Html$Attributes$placeholder('Zoek opmerking'),
												$elm$html$Html$Events$onInput($author$project$Main$UpdateActionFilter),
												$author$project$Main$onEnter($author$project$Main$ZoekAction)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button-refresh'),
												$elm$html$Html$Events$onClick($author$project$Main$CancelNewComment)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('x')
											]))
									]))),
							_Utils_Tuple2(
							'zoek_opmMap',
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$Main$ContactenMsg,
										$author$project$Contacten$mapComments(model.a9))
									])))
						]));
			case 'zoek_lead':
				return A3(
					$elm$html$Html$Keyed$node,
					'div',
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fieldbar-2')
						]),
					_List_fromArray(
						[
							_Utils_Tuple2(
							'zoek_lead',
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-row')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$input,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('fieldheader search-2'),
												$elm$html$Html$Attributes$placeholder('Zoek follow-up info'),
												$elm$html$Html$Events$onInput($author$project$Main$UpdateActionFilter),
												$author$project$Main$onEnter($author$project$Main$ZoekAction)
											]),
										_List_Nil),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button-refresh'),
												$elm$html$Html$Events$onClick($author$project$Main$CancelNewComment)
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('x')
											]))
									]))),
							_Utils_Tuple2(
							'zoek_leadMap',
							A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$map,
										$author$project$Main$ContactenMsg,
										$author$project$Contacten$mapLeads(model.ba))
									])))
						]));
			default:
				return A2(
					$elm$html$Html$div,
					_List_fromArray(
						[
							$elm$html$Html$Attributes$class('fieldbar-3')
						]),
					_List_Nil);
		}
	});
var $author$project$Agenda$LoadContactByName = function (a) {
	return {$: 2, a: a};
};
var $frandibar$elm_font_awesome_5$FontAwesome$arrowRight = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('arrow-right');
var $frandibar$elm_font_awesome_5$FontAwesome$building = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('building');
var $frandibar$elm_font_awesome_5$FontAwesome$clock = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('clock');
var $elm$core$List$isEmpty = function (xs) {
	if (!xs.b) {
		return true;
	} else {
		return false;
	}
};
var $author$project$Agenda$mapAgenda = F2(
	function (list, _v0) {
		return $elm$core$List$isEmpty(list) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('main-container')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Geen planning')
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('lead-headcontainer')
				]),
			A2(
				$elm$core$List$map,
				function (x) {
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('lead-container')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-row')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon')
													]),
												_List_fromArray(
													[
														$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$clock)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-ticket')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(x.bx)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-row')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon'),
														A2($elm$html$Html$Attributes$style, 'color', 'blue')
													]),
												_List_fromArray(
													[
														$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$building)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-ticket'),
														A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(x.bf)
													]))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('button-else'),
										$elm$html$Html$Attributes$title('Ga naar contact'),
										$elm$html$Html$Events$onClick(
										$author$project$Agenda$LoadContactByName(x.bf))
									]),
								_List_fromArray(
									[
										$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$arrowRight)
									]))
							]));
				},
				list));
	});
var $author$project$Contacten$Afmelden = function (a) {
	return {$: 21, a: a};
};
var $author$project$Contacten$LoadContact = function (a) {
	return {$: 22, a: a};
};
var $frandibar$elm_font_awesome_5$FontAwesome$check = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('check');
var $elm$core$Dict$isEmpty = function (dict) {
	if (dict.$ === -2) {
		return true;
	} else {
		return false;
	}
};
var $elm$core$List$sortBy = _List_sortBy;
var $frandibar$elm_font_awesome_5$FontAwesome$user = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('user');
var $author$project$Contacten$mapDagLeads = F2(
	function (dict, _v0) {
		return $elm$core$Dict$isEmpty(dict) ? A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('main-container')
				]),
			_List_fromArray(
				[
					$elm$html$Html$text('Geen Leads')
				])) : A2(
			$elm$html$Html$div,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$class('lead-headcontainer')
				]),
			A2(
				$elm$core$List$map,
				function (_v1) {
					var k = _v1.a;
					var x = _v1.b;
					return A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('lead-container')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-row')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon')
													]),
												_List_fromArray(
													[
														$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$clock)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-ticket')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(x.K)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-row')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon'),
														A2($elm$html$Html$Attributes$style, 'color', 'blue')
													]),
												_List_fromArray(
													[
														$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$building)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-ticket'),
														A2($elm$html$Html$Attributes$style, 'font-weight', 'bold')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(x.bf)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-row')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('icon'),
														A2($elm$html$Html$Attributes$style, 'color', 'green')
													]),
												_List_fromArray(
													[
														$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$user)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-ticket')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(x.R)
													]))
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-column'),
										A2($elm$html$Html$Attributes$style, 'align-items', 'flex-end'),
										A2($elm$html$Html$Attributes$style, 'width', '20%')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button-else'),
												$elm$html$Html$Attributes$title('Ga naar contact'),
												A2($elm$html$Html$Attributes$style, 'margin-bottom', '8%'),
												$elm$html$Html$Events$onClick(
												$author$project$Contacten$LoadContact(x.cl))
											]),
										_List_fromArray(
											[
												$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$arrowRight)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button-else'),
												$elm$html$Html$Attributes$title('Check'),
												A2($elm$html$Html$Attributes$style, 'margin-top', '8%'),
												$elm$html$Html$Events$onClick(
												$author$project$Contacten$Afmelden(k))
											]),
										_List_fromArray(
											[
												$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$check)
											]))
									]))
							]));
				},
				A2(
					$elm$core$List$sortBy,
					function (_v2) {
						var v = _v2.b;
						return v.K;
					},
					$elm$core$Dict$toList(dict))));
	});
var $author$project$Main$SaveNewContact = {$: 18};
var $author$project$Main$UpdateNewContact = F2(
	function (a, b) {
		return {$: 17, a: a, b: b};
	});
var $author$project$Main$mapNewContact = function (x) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('fieldbar-2')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('flex-column')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('flex-rowbtn')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-fieldcolumn')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-rowbtn')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-row')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$select,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field-sel'),
																A3($author$project$Main$onInputCustom, 'input', 'status', $author$project$Main$UpdateNewContact)
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$option,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$value('Prospect')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Prospect')
																	]))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('button-row')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button-refresh'),
																$elm$html$Html$Events$onClick($author$project$Main$SaveNewContact),
																$elm$html$Html$Attributes$title('Opslaan Contact')
															]),
														_List_fromArray(
															[
																$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$save)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('button-refresh'),
																$elm$html$Html$Events$onClick($author$project$Main$CancelNewContact),
																$elm$html$Html$Attributes$title('Cancel Contact')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('x')
															]))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-fieldrow')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-rowinp')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Bedrijfsnaam :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-head'),
																		$elm$html$Html$Attributes$value(x.bf),
																		A3($author$project$Main$onInputCustom, 'input', 'bedrijfsnaam', $author$project$Main$UpdateNewContact)
																	]),
																_List_Nil)
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-rowinp')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-column')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$span,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-name')
																	]),
																_List_fromArray(
																	[
																		$elm$html$Html$text('Adres :')
																	])),
																A2(
																$elm$html$Html$input,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('field-headadr'),
																		$elm$html$Html$Attributes$value(x.ax),
																		A3($author$project$Main$onInputCustom, 'input', 'straat', $author$project$Main$UpdateNewContact)
																	]),
																_List_Nil)
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowcombi')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$span,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Nr :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-headnr'),
																				$elm$html$Html$Attributes$value(
																				_Utils_eq(x.Z, -1) ? '' : $elm$core$String$fromInt(x.Z)),
																				A3($author$project$Main$onInputCustom, 'input', 'nummer', $author$project$Main$UpdateNewContact)
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$span,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Tv :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-headnr'),
																				$elm$html$Html$Attributes$value(x.aQ),
																				A3($author$project$Main$onInputCustom, 'input', 'toevoeging', $author$project$Main$UpdateNewContact)
																			]),
																		_List_Nil)
																	]))
															]))
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-rowinp')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-row')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$span,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Pc :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-headpc'),
																				$elm$html$Html$Attributes$value(x.aM),
																				A3($author$project$Main$onInputCustom, 'input', 'postcode', $author$project$Main$UpdateNewContact)
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$span,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Plaats :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-headpl'),
																				$elm$html$Html$Attributes$value(x.at),
																				A3($author$project$Main$onInputCustom, 'input', 'plaats', $author$project$Main$UpdateNewContact)
																			]),
																		_List_Nil)
																	]))
															]))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-row')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-fieldrow')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Contact voornaam :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-contact'),
																				$elm$html$Html$Attributes$value(x.aS),
																				A3($author$project$Main$onInputCustom, 'input', 'voornaam', $author$project$Main$UpdateNewContact)
																			]),
																		_List_Nil)
																	])),
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('achternaam :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-contact'),
																				$elm$html$Html$Attributes$value(x.aD),
																				A3($author$project$Main$onInputCustom, 'input', 'achternaam', $author$project$Main$UpdateNewContact)
																			]),
																		_List_Nil)
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Telefoon :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-tel'),
																				$elm$html$Html$Attributes$value(x.aP),
																				A3($author$project$Main$onInputCustom, 'input', 'tel', $author$project$Main$UpdateNewContact)
																			]),
																		_List_Nil)
																	]))
															])),
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('flex-rowinp')
															]),
														_List_fromArray(
															[
																A2(
																$elm$html$Html$div,
																_List_fromArray(
																	[
																		$elm$html$Html$Attributes$class('flex-column')
																	]),
																_List_fromArray(
																	[
																		A2(
																		$elm$html$Html$div,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-name')
																			]),
																		_List_fromArray(
																			[
																				$elm$html$Html$text('Email :')
																			])),
																		A2(
																		$elm$html$Html$input,
																		_List_fromArray(
																			[
																				$elm$html$Html$Attributes$class('field-r'),
																				$elm$html$Html$Attributes$value(x.ai),
																				A3($author$project$Main$onInputCustom, 'input', 'email', $author$project$Main$UpdateNewContact)
																			]),
																		_List_Nil)
																	]))
															]))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-fieldrow')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-mng')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field-name')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Accountmng :')
															])),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field-mng'),
																$elm$html$Html$Attributes$value(x.R),
																A3($author$project$Main$onInputCustom, 'input', 'accountmng', $author$project$Main$UpdateNewContact)
															]),
														_List_Nil)
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('flex-column')
													]),
												_List_fromArray(
													[
														A2(
														$elm$html$Html$div,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field-name')
															]),
														_List_fromArray(
															[
																$elm$html$Html$text('Contact info :')
															])),
														A2(
														$elm$html$Html$input,
														_List_fromArray(
															[
																$elm$html$Html$Attributes$class('field-mnginfo'),
																$elm$html$Html$Attributes$value(x.aE),
																A3($author$project$Main$onInputCustom, 'input', 'contactinfo', $author$project$Main$UpdateNewContact)
															]),
														_List_Nil)
													]))
											]))
									]))
							]))
					]))
			]));
};
var $author$project$Main$inhoud = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('dash-container')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('column-3')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('headbar-2')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-row'),
										A2($elm$html$Html$Attributes$style, 'align-items', 'center')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-fieldrow'),
												A2($elm$html$Html$Attributes$style, 'align-items', 'center')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$input,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('search-2'),
														$elm$html$Html$Attributes$placeholder('Zoek'),
														$elm$html$Html$Events$onInput($author$project$Main$UpdateFilter),
														$author$project$Main$onEnter(
														$author$project$Main$Zoek(model.aC))
													]),
												_List_Nil)
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button-refresh'),
												$elm$html$Html$Attributes$title('Terug'),
												$elm$html$Html$Events$onClick($author$project$Main$CancelNewContact),
												A2($elm$html$Html$Attributes$style, 'margin-bottom', '0px')
											]),
										_List_fromArray(
											[
												$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$home)
											])),
										(model.a.aZ === 'admin') ? A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('button-plushead'),
												$elm$html$Html$Attributes$title('Nieuw Contact'),
												$elm$html$Html$Events$onClick($author$project$Main$NewContact),
												A2($elm$html$Html$Attributes$style, 'margin-bottom', '0px')
											]),
										_List_fromArray(
											[
												$elm$html$Html$text('+')
											])) : A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												A2($elm$html$Html$Attributes$style, 'margin-right', '10px')
											]),
										_List_Nil)
									]))
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('dashfield-2 flex-column')
							]),
						_List_fromArray(
							[
								function () {
								var _v0 = model.e.w;
								switch (_v0) {
									case 'contact':
										return $author$project$Main$mapNewContact(model.Y);
									case 'dagLeads':
										return A2(
											$elm$html$Html$map,
											$author$project$Main$ContactenMsg,
											A2($author$project$Contacten$mapDagLeads, model.aG, model.a));
									case 'agendaPlanning':
										return A2(
											$elm$html$Html$map,
											$author$project$Main$AgendaMsg,
											A2($author$project$Agenda$mapAgenda, model.af, model.a));
									default:
										return A2(
											$elm$html$Html$map,
											$author$project$Main$ContactenMsg,
											A2($author$project$Contacten$map, model.V, model.a.aZ));
								}
							}()
							])),
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('dashfield-4 flex-column')
							]),
						_List_fromArray(
							[
								A2($author$project$Main$mapAction, model.e, model)
							]))
					]))
			]));
};
var $elm$core$List$append = F2(
	function (xs, ys) {
		if (!ys.b) {
			return xs;
		} else {
			return A3($elm$core$List$foldr, $elm$core$List$cons, ys, xs);
		}
	});
var $elm$core$List$concat = function (lists) {
	return A3($elm$core$List$foldr, $elm$core$List$append, _List_Nil, lists);
};
var $emilianobovetti$elm_toast$Toast$Interaction = F2(
	function (a, b) {
		return {$: 1, a: a, b: b};
	});
var $elm$virtual_dom$VirtualDom$mapAttribute = _VirtualDom_mapAttribute;
var $elm$html$Html$Attributes$map = $elm$virtual_dom$VirtualDom$mapAttribute;
var $emilianobovetti$elm_toast$Toast$mapAttributes = function (mapper) {
	return $elm$core$List$map(
		$elm$html$Html$Attributes$map(mapper));
};
var $elm$html$Html$Events$onBlur = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'blur',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onFocus = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'focus',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseEnter = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseenter',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Events$onMouseLeave = function (msg) {
	return A2(
		$elm$html$Html$Events$on,
		'mouseleave',
		$elm$json$Json$Decode$succeed(msg));
};
var $elm$html$Html$Attributes$tabindex = function (n) {
	return A2(
		_VirtualDom_attribute,
		'tabIndex',
		$elm$core$String$fromInt(n));
};
var $emilianobovetti$elm_toast$Toast$defaultAttributes = F2(
	function (toAppMsg, id) {
		return A2(
			$emilianobovetti$elm_toast$Toast$mapAttributes,
			toAppMsg,
			_List_fromArray(
				[
					$elm$html$Html$Attributes$tabindex(0),
					$elm$html$Html$Events$onMouseEnter(
					A2($emilianobovetti$elm_toast$Toast$Interaction, 0, id)),
					$elm$html$Html$Events$onMouseLeave(
					A2($emilianobovetti$elm_toast$Toast$Interaction, 1, id)),
					$elm$html$Html$Events$onFocus(
					A2($emilianobovetti$elm_toast$Toast$Interaction, 0, id)),
					$elm$html$Html$Events$onBlur(
					A2($emilianobovetti$elm_toast$Toast$Interaction, 1, id))
				]));
	});
var $emilianobovetti$elm_toast$Toast$interactionAttributes = F2(
	function (cfg, interaction) {
		if (!interaction) {
			return cfg.aU;
		} else {
			return _List_Nil;
		}
	});
var $emilianobovetti$elm_toast$Toast$transitionAttributes = F2(
	function (cfg, phase) {
		switch (phase) {
			case 0:
				return cfg.ak;
			case 1:
				return _List_Nil;
			default:
				return cfg.al;
		}
	});
var $emilianobovetti$elm_toast$Toast$allToastAttributes = F2(
	function (cfg, toast) {
		return $elm$core$List$concat(
			_List_fromArray(
				[
					cfg.a0,
					A2($emilianobovetti$elm_toast$Toast$interactionAttributes, cfg, toast.L),
					A2($emilianobovetti$elm_toast$Toast$transitionAttributes, cfg, toast.O),
					A2($emilianobovetti$elm_toast$Toast$defaultAttributes, cfg.bo, toast.ct)
				]));
	});
var $emilianobovetti$elm_toast$Toast$toInfo = function (toast) {
	return {bw: toast.bw, ct: toast.ct, L: toast.L, O: toast.O};
};
var $emilianobovetti$elm_toast$Toast$renderToast = F3(
	function (viewer, cfg, toast) {
		return _Utils_Tuple2(
			'toast-' + $elm$core$String$fromInt(toast.ct),
			A2(
				viewer,
				A2($emilianobovetti$elm_toast$Toast$allToastAttributes, cfg, toast),
				$emilianobovetti$elm_toast$Toast$toInfo(toast)));
	});
var $emilianobovetti$elm_toast$Toast$render = F3(
	function (viewer, _v0, _v1) {
		var model = _v0;
		var cfg = _v1;
		return A3(
			$elm$html$Html$Keyed$node,
			cfg.be,
			cfg.a1,
			A2(
				$elm$core$List$map,
				A2($emilianobovetti$elm_toast$Toast$renderToast, viewer, cfg),
				model.P));
	});
var $frandibar$elm_font_awesome_5$FontAwesome$signOutAlt = $frandibar$elm_font_awesome_5$FontAwesome$Icon$Icon('sign-out-alt');
var $elm$html$Html$Attributes$src = function (url) {
	return A2(
		$elm$html$Html$Attributes$stringProperty,
		'src',
		_VirtualDom_noJavaScriptOrHtmlUri(url));
};
var $author$project$User$Login = {$: 2};
var $author$project$User$UpdatePassword = function (a) {
	return {$: 1, a: a};
};
var $author$project$User$UpdateUserName = function (a) {
	return {$: 0, a: a};
};
var $elm$html$Html$button = _VirtualDom_node('button');
var $author$project$User$onEnter = function (msg) {
	var isEnter = function (code) {
		return (code === 13) ? $elm$json$Json$Decode$succeed(msg) : $elm$json$Json$Decode$fail('not ENTER');
	};
	return A2(
		$elm$html$Html$Events$on,
		'keydown',
		A2($elm$json$Json$Decode$andThen, isEnter, $elm$html$Html$Events$keyCode));
};
var $author$project$User$view = function (model) {
	return A2(
		$elm$html$Html$div,
		_List_fromArray(
			[
				$elm$html$Html$Attributes$class('main-login')
			]),
		_List_fromArray(
			[
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('aquariumverhuur_logo_login')
					]),
				_List_Nil),
				A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('login-field')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('login'),
								$elm$html$Html$Attributes$placeholder('Email'),
								$elm$html$Html$Events$onInput($author$project$User$UpdateUserName)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$input,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('login'),
								$elm$html$Html$Attributes$type_('password'),
								$elm$html$Html$Attributes$placeholder('Wachtwoord'),
								$author$project$User$onEnter($author$project$User$Login),
								$elm$html$Html$Events$onInput($author$project$User$UpdatePassword)
							]),
						_List_Nil),
						A2(
						$elm$html$Html$button,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('login-btn'),
								$elm$html$Html$Events$onClick($author$project$User$Login)
							]),
						_List_fromArray(
							[
								$elm$html$Html$text('LOG IN')
							])),
						$elm$html$Html$text(model.aW)
					]))
			]));
};
var $author$project$Main$toastStyles = function (toast) {
	var background = function () {
		var _v0 = toast.bw.bg;
		if (!_v0) {
			return $elm$html$Html$Attributes$class('toastr');
		} else {
			return $elm$html$Html$Attributes$class('toastg');
		}
	}();
	return _List_fromArray(
		[background]);
};
var $author$project$Main$viewToast = F2(
	function (attributes, toast) {
		return A2(
			$elm$html$Html$div,
			_Utils_ap(
				$author$project$Main$toastStyles(toast),
				attributes),
			_List_fromArray(
				[
					$elm$html$Html$text(toast.bw.bh)
				]));
	});
var $elm$html$Html$Attributes$width = function (n) {
	return A2(
		_VirtualDom_attribute,
		'width',
		$elm$core$String$fromInt(n));
};
var $author$project$Main$view = function (model) {
	return {
		bt: _List_fromArray(
			[
				(model.a.ca < 1) ? A2(
				$elm$html$Html$map,
				$author$project$Main$UserMsg,
				$author$project$User$view(model.a)) : ((model.a.ca >= 1) ? A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('main-container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$div,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$class('column-1')
							]),
						_List_fromArray(
							[
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('aquariumverhuur_logo'),
										$elm$html$Html$Attributes$width(400),
										$elm$html$Html$Events$onClick($author$project$Main$CancelNewContact)
									]),
								_List_Nil),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('flex-rowbut')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('manual-btn')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$a,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$title('Handleiding'),
														$elm$html$Html$Attributes$href('images/Handleiding_aqua.pdf'),
														$elm$html$Html$Attributes$target('blank')
													]),
												_List_fromArray(
													[
														$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$fileAlt)
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Events$onClick($author$project$Main$LogOut),
												$elm$html$Html$Attributes$class('logout-btn'),
												$elm$html$Html$Attributes$title(' log uit')
											]),
										_List_fromArray(
											[
												$frandibar$elm_font_awesome_5$FontAwesome$icon($frandibar$elm_font_awesome_5$FontAwesome$signOutAlt)
											]))
									])),
								A2(
								$elm$html$Html$div,
								_List_fromArray(
									[
										$elm$html$Html$Attributes$class('sidebar')
									]),
								_List_fromArray(
									[
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-input')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Klanten :')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-headnr')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														$elm$core$String$fromInt(model.aO.W))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-input')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Prospect :')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-headnr')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														$elm$core$String$fromInt(model.aO.ab))
													]))
											])),
										A2(
										$elm$html$Html$div,
										_List_fromArray(
											[
												$elm$html$Html$Attributes$class('flex-input')
											]),
										_List_fromArray(
											[
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-name')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text('Archief :')
													])),
												A2(
												$elm$html$Html$div,
												_List_fromArray(
													[
														$elm$html$Html$Attributes$class('field-headnr')
													]),
												_List_fromArray(
													[
														$elm$html$Html$text(
														$elm$core$String$fromInt(model.aO.S))
													]))
											]))
									])),
								A3(
								$emilianobovetti$elm_toast$Toast$render,
								$author$project$Main$viewToast,
								model.az,
								$emilianobovetti$elm_toast$Toast$config($author$project$Main$ToastMsg))
							])),
						$author$project$Main$inhoud(model)
					])) : A2(
				$elm$html$Html$div,
				_List_fromArray(
					[
						$elm$html$Html$Attributes$class('main-container')
					]),
				_List_fromArray(
					[
						A2(
						$elm$html$Html$img,
						_List_fromArray(
							[
								$elm$html$Html$Attributes$height(80),
								$elm$html$Html$Attributes$src('../images/loading.gif')
							]),
						_List_Nil)
					])))
			]),
		b8: 'aqua'
	};
};
var $author$project$Main$main = $elm$browser$Browser$application(
	{bI: $author$project$Main$init, cy: $author$project$Main$UrlChanged, cz: $author$project$Main$LinkClicked, cH: $author$project$Main$subscriptions, cI: $author$project$Main$update, cJ: $author$project$Main$view});
_Platform_export({'Main':{'init':$author$project$Main$main(
	A2(
		$elm$json$Json$Decode$andThen,
		function (endpoint) {
			return A2(
				$elm$json$Json$Decode$andThen,
				function (api_key) {
					return $elm$json$Json$Decode$succeed(
						{br: api_key, aj: endpoint});
				},
				A2($elm$json$Json$Decode$field, 'api_key', $elm$json$Json$Decode$string));
		},
		A2($elm$json$Json$Decode$field, 'endpoint', $elm$json$Json$Decode$string)))(0)}});}(this));